import React, { CSSProperties } from 'react';

export type SeparatorOrientation = 'horizontal' | 'vertical';

interface SeparatorProps {
  size: number;
  orientation?: SeparatorOrientation;
}

export const Separator: React.FC<SeparatorProps> = ({ size, orientation = 'vertical' }) => {
  const separatorStyle: CSSProperties = {
    background: 'transparent',
    border: 0,
    height: orientation === 'vertical' ? size : 1,
    width: orientation === 'vertical' ? 1 : size,
  };
  return <div style={separatorStyle} />;
};
