import { useEffect, useState } from 'react';
import { Job } from '../models/JobModel';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { Profile } from '../models/Profile';

type JobType = 'all' | 'user' | 'user_id';

const useJobs = (type: JobType = 'all', profile?: Profile) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobsError, setJobsError] = useState<string>();
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);

  const fetchJobs = () => {
    setJobsLoading(true);
    API.user
      .getAllJobs()
      .then((response) => {
        logger('useJobs.tsx - fetchJobs()', response, 'success');
        setJobs(response);
      })
      .catch((error) => {
        logger('useJobs.tsx - fetchJobs()', error.message, 'error');
        setJobsError(error.message);
      })
      .finally(() => setJobsLoading(false));
  };

  const fetchJobsByUser = () => {
    if (profile && profile.role === 'user') {
      setJobsLoading(true);
      API.user
        .getUserJobs()
        .then((response) => {
          logger('useJobs.tsx - fetchJobsByUser()', response, 'success');
          setJobs(response);
        })
        .catch((error) => {
          logger('useJobs.tsx - fetchJobsByUser()', error.message, 'error');
          setJobsError(error.message);
        })
        .finally(() => setJobsLoading(false));
    }
  };

  const fetchJobsByUserId = () => {
    if (profile && profile.role === 'user') {
      setJobsLoading(true);
      API.user
        .getUserJobsByUserId(profile.id)
        .then((response) => {
          logger('useJobs.tsx - fetchJobsByUserId()', response, 'success');
          setJobs(response);
        })
        .catch((error) => {
          logger('useJobs.tsx - fetchJobsByUserId()', error.message, 'error');
          setJobsError(error.message);
        })
        .finally(() => setJobsLoading(false));
    }
  };

  useEffect(() => {
    if (type === 'user') fetchJobsByUser();
    if (type === 'all') fetchJobs();
    if (type === 'user_id') fetchJobsByUserId();
  }, [profile]);

  return {
    jobs,
    jobsError,
    jobsLoading,
    fetchJobsByUser,
    fetchJobsByUserId,
  };
};

export default useJobs;
