import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Separator } from '../Separators/Separator';
import { ServicesFilter } from '../Forms/ServicesFilter';
import loaderControls from '../Loader/loaderControls';
import { Loader } from '../Loader/Loader';
import bannerControls from '../Banner/bannerControls';
import { Banner } from '../Banner/Banner';
import { t } from 'i18next';
import { getServices } from '../../utils/APIUtils';
import { Service } from '../../models/Services';
import { ServicesList } from '../Lists/ServicesList';
import { COLORS } from '../../utils/colors';
import { FilterIcon } from '../../assets/icons/FilterIcon';

const RootDiv = styled.div`
  padding-inline: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: 30px;

  @media screen and (max-width: 700px) {
    padding-inline: 15px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  gap: 40px;
  overflow-y: hidden;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ServicesFilterDiv = styled.div`
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const CheckBtn = styled.label`
  cursor: pointer;
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -35px;
  }
`;

const CheckInp = styled.input`
  display: none;

  @media screen and (max-width: 700px) {
    &:checked ~ ${ServicesFilterDiv} {
      display: flex;
    }
  }
`;

export const ServicesTab: React.FC = () => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();
  const [services, setServices] = useState<Service[]>([]);
  const [showServices, setShownServices] = useState<Service[]>([]);

  /* istanbul ignore next */
  const onServiceSuccess = (data: { services?: Service[] }) => {
    if (data.services) {
      setServices(data.services);
      setShownServices(data.services);
    }
    closeLoader();
  };

  /* istanbul ignore next */
  const onServiceError = (error: string) => {
    closeLoader();
    showBanner(t('error'), error);
  };

  /* istanbul ignore next */
  const handleFilter = (
    maxPrice?: number,
    city?: string,
    searchString?: string,
  ) => {
    if (maxPrice && city) {
      setShownServices(
        services
          .filter(
            (item) =>
              item.price_per_hour <= maxPrice &&
              `${item.city}, ${item.country}`
                .toLowerCase()
                .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.service
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (maxPrice && !city) {
      setShownServices(
        services
          .filter((item) => item.price_per_hour <= maxPrice)
          .filter(
            (item) =>
              item.service
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (!maxPrice && city) {
      setShownServices(
        services
          .filter((item) =>
            `${item.city}, ${item.country}`
              .toLowerCase()
              .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.service
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else {
      setShownServices(
        services.filter(
          (item) =>
            item.service
              .toLowerCase()
              .includes(searchString?.toLowerCase() ?? '') ||
            item.description
              .toLowerCase()
              .includes(searchString?.toLowerCase() ?? ''),
        ),
      );
    }
  };
  
  /* istanbul ignore next */
  const handleResetFilters = () =>
    getServices(onServiceSuccess, onServiceError);

  useEffect(() => {
    openLoader();
    getServices(onServiceSuccess, onServiceError);
  }, []);
  return (
    <RootDiv id="services-tab-root">
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <Separator size={30} />
      <BodyDiv>
        <CheckInp type="checkbox" id="check" />
        <CheckBtn htmlFor="check">
          <FilterIcon width={20} height={20} color={COLORS.blue} />
        </CheckBtn>
        <ServicesFilterDiv>
          <ServicesFilter
            services={showServices}
            handleFilter={handleFilter}
            handleResetFilters={handleResetFilters}
          />
        </ServicesFilterDiv>
        <ServicesList services={showServices} />
      </BodyDiv>
    </RootDiv>
  );
};
