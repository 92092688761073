import React from 'react';
import { PopUpMenu } from './PopUpMenu';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';
import { Profile } from '../../models/Profile';
import { Router } from '../../navigation/Router';
import { Chat } from '../../models/ChatModels';
import { checkUnseenMessages } from '../../utils/messages';

const Header = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  color: ${COLORS.blue};
  min-width: 200px;
`;

const ViewAllButton = styled.button`
  font-size: 12px;
  text-decoration: underline;
  color: ${COLORS.blue};
`;

const MessagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  gap: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: 150px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ChatButton = styled.button`
  display: flex;
  gap: 10px;
  padding-inline: 0;
  border-bottom: 1px solid ${COLORS.gray_50};
  align-items: center;
`;

const ChatUserDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const ChatButtonUsername = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${COLORS.blue};
`;

const ChatButtonTopic = styled.span`
  font-size: 0.8rem;
  color: ${COLORS.gray_50};
`;

const UnseenMessagesDiv = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${COLORS.orange};
`;

interface MessagesNavbarMenuProps {
  profile?: Profile;
  chats: Chat[];
  handleClose: () => void;
}

export const MessagesNavbarMenu: React.FC<MessagesNavbarMenuProps> = ({
  profile,
  chats,
  handleClose,
}) => {
  const handleGoToChats = (chatId?: number) => {
    Router.goToChats(chatId);
    handleClose();
  };

  return (
    <PopUpMenu menuPosition={36} innerBoxPosition="45%" menuHeight={47}>
      <Header>
        <span>{t('messages')}</span>
        <div style={{ flex: 1 }} />
        <ViewAllButton
          type="button"
          id="messages-navbar-menu-view-all-button"
          onClick={() => handleGoToChats()}>
          {t('view_all')}
        </ViewAllButton>
      </Header>
      <Separator size={10} />
      <MessagesDiv>
        {chats.map((chat, index) => {
          const secondUser = chat.users_list.find(
            (user) => user.id !== profile?.id,
          );
          if (index < 5) {
            return (
              <ChatButton
                key={chat.id}
                type="button"
                onClick={() => handleGoToChats(chat.id)}>
                <ChatUserDiv>
                  <ChatButtonUsername>
                    {secondUser?.username}
                  </ChatButtonUsername>
                  <ChatButtonTopic>{chat.topic}</ChatButtonTopic>
                </ChatUserDiv>
                {checkUnseenMessages([chat], profile) && <UnseenMessagesDiv />}
              </ChatButton>
            );
          }
        })}
      </MessagesDiv>
    </PopUpMenu>
  );
};
