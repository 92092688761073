import { DefaultControls } from '../models/FunctionInterface';
import { API } from '../network/API';
import { saveClientAPIToken } from '../network/ClientAPIKey';
import { logger } from './logger';

export const decodeURLData = (urlData: string) => {
  const formattedData = urlData.replace("b'", '').replace("'", '');
  return atob(formattedData);
};

export const parseDecodedData = (strData: string) => JSON.parse(strData);

export const resetPassword = (
  { t, openLoader, closeLoader, showBanner }: DefaultControls,
  password: string,
  onSuccess: () => void,
  urlData: string | null,
) => {
  if (!urlData) {
    showBanner(t('error'), t('general_error'));
    return;
  }
  const strData = decodeURLData(urlData);
  const parsedData = parseDecodedData(strData);
  const email = parsedData.email;
  const expires = parsedData.expires;
  const token = parsedData.token;
  const timestamp = Date.now() / 1000;

  if (!email || !token) {
    showBanner(t('error'), t('missing_data'));
    return;
  }

  if (expires <= timestamp) {
    showBanner(t('error'), t('url_expired'));
    return;
  }

  saveClientAPIToken(token);
  openLoader();
  API.user
    .resetPassword(password)
    .then(() => {
      closeLoader();
      onSuccess();
    })
    .catch((error) => {
      closeLoader();
      showBanner(t('error'), error.message);
      logger('miscUtils.ts - resetPassword()', error, 'error');
    });
};

export const isPhone = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
