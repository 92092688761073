import { FC } from 'react';
import { Service } from '../../models/Services';
import styled from 'styled-components';
import { ServiceCard } from '../Cards/ServiceCard';

const ServicesRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ServicesListProps {
  services: Service[];
}

export const ServicesList: FC<ServicesListProps> = ({ services }) => {
  return (
    <ServicesRoot>
      {services.map((service) => {
        /* istanbul ignore next */
        return <ServiceCard key={service.id} service={service} />;
      })}
    </ServicesRoot>
  );
};
