import { getLanguage } from '../localization/I18';
import { ClientError } from './ClientError';
import { getClientAPIToken } from './ClientAPIKey';
import { getCookie } from './ClientCookies';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_GENERAL_TOKEN = process.env.REACT_APP_GENERAL_API_TOKEN;

const toJSON = (response: any) => response.json();

const getBodyFromResponse = async (response: any) => {
  try {
    return await response.json();
  } catch (e) {
    return 'No body';
  }
};

const handleErrors = async (response: any) => {
  if (!response.ok) {
    const body = await getBodyFromResponse(response);
    if (response.status === 500) {
      throw new ClientError(body);
    }
    throw new Error(`Unexpected error: ${JSON.stringify(response)}${body}`);
  }
  return response;
};

const jsonFetch = async <T>(
  url: string,
  method: string,
  authorizedEndpoint: boolean,
  body?: object,
): Promise<T> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'Accept-Language': getLanguage(),
    'X-Csrftoken': getCookie('csrftoken'),
    Authorization: `Bearer ${getClientAPIToken()}`,
  };

  if (!authorizedEndpoint) {
    headers.Authorization = `Bearer ${API_GENERAL_TOKEN}`;
  }

  const request: RequestInit = {
    method,
    headers,
  };

  if (body) {
    request.body = JSON.stringify(body);
  }

  const destination = `${BASE_URL}${url}`;

  return await fetch(destination, request).then(handleErrors).then(toJSON);
};

const formDataFetch = async <T>(
  url: string,
  method: string,
  authorizedEndpoint: boolean,
  body?: any,
): Promise<T> => {
  const headers: HeadersInit = {
    'Accept-Language': getLanguage(),
    'X-Csrftoken': getCookie('csrftoken'),
    Authorization: `Bearer ${getClientAPIToken()}`,
  };

  if (!authorizedEndpoint) {
    headers.Authorization = `Bearer ${API_GENERAL_TOKEN}`;
  }

  const request: RequestInit = {
    method,
    headers,
  };

  if (body) {
    request.body = body;
  }

  const destination = `${BASE_URL}${url}`;
  return await fetch(destination, request).then(handleErrors).then(toJSON);
};

export const get = async <T>(url: string, authorizedEndpoint: boolean) =>
  jsonFetch<T>(url, 'GET', authorizedEndpoint);

export const post = async <T>(url: string, authorizedEndpoint: boolean, body?: object) =>
  jsonFetch<T>(url, 'POST', authorizedEndpoint, body);

export const put = async <T>(url: string, authorizedEndpoint: boolean, body?: object) =>
  jsonFetch<T>(url, 'PUT', authorizedEndpoint, body);

export const del = async <T>(url: string, authorizedEndpoint: boolean, body?: object) =>
  jsonFetch<T>(url, 'DELETE', authorizedEndpoint, body);

export const postFile = async <T>(url: string, authorizedEndpoint: boolean, body?: object) =>
  formDataFetch<T>(url, 'POST', authorizedEndpoint, body);
