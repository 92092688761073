import React from 'react';
import { t } from 'i18next';
import { FullScreenBackgroundImgSlider } from '../components/Slider/FullScreenBackgroundImgSlider';
import { LoginForm } from '../components/Forms/LoginForm';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { isPC } from '../utils/screenWidth';
import { Helmet } from 'react-helmet-async';

const Login: React.FC = () => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
      <Helmet>
        <title>{t('login')} - Hephaestus</title>
      </Helmet>
      <div>
        <FullScreenBackgroundImgSlider />
        <div className="flex-row--reverse-800 flex-1">
          <div className="double-border-component">
            <div style={{ height: isPC() ? undefined : '100vh' }}>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
