import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { EuroIcon } from '../../assets/icons/EuroIcon';

const RootDiv = styled.div`
  background: white;
  border: 0;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border-radius: 5px;
  display: flex;
  padding-block: 5px;
  padding-inline: 10px;
  gap: 10px;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  color: ${COLORS.blue};
  border: 0;
  outline: none;
`;

interface MoneyInputProps {
  id: string;
  value: string;
  placeholder: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const MoneyInput: React.FC<MoneyInputProps> = ({ id, value, onChange, placeholder }) => {
  return (
    <RootDiv>
      <Input type="number" id={id} placeholder={placeholder} value={value} onChange={onChange} />
      <EuroIcon width={16} height={16} color={COLORS.blue} />
    </RootDiv>
  );
};
