import React, { useEffect } from 'react';
import { ProfileContext } from '../../context/ProfileContext';
import styled from 'styled-components';
import { useProfile } from '../../hooks/useProfile';
import { Router } from '../../navigation/Router';

const Root = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

interface WorkerWrapperProps {
  children: React.ReactNode;
}

export const WorkerWrapper: React.FC<WorkerWrapperProps> = ({ children }) => {
  const { profile, reloadProfile } = useProfile();

  useEffect(() => {
    if (profile && profile.role !== 'worker') Router.goToHome();
  }, [profile]);

  return (
    <ProfileContext.Provider
      value={{ profile: profile, reloadProfile: reloadProfile }}>
      <Root>{children}</Root>
    </ProfileContext.Provider>
  );
};
