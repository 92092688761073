import React, { FC, useContext } from 'react';
import { ExtendedOffer } from '../../models/OfferModel';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Profile } from '../../models/Profile';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';
import { API } from '../../network/API';
import { logger } from '../../utils/logger';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';

const Root = styled.div`
  background: ${COLORS.white};
  box-shadow: 0 0 5px 0 ${COLORS.gray};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
`;

const UserSpan = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: ${COLORS.orange};
`;

const HeaderDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TitleSpan = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${COLORS.blue};
`;

const PriceSpan = styled.span`
  background: ${COLORS.orange};
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  align-self: flex-end;
  font-weight: bold;
  color: ${COLORS.white};
`;

const Description = styled.p`
  font-size: 0.9rem;
  margin: 0;
  white-space: pre-wrap;
`;

const OfferState = styled.span`
  font-size: 1rem;
  font-weight: 600;
  flex: 1;
  text-transform: uppercase;
  width: fit-content;
  border-radius: 5px;
  padding-block: 5px;
  color: ${COLORS.white};
  align-self: center;
  padding-inline: 20px;
`;

const FinishButton = styled.button`
  background: ${COLORS.green};
  border-radius: 10px;
  border: none;
  flex: 1;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-block: 5px;
`;

const RefuseButton = styled.button`
  background: ${COLORS.red};
  border-radius: 10px;
  border: none;
  flex: 1;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-block: 5px;
`;

const AcceptButton = styled.button`
  background: ${COLORS.green};
  border-radius: 10px;
  border: none;
  flex: 1;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-block: 5px;
`;

const OfferStateColors = {
  inProgress: COLORS.orange,
  refused: COLORS.red,
  pending: COLORS.blue,
  finished: COLORS.green,
};

interface OfferCardProps {
  offer: ExtendedOffer;
  refreshOffers: (loader: boolean) => void;
  profile?: Profile;
  setSelectedOffer: (offer: ExtendedOffer) => void;
}

export const OfferCard: FC<OfferCardProps> = ({
  offer,
  refreshOffers,
  profile,
  setSelectedOffer,
}) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const handleDelete = () => {
    showLoader();
    API.user
      .deleteOffer(offer.id)
      .then(() => refreshOffers(true))
      .catch((error) => {
        logger('OfferMesssage.tsx - handleDelete()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleAccept = () => {
    showLoader();
    API.user
      .changeOfferState('inProgress', offer.id)
      .then(() => refreshOffers(true))
      .catch((error) => {
        logger('OfferMesssage.tsx - handleAccept()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleRefuse = () => {
    showLoader();
    API.user
      .changeOfferState('refused', offer.id)
      .then(() => refreshOffers(true))
      .catch((error) => {
        logger('OfferMesssage.tsx - handleAccept()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleFinish = () => {
    showLoader();
    API.user
      .changeOfferState('finished', offer.id)
      .then(() => {
        refreshOffers(true);
        setSelectedOffer(offer);
      })
      .catch((error) => {
        logger('OfferMesssage.tsx - handleAccept()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };
  return (
    <Root>
      {profile?.id === offer.from_offer_data.id ? (
        <UserSpan>{offer.to_offer_data.username}</UserSpan>
      ) : (
        <UserSpan>{offer.from_offer_data.username}</UserSpan>
      )}
      <HeaderDiv>
        <TitleSpan style={{ flex: 1 }}>{offer.title}</TitleSpan>
        <PriceSpan>{offer.price} €/h</PriceSpan>
      </HeaderDiv>
      <Separator size={20} />
      <Description>{offer.description}</Description>
      <Separator size={20} />
      <OfferState
        style={{
          background: OfferStateColors[offer.state],
        }}>
        {offer.state}
      </OfferState>
      {offer.from_offer_data.id !== profile?.id &&
        offer.state === 'pending' && (
          <>
            <Separator size={20} />
            <div style={{ display: 'flex', gap: 10 }}>
              <RefuseButton type="button" onClick={handleRefuse}>
                {t('refuse')}
              </RefuseButton>
              <AcceptButton type="button" onClick={handleAccept}>
                {t('accept')}
              </AcceptButton>
            </div>
          </>
        )}
      {offer.from_offer_data.id === profile?.id &&
        (offer.state === 'pending' || offer.state === 'refused') && (
          <>
            <Separator size={20} />
            <div style={{ display: 'flex', gap: 10 }}>
              <RefuseButton type="button" onClick={handleDelete}>
                {t('delete')}
              </RefuseButton>
            </div>
          </>
        )}
      {offer.from_offer_data.id !== profile?.id &&
        offer.state === 'inProgress' && (
          <>
            <Separator size={20} />
            <div style={{ display: 'flex', gap: 10 }}>
              <FinishButton type="button" onClick={handleFinish}>
                {t('finish')}
              </FinishButton>
            </div>
          </>
        )}
    </Root>
  );
};
