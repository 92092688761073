import { useContext, useEffect, useState } from 'react';
import { UserType } from '../constants/UserTypesConst';
import { Profile } from '../models/Profile';
import { API } from '../network/API';
import { Router } from '../navigation/Router';
import { logger } from '../utils/logger';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { t } from 'i18next';

type UseProfileReturn = {
  profile: Profile | undefined;
  checkUserTypeExist: () => void;
  reloadProfile: () => void;
};

export const useProfile = (): UseProfileReturn => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [profile, setProfile] = useState<Profile>();
  const [userType, setUserType] = useState<UserType | null>(null);

  const fetchProfile = () => {
    showLoader();
    API.user
      .getProfile()
      .then((response) => {
        setProfile(response);
        setUserType(response.role);
        logger('useProfile.ts - useProfile()', response, 'success');
      })
      .catch((err) => {
        showBanner(t('error'), err.message);
        logger('useProfile.ts - useProfile()', err.message, 'error');
      })
      .finally(() => closeLoader());
  };

  const reloadProfile = () => fetchProfile();

  const checkUserTypeExist = () => {
    if (userType === null && profile) {
      Router.goToRoleChooser(profile);
    }
  };

  useEffect(() => fetchProfile(), []);

  return {
    profile,
    checkUserTypeExist,
    reloadProfile,
  };
};
