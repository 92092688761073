import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { DefaultTextArea } from '../Inputs/DefaultTextArea';
import { SendIcon } from '../../assets/icons/SendIcon';
import { useChat } from '../../hooks/useChat';
import { Profile } from '../../models/Profile';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { TopLabelTextArea } from '../Inputs/TopLabelTextArea';
import { t } from 'i18next';
import { XMarkIcon } from '../../assets/icons/XMarkIcon';
import { API } from '../../network/API';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { logger } from '../../utils/logger';
import { MessageView } from './MessageView';

const Root = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const Header = styled.div`
  background: ${COLORS.gray_10};
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 5px 10px 0 ${COLORS.gray_50};

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
  }

  & > div > #username {
    font-size: 1rem;
    font-weight: 600;
  }

  & > div > #topic {
    font-size: 0.8rem;
  }

  & > button {
    background: ${COLORS.blue};
    color: ${COLORS.white};
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1rem;
  }
`;

const MessagesDiv = styled.div`
  flex: 1;
  margin-bottom: 10px;
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const InputDiv = styled.div`
  display: flex;
  gap: 10px;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${COLORS.gray_50};
  overflow-y: hidden;

  & > form {
    background: ${COLORS.white};
    width: 80%;
    max-height: 50%;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;

    & > #send-offer-button {
      background: ${COLORS.orange};
      color: ${COLORS.white};
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 1rem;
      box-shadow: 0 5px 10px 0 ${COLORS.gray_50};
    }

    & > #send-offer-button:hover {
      box-shadow: 0 0 0 0 ${COLORS.gray_50};
    }

    & > #close-offer-modal-button {
      background: none;
      border: none;
      cursor: pointer;
      align-self: flex-end;
    }
  }
`;

interface ChatViewProps {
  selectedChat: number;
  profile?: Profile;
}

export const ChatView: FC<ChatViewProps> = ({ selectedChat, profile }) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [newOfferModal, setNewOfferModal] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const { messages, sendMessage } = useChat(selectedChat);

  const [scrollToBottom, setScrollToBottom] = useState(true);

  const handleOfferModal = () => setNewOfferModal(!newOfferModal);

  const wheelListener = () => setScrollToBottom(false);
  const keyListener = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter') {
      const activeElement = document.activeElement;
      const messageInput = document.querySelector('#message');
      const inputFocus = activeElement === messageInput;
      if (inputFocus) {
        handleSend();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(event.target.value);

  const onSendSuccess = () => {
    setMessage('');
    setScrollToBottom(true);
  };

  const handleSend = () =>
    sendMessage(
      message,
      messages[0].receiver_data.id,
      messages[0].topic,
      onSendSuccess,
    );

  const handleOfferSend = (event: FormEvent) => {
    event.preventDefault();
    // @ts-ignore
    const title = event.target[1].value;
    // @ts-ignore
    const description = event.target[2].value;
    // @ts-ignore
    const price = event.target[3].value;

    showLoader();
    API.user
      .sendNewOffer(
        title,
        description,
        price,
        messages[0].receiver_data.id !== profile?.id
          ? messages[0].receiver_data.id
          : messages[0].sender_data.id,
      )
      .catch((error) => {
        logger('ChatView.tsx - handleOfferSend()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => {
        handleOfferModal();
        closeLoader();
      });
  };

  useEffect(() => {
    document.addEventListener('wheel', wheelListener);
    document.addEventListener('keyup', keyListener);
    if (scrollToBottom) {
      const messagesDiv = document.querySelector('#messages');
      if (messagesDiv) {
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
      }
    }

    return () => {
      document.removeEventListener('wheel', wheelListener);
      document.removeEventListener('keyup', keyListener);
    };
  }, [messages]);

  return (
    <Root>
      {messages.length > 0 && (
        <>
          <Header>
            <div>
              <span id="username">{messages[0].receiver_data.username}</span>
              <span id="topic">{messages[0].topic}</span>
            </div>
            <button type="button" onClick={handleOfferModal}>
              {t('new_offer')}
            </button>
          </Header>
          <MessagesDiv id="messages">
            {messages.map((item) => {
              return (
                <MessageView key={item.id} message={item} profile={profile} />
              );
            })}
          </MessagesDiv>
        </>
      )}
      <InputDiv>
        <DefaultTextArea id="message" value={message} onChange={handleChange} />
        <button type="button" onClick={handleSend}>
          <SendIcon width={24} height={24} color={COLORS.blue} />
        </button>
      </InputDiv>
      {newOfferModal && (
        <Modal>
          <form onSubmit={handleOfferSend}>
            <button
              id="close-offer-modal-button"
              type="button"
              onClick={handleOfferModal}>
              <XMarkIcon width={20} height={20} color={COLORS.orange} />
            </button>
            <TopLabelInput label={t('offer_title')} id="offer-title" />
            <TopLabelTextArea
              label={t('offer_description')}
              id="offer-description"
              onChange={() => undefined}
            />
            <TopLabelInput
              label={t('price_per_hour')}
              id="offer-price"
              type="number"
            />
            <button id="send-offer-button" type="submit">
              {t('send_offer')}
            </button>
          </form>
        </Modal>
      )}
    </Root>
  );
};
