import React, { ChangeEvent, useState } from 'react';
import { DropdownHeaderNumber } from './DropdownHeaderNumber';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { t } from 'i18next';
import { Separator } from '../Separators/Separator';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';
import { FinancialData } from '../../models/FinancialData';
import { completeFinancialData } from '../../utils/user';

interface DropdownFinancialDataProps {
  financialData: FinancialData;
  setFinancialData: (fd: FinancialData) => void;
}

export const DropdownFinancialData: React.FC<DropdownFinancialDataProps> = ({
  financialData,
  setFinancialData,
}) => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerMessage,
    bannerTitle,
  } = bannerControls();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  /* istanbul ignore next */
  const handleOpen = () => setIsOpen(!isOpen);

  /* istanbul ignore next */
  const handleChangeVatNumber = (event: ChangeEvent<HTMLInputElement>) =>
    setFinancialData({ ...financialData, vat_number: event.target.value });

  /* istanbul ignore next */
  const save = async () => {
    const result = await completeFinancialData(
      { t, openLoader, closeLoader, showBanner },
      financialData,
      setFinancialData,
    );
    setIsOpen(!result);
  };

  /* istanbul ignore next */
  const buttonDisabled = !Boolean(financialData.vat_number.length);

  return (
    <div style={{ width: '100%' }}>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <DropdownHeaderNumber
        number={2}
        title={t('financial_data')}
        isOpen={isOpen}
        onClick={handleOpen}
        isComplete={!buttonDisabled}
      />
      {/* istanbul ignore next */isOpen && (
        <div>
          <div className="grid-3-columns">
            <TopLabelInput
              label={t('vat_number')}
              id="vat-number-input"
              value={financialData.vat_number}
              onChange={handleChangeVatNumber}
            />
          </div>
          <Separator size={20} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              type="button"
              id="dropdown-financial-data-save-button"
              className="orange-button"
              style={{ width: 'unset', paddingInline: 40 }}
              onClick={save}
              disabled={buttonDisabled}>
              {t('save')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
