import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Landing from '../screens/Landing';
import Register from '../screens/Register';
import { setUpTexts } from '../localization/I18';
import { setUpNavigator } from './Router';
import Login from '../screens/Login';
import RoleChooser from '../screens/RoleChooser';
import Home from '../screens/Home';
import CompleteWorkerProfile from '../screens/CompleteWorkerProfile';
import { LoggedOutWrapper } from '../components/Wrappers/LoggedOutWrapper';
import { LoggedInWrapper } from '../components/Wrappers/LoggedInWrapper';
import ForgotPassword from '../screens/ForgotPassword';
import PasswordReset from '../screens/PasswordReset';
import NewService from '../screens/NewService';
import ServicesAndProfessionals from '../screens/ServicesAndProfessionals';
import { UserWrapper } from '../components/Wrappers/UserWrapper';
import { WorkerWrapper } from '../components/Wrappers/WorkerWrapper';
import NewJob from '../screens/NewJob';
import Jobs from '../screens/Jobs';
import PersonalProfile from '../screens/PersonalProfile';
import UserProfileView from '../screens/UserProfileView';
import Chats from '../screens/Chats';
import { Settings } from '../screens/Settings';
import VerifyAccount from '../screens/VerifyAccount';
import { ProvacyPolicy } from '../screens/ProvacyPolicy';

export const MainNavigation: React.FC = () => {
  const navigatior = useNavigate();
  setUpNavigator(navigatior);
  setUpTexts();
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route
        path="/register"
        element={
          <LoggedInWrapper>
            <Register />
          </LoggedInWrapper>
        }
      />
      <Route
        path="/login"
        element={
          <LoggedInWrapper>
            <Login />
          </LoggedInWrapper>
        }
      />
      <Route
        path="/password-recovery"
        element={
          <LoggedInWrapper>
            <ForgotPassword />
          </LoggedInWrapper>
        }
      />
      <Route
        path="/password-reset"
        element={
          <LoggedInWrapper>
            <PasswordReset />
          </LoggedInWrapper>
        }
      />
      <Route path="/verify-account" element={<VerifyAccount />} />
      <Route
        path="/home"
        element={
          <LoggedOutWrapper>
            <Home />
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/role-chooser"
        element={
          <LoggedOutWrapper navbarType="only-user">
            <RoleChooser />
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/complete-worker-profile"
        element={
          <LoggedOutWrapper navbarType="only-user">
            <WorkerWrapper>
              <CompleteWorkerProfile />
            </WorkerWrapper>
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/new-service"
        element={
          <LoggedOutWrapper>
            <WorkerWrapper>
              <NewService />
            </WorkerWrapper>
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/services-and-professionals"
        element={
          <LoggedOutWrapper>
            <UserWrapper>
              <ServicesAndProfessionals />
            </UserWrapper>
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/new-job"
        element={
          <LoggedOutWrapper>
            <UserWrapper>
              <NewJob />
            </UserWrapper>
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/jobs"
        element={
          <LoggedOutWrapper>
            <WorkerWrapper>
              <Jobs />
            </WorkerWrapper>
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/profile"
        element={
          <LoggedOutWrapper>
            <PersonalProfile />
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/profile/:id"
        element={
          <LoggedOutWrapper>
            <UserProfileView />
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/chats/:id"
        element={
          <LoggedOutWrapper>
            <Chats />
          </LoggedOutWrapper>
        }
      />
      <Route
        path="/settings/"
        element={
          <LoggedOutWrapper>
            <Settings />
          </LoggedOutWrapper>
        }
      />
      <Route path="/privacy/" element={<ProvacyPolicy />} />
    </Routes>
  );
};
