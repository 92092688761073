import { FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Profile } from '../../models/Profile';
import { CheckCircleIcon } from '../../assets/icons/CheckCircleIcon';
import { t } from 'i18next';
import { ReviewComponent } from '../ReviewComponent';
import { isPhone } from '../../utils/miscUtils';
import { Router } from '../../navigation/Router';

const WorkerRoot = styled.div`
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-block: 15px;
  display: flex;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ProfieImg = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 0;
  border-radius: 5px;
`;

const ProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Username = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
`;

const Job = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Description = styled.span`
  max-height: 80px;
  overflow-y: hidden;
`;

const City = styled.span`
  color: ${COLORS.blue};
  margin-top: 10px;
  font-weight: 500;
`;

const PriceLabel = styled.span`
  color: ${COLORS.blue};
  text-align: right;

  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
    text-align: center;
  }
`;

const Button = styled.button`
  background: ${COLORS.blue};
  color: ${COLORS.white};
  padding-block: 5px;
  padding-inline: 10px;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  border-radius: 8px;
  box-shadow: 3px 3px 5px ${COLORS.gray};

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray};
  }
`;

interface WorkerCardProps {
  profile: Profile;
}

export const WorkerCard: FC<WorkerCardProps> = ({ profile }) => {
  const navigateToUser = () => {
    if (isPhone()) {
      Router.goToProfileId(profile.id);
      return;
    }
    window.open(`/profile/${profile.id}`, '_blank', 'rel=noreferrer');
  };
  return (
    <WorkerRoot>
      <ProfieImg src={profile.avatar} alt="profile image" loading="lazy" />
      <ProfileBody>
        <Username>
          {profile.username}{' '}
          {profile.id_verified && (
            <CheckCircleIcon width={20} height={20} color={COLORS.blue} />
          )}
        </Username>
        <ReviewComponent
          rating={profile.rating.rating}
          reviews={profile.rating.reviews}
          openModal={false}
        />
        <Job>{profile.main_job}</Job>
        <Description>{profile.profile_description}</Description>
        <City>
          {profile.address?.city}, {profile.address?.country}
        </City>
      </ProfileBody>
      <ProfileBody style={{ flex: 'unset', height: '100%', paddingInline: 10 }}>
        <PriceLabel style={{ fontSize: '1.3rem', fontWeight: 500 }}>
          {profile.main_price_per_hour}(€/h)
        </PriceLabel>
        <div style={{ flex: 1 }} />
        <Button type="button" onClick={navigateToUser}>
          {t('hire')}
        </Button>
      </ProfileBody>
    </WorkerRoot>
  );
};
