import React, { ChangeEvent } from 'react';
import { isPC } from '../../utils/screenWidth';
import { Separator } from '../Separators/Separator';
import { IMAGES } from '../../utils/images';
import { LoginRegisterStyles } from '../../assets/css/LoginRegisterStyles';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';
import { t } from 'i18next';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { Router } from '../../navigation/Router';
import { sendPasswordRecoveryEmail } from '../../utils/user';

export const PasswordRecoveryForm: React.FC = () => {
  const styles = LoginRegisterStyles;

  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();

  const [email, setEmail] = React.useState<string>('');
  /* istanbul ignore next */
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  /* istanbul ignore next */
  const handleGoLogin = () => Router.goToLogin();

  /* istanbul ignore next */
  const handleSend = () =>
    sendPasswordRecoveryEmail(
      { t, openLoader, closeLoader, showBanner },
      email,
    );

  /* istanbul ignore next */
  const sendButtonDisabled = !Boolean(email.length);
  return (
    <>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <div
        className={`flex-column flex-1 width-80 ${isPC() ? 'justify-center' : ''}`}>
        <Separator size={30} />
        {isPC() && <img src={IMAGES.logo_full_trans} style={styles.img} />}
        <h1 style={styles.h1}>{t('password_recovery')}</h1>
        <span style={styles.span}>
          {t('already_have_account_question')}{' '}
          <button
            type="button"
            className="button-link-orange"
            onClick={handleGoLogin}>
            {t('login')}
          </button>
        </span>
        <div style={styles.div}>
          <p className="text-center">{t('password_recovery_steps')}</p>
          <TopLabelInput
            label={t('email')}
            id="email-input"
            value={email}
            onChange={handleEmailChange}
            type="email"
          />
          <Separator size={30} />
          <button
            type="button"
            className="orange-button"
            onClick={handleSend}
            disabled={sendButtonDisabled}>
            {t('send')}
          </button>
        </div>
        <Separator size={30} />
      </div>
    </>
  );
};
