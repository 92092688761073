import React, { ChangeEvent, useContext, useState } from 'react';
import { t } from 'i18next';
import { Router } from '../navigation/Router';
import { Service } from '../models/Services';
import { useWorkerServices } from '../hooks/useWorkerServices';
import { Separator } from '../components/Separators/Separator';
import { TopLabelInput } from '../components/Inputs/TopLabelInput';
import { TopLabelTextArea } from '../components/Inputs/TopLabelTextArea';
import styled from 'styled-components';
import { COLORS } from '../utils/colors';
import { ProfileContext } from '../context/ProfileContext';
import { Helmet } from 'react-helmet-async';

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Form = styled.div`
  background: white;
  padding-inline: 20px;
  width: 80%;
  border: 1px solid ${COLORS.gray_50};
  border-radius: 10px;
  box-shadow: 3px 3px 7px ${COLORS.gray_50};
  padding: 20px;
`;

const NewService: React.FC = () => {
  const { profile } = useContext(ProfileContext);
  const { createService } = useWorkerServices(profile);

  const [serviceName, setServiceName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [pricePerHour, setPricePerHour] = useState<number>(0);
  const [serviceDescription, setServiceDescription] = useState<string>('');

  /* istanbul ignore next */
  const handleServiceNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setServiceName(event.target.value);
  /* istanbul ignore next */
  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCity(event.target.value);
  /* istanbul ignore next */
  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCountry(event.target.value);
  /* istanbul ignore next */
  const handlePricePerHourChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPricePerHour(Number.parseInt(event.target.value));
  /* istanbul ignore next */
  const handleServiceDescriptionChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => setServiceDescription(event.target.value);

  /* istanbul ignore next */
  const buttonDisabled =
    !Boolean(serviceName.length) ||
    !Boolean(serviceDescription.length) ||
    !Boolean(city.length) ||
    !Boolean(country.length) ||
    pricePerHour <= 0;

  const onSuccess = () => Router.goToHome();

  /* istanbul ignore next */
  const save = () => {
    const service: Service = {
      service: serviceName,
      city: city,
      country: country,
      price_per_hour: pricePerHour,
      description: serviceDescription,
    };
    createService(service, onSuccess);
  };

  return (
    <>
      <Helmet>
        <title>{t('create_service')} - Hephaestus</title>
      </Helmet>
      <Body>
        <Form>
          <Separator size={10} />
          <h2 className="text-center orange">{t('create_service')}</h2>
          <div className="grid-4-columns">
            <TopLabelInput
              label={t('service_name')}
              id="service_name-input"
              value={serviceName}
              onChange={handleServiceNameChange}
            />
            <TopLabelInput
              label={t('city')}
              id="city-input"
              value={city}
              type="text"
              onChange={handleCityChange}
            />
            <TopLabelInput
              label={t('country')}
              id="country-input"
              value={country}
              type="text"
              onChange={handleCountryChange}
            />
            <TopLabelInput
              label={t('price_per_hour')}
              id="price_per_hour-input"
              value={String(pricePerHour)}
              type="number"
              onChange={handlePricePerHourChange}
            />
          </div>
          <TopLabelTextArea
            label={t('service_description')}
            id="service_description-textarea"
            value={serviceDescription}
            onChange={handleServiceDescriptionChange}
          />
          <Separator size={20} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              type="button"
              className="orange-button"
              id="new-service-save-button"
              style={{ width: 'unset', paddingInline: 40 }}
              onClick={save}
              disabled={buttonDisabled}>
              {t('save')}
            </button>
          </div>
        </Form>
      </Body>
    </>
  );
};

export default NewService;
