import React from 'react';
import IconProps from './IconProps';

export const PlusCirlceIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.8145 16.9355V14.0645H16.9355V8.18555H14.0645V14.0645H8.18555V16.9355H14.0645V22.8145H16.9355V16.9355H22.8145ZM5.17773 5.24609C8.04883 2.375 11.4896 0.939453 15.5 0.939453C19.5104 0.939453 22.9284 2.375 25.7539 5.24609C28.625 8.07161 30.0605 11.4896 30.0605 15.5C30.0605 19.5104 28.625 22.9512 25.7539 25.8223C22.9284 28.6478 19.5104 30.0605 15.5 30.0605C11.4896 30.0605 8.04883 28.6478 5.17773 25.8223C2.35221 22.9512 0.939453 19.5104 0.939453 15.5C0.939453 11.4896 2.35221 8.07161 5.17773 5.24609Z" fill={color} />
    </svg>
  );
};
