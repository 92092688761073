import React, { CSSProperties } from 'react';
import { SeparatorOrientation } from './Separator';
import { COLORS } from '../../utils/colors';

interface LineSeparatorProps {
  size: number;
  orientation?: SeparatorOrientation;
  color?: string;
}

export const LineSeparator: React.FC<LineSeparatorProps> = ({
  size,
  orientation = 'vertical',
  color = COLORS.blue,
}) => {
  const divStyle: CSSProperties = {
    background: color,
    border: 0,
    height: orientation === 'vertical' ? size : '100%',
    width: orientation === 'vertical' ? '100%' : size,
  };
  return <div style={divStyle} />;
};
