import { TFunction } from 'i18next';
import { checkEmailValid } from './email';
import { checkPasswordValid } from './password';
import { API } from '../network/API';
import { logger } from './logger';
import { saveClientAPIToken } from '../network/ClientAPIKey';

export const checkLoginInputs = (t: TFunction<['translation', ...string[]], undefined>, email: string, password: string) => {
  if (!checkEmailValid(email)) {
    return t('write_a_valid_email');
  }
  if (!checkPasswordValid(password)) {
    return t('write_a_valid_password');
  }
  return;
};

export const loginUser = async (email: string, password: string) => {
  try {
    return await API.general.loginUser(email, password);
  } catch (error: any) {
    logger('Login.ts - loginUser()', error, 'error');
    throw error.message;
  }
};

interface HandleLoginProps {
  t: TFunction<['translation', ...string[]], undefined>;
  showBanner: (title: string, message: string) => void;
  openLoader: () => void;
  closeLoader: () => void;
  onSuccess: () => void;
  email: string;
  password: string;
}

export const handleLogin = async ({ t, showBanner, openLoader, closeLoader, onSuccess, email, password }: HandleLoginProps) => {
  const allValid = checkLoginInputs(t, email, password);
  if (allValid) {
    showBanner(t('error'), allValid);
    return;
  }

  openLoader();
  try {
    const { token } = await loginUser(email, password);
    logger('Login.ts - handleLogin()', token, 'success');
    saveClientAPIToken(token);
    onSuccess();
  } catch (error: any) {
    showBanner(t('error'), error);
    logger('Login.ts - handleLogin()', error, 'error');
  }
  closeLoader();
};
