import { BrowserRouter as Router } from 'react-router-dom';
import { MainNavigation } from './navigation/MainNavgation';

function App() {
  return (
    <Router>
      <MainNavigation />
    </Router>
  );
}

export default App;
