import React, { FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Message } from '../../models/ChatModels';
import { OfferMesssage } from './OfferMesssage';

const SenderMessageDiv = styled.div`
  background: ${COLORS.blue};
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 20px;
  padding-inline: 20px;
  padding-block: 5px;
  align-self: flex-end;
  color: ${COLORS.white};

  & > p {
    font-size: 0.9rem;
    margin: 0;
    white-space: pre-wrap;
  }

  & > span {
    font-size: 0.7rem;
  }
`;

interface SenderMessageProps {
  item: Message;
}

export const SenderMessage: FC<SenderMessageProps> = ({ item }) => {
  return (
    <SenderMessageDiv>
      {item.message_type === 'message' ? (
        <>
          <p>{item.message}</p>
          <span>{new Date(item.get_date).toLocaleString()}</span>
        </>
      ) : (
        <OfferMesssage
          item={item}
          showSenderControls={item.offer_data?.state === 'pending'}
        />
      )}
    </SenderMessageDiv>
  );
};
