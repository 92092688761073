import React, { useEffect } from 'react';
import { checkLoggedOut } from '../../utils/user';
import { ChatsProvider } from '../../context/ChatsContext';
import { ProfileWrapper } from './ProfileWrapper';
import { NavbarTypes } from '../Navbar/NavbarTypes';
import { LoaderProvider } from '../../context/LoaderContext';
import { BannerProvider } from '../../context/BannerContext';

interface LoggedOutWrapperProps {
  children: React.ReactNode;
  navbarType?: NavbarTypes;
}

export const LoggedOutWrapper: React.FC<LoggedOutWrapperProps> = ({
  children,
  navbarType = 'full',
}) => {
  useEffect(() => {
    checkLoggedOut();
  });
  return (
    <LoaderProvider>
      <BannerProvider>
        <ChatsProvider>
          <ProfileWrapper navbarType={navbarType}>{children}</ProfileWrapper>
        </ChatsProvider>
      </BannerProvider>
    </LoaderProvider>
  );
};
