import cleaner from '../assets/img/cleaner.webp';
import grdner from '../assets/img/gardner.webp';
import logo_full_trans from '../assets/img/logo_full_trans.webp';
import logo_full_trans_white from '../assets/img/logo_full_trans_white.webp';
import pastry from '../assets/img/pastry.webp';
import plumber from '../assets/img/plumber.webp';

export const IMAGES = {
  cleaner: cleaner,
  grdner: grdner,
  logo_full_trans: logo_full_trans,
  pastry: pastry,
  plumber: plumber,
  logo_full_trans_white: logo_full_trans_white,
};
