import React from 'react';
import IconProps from './IconProps';

export const EmptyStartIcon: React.FC<IconProps> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1_249_3962"
        maskUnits="userSpaceOnUse"
        x="-2"
        y="-3"
        width={width * 2}
        height={height * 2}
        fill="black">
        <rect
          fill={'white'}
          x="-2"
          y="-3"
          width={width * 2}
          height={height * 2}
        />
        <path d="M14.25 20.8594L5.87109 25.9375L8.09277 16.416L0.729492 10.0049L10.4414 9.17969L14.25 0.229492L18.0586 9.17969L27.7705 10.0049L20.4072 16.416L22.6289 25.9375L14.25 20.8594Z" />
      </mask>
      <path
        d="M14.25 20.8594L14.7313 20.0653L14.25 19.7736L13.7687 20.0653L14.25 20.8594ZM5.87109 25.9375L4.96681 25.7265L4.46545 27.8752L6.35237 26.7316L5.87109 25.9375ZM8.09277 16.416L8.99705 16.627L9.12406 16.0827L8.70253 15.7157L8.09277 16.416ZM0.729492 10.0049L0.650877 9.07965L-1.53402 9.26529L0.119735 10.7052L0.729492 10.0049ZM10.4414 9.17969L10.52 10.1049L11.077 10.0576L11.2958 9.54327L10.4414 9.17969ZM14.25 0.229492L15.1044 -0.134095L14.25 -2.142L13.3956 -0.134095L14.25 0.229492ZM18.0586 9.17969L17.2042 9.54327L17.423 10.0576L17.98 10.1049L18.0586 9.17969ZM27.7705 10.0049L28.3803 10.7052L30.034 9.26529L27.8491 9.07965L27.7705 10.0049ZM20.4072 16.416L19.7975 15.7157L19.3759 16.0827L19.5029 16.627L20.4072 16.416ZM22.6289 25.9375L22.1476 26.7316L24.0346 27.8752L23.5332 25.7265L22.6289 25.9375ZM13.7687 20.0653L5.38981 25.1434L6.35237 26.7316L14.7313 21.6535L13.7687 20.0653ZM6.77537 26.1485L8.99705 16.627L7.18849 16.205L4.96681 25.7265L6.77537 26.1485ZM8.70253 15.7157L1.33925 9.30457L0.119735 10.7052L7.48302 17.1163L8.70253 15.7157ZM0.808107 10.9301L10.52 10.1049L10.3628 8.25445L0.650877 9.07965L0.808107 10.9301ZM11.2958 9.54327L15.1044 0.593079L13.3956 -0.134095L9.58698 8.8161L11.2958 9.54327ZM13.3956 0.593079L17.2042 9.54327L18.913 8.8161L15.1044 -0.134095L13.3956 0.593079ZM17.98 10.1049L27.6919 10.9301L27.8491 9.07965L18.1372 8.25445L17.98 10.1049ZM27.1608 9.30457L19.7975 15.7157L21.017 17.1163L28.3803 10.7052L27.1608 9.30457ZM19.5029 16.627L21.7246 26.1485L23.5332 25.7265L21.3115 16.205L19.5029 16.627ZM23.1102 25.1434L14.7313 20.0653L13.7687 21.6535L22.1476 26.7316L23.1102 25.1434Z"
        fill={color}
        mask="url(#path-1-outside-1_249_3962)"
      />
    </svg>
  );
};
