import React, { ChangeEvent, useState } from 'react';
import { DropdownHeaderNumber } from './DropdownHeaderNumber';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { t } from 'i18next';
import { Profile } from '../../models/Profile';
import { Separator } from '../Separators/Separator';
import { WorkerAddress } from '../../models/WorkerAddress';
import { completePersonalData } from '../../utils/user';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';

interface DropdownPersonalDataProps {
  profile?: Profile;
  workerAddress: WorkerAddress;
  setWorkerAddress: (wa: WorkerAddress) => void;
}

export const DropdownPersonalData: React.FC<DropdownPersonalDataProps> = ({
  profile,
  workerAddress,
  setWorkerAddress,
}) => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerMessage,
    bannerTitle,
  } = bannerControls();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  /* istanbul ignore next */
  const handleOpen = () => setIsOpen(!isOpen);

  const [birthdate, setBirthdate] = useState<string>(profile?.birthdate ?? '');

  /* istanbul ignore next */
  const handleChangeBirtdate = (event: ChangeEvent<HTMLInputElement>) =>
    setBirthdate(event.target.value);
  /* istanbul ignore next */
  const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) =>
    setWorkerAddress({ ...workerAddress, address: event.target.value });
  /* istanbul ignore next */
  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) =>
    setWorkerAddress({ ...workerAddress, city: event.target.value });
  /* istanbul ignore next */
  const handleChangeProvinceOrState = (event: ChangeEvent<HTMLInputElement>) =>
    setWorkerAddress({
      ...workerAddress,
      province_or_state: event.target.value,
    });
  /* istanbul ignore next */
  const handleChangeCountry = (event: ChangeEvent<HTMLInputElement>) =>
    setWorkerAddress({ ...workerAddress, country: event.target.value });

  /* istanbul ignore next */
  const save = async () => {
    const result = await completePersonalData(
      { t, openLoader, closeLoader, showBanner },
      workerAddress,
      birthdate,
      setWorkerAddress,
    );
    setIsOpen(!result);
  };

  /* istanbul ignore next */
  const buttonDisabled =
    !Boolean(birthdate.length) ||
    !Boolean(workerAddress.address.length) ||
    !Boolean(workerAddress.city.length) ||
    !Boolean(workerAddress.province_or_state.length) ||
    !Boolean(workerAddress.country.length);

  return (
    <div style={{ width: '100%' }}>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <DropdownHeaderNumber
        number={1}
        title={t('personal_data')}
        isOpen={isOpen}
        onClick={handleOpen}
        isComplete={!buttonDisabled}
      />
      {
        /* istanbul ignore next */ isOpen && (
          <div>
            <div className="grid-3-columns">
              <TopLabelInput
                label={t('full_name')}
                id="full-name-input"
                value={profile?.username}
                disabled
              />
              <TopLabelInput
                label={t('birthdate')}
                id="birthdate-input"
                type="date"
                value={birthdate}
                onChange={handleChangeBirtdate}
              />
              <TopLabelInput
                label={t('address')}
                id="address-input"
                value={workerAddress.address}
                onChange={handleChangeAddress}
              />
              <TopLabelInput
                label={t('city')}
                id="city-input"
                value={workerAddress.city}
                onChange={handleChangeCity}
              />
              <TopLabelInput
                label={t('province_or_state')}
                id="province-or-state-input"
                value={workerAddress?.province_or_state}
                onChange={handleChangeProvinceOrState}
              />
              <TopLabelInput
                label={t('country')}
                id="country-input"
                value={workerAddress.country}
                onChange={handleChangeCountry}
              />
            </div>
            <Separator size={20} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type="button"
                className="orange-button"
                style={{ width: 'unset', paddingInline: 40 }}
                onClick={save}
                disabled={buttonDisabled}>
                {t('save')}
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
};
