const en = {
  create_an_account: 'Create an account',
  already_have_account_question: 'Do you already have an account?',
  login: 'Login',
  full_name: 'Full name',
  email: 'Email',
  password: 'Password',
  user_type: 'User type',
  user: 'User',
  worker: 'Worker',
  i_accept_privacy_and_security: 'I accept the privacy and security policy.',
  register: 'Register',
  or: 'Or',
  error: 'Error',
  write_a_valid_name: 'Write a valid name.',
  write_a_valid_email: 'Write a valid email.',
  write_a_valid_password:
    'Password must have at least an UPPER CASE letter a lower case letter, a number and must be at least 8 characters long.',
  choose_a_valid_user_type: 'Choose a valid user type.',
  must_accept_privacy: 'You must accept our privacy and security policy.',
  register_success: 'Successfully registered!',
  register_success_message:
    "You've registered successfully. In a bit you'll receive an email to activate your account, please make sure to check the spambox if you don't see the email in your inbox.",
  close: 'Close',
  not_have_account_question: "You still don't have an account?",
  password_forget_question: 'Did you forget your password?',
  welcome_exclamation: 'Welcome!',
  home: 'Home',
  choose_role: 'Choose your role',
  save: 'Save',
  welcome: 'Welcome',
  we_need_role_type: 'We need to know which is your role in our app.',
  complete_worker_profile: 'Complete Worke Profile',
  sign_out: 'Sign out',
  birthdate: 'Birth date',
  address: 'Address',
  city: 'City',
  province_or_state: 'Province or state',
  country: 'Country',
  general_error: 'Something went wrong, try again later.',
  finish_form_worker_request:
    'To finalize your profile, we require that you complete the following form.',
  personal_data: 'Perosnal data',
  financial_data: 'Financial data',
  vat_number: 'VAT number',
  services_data: 'Services data',
  service_name: 'Service name',
  action_radio: 'Action radio (Km)',
  price_per_hour: 'Price per hour (€/h)',
  service_description: 'Service description',
  finish: 'Finish',
  password_recovery: 'Password recovery',
  password_recovery_steps:
    "Write your accounts email below. We will send you a message with the steps required to reset your password. Don't forget to check the spam box.",
  send: 'Send',
  password_reset: 'Password reset',
  new_password: 'New password',
  repeat_password: 'Repeat password',
  success: 'Success',
  email_sent: 'Email sent!',
  missing_data: 'Some needed data is missing.',
  url_expired: 'This url has expired, please request a new one.',
  view_profile: 'View profile',
  messages: 'Messages',
  view_all: 'View all',
  notifications: 'Notifications',
  dashboard: 'Dashboard',
  jobs: 'Jobs',
  create_service: 'Create service',
  create_job: 'Create job',
  settings: 'Settings',
  services_and_professionals: 'Services & professionals',
  services: 'Services',
  professionals: 'Professionals',
  search: 'Search',
  filters: 'Filters',
  filter: 'Filter',
  reset: 'Reset',
  job_name: 'Job name',
  job_description: 'Job description',
  job_created_success: 'Job created successfully.',
  hire: 'Hire',
  created: 'Created',
  profile: 'Profile',
  service_deleted: 'Service deleted successfully.',
  edit_profile: 'Edit profile',
  contact: 'Contact',
  description: 'Description',
  delete_user: 'Delete user',
  job_proposal: 'Job proposal',
  job_proposal_message: 'I would like to make a proposal.',
  new_offer: 'New offer',
  offer_title: 'Offer title',
  offer_description: 'Offer description',
  send_offer: 'Send offer',
  refuse: 'Refuse',
  accept: 'Accept',
  delete: 'Delete',
  active_offers: 'Active offers',
  no_active_offers:
    "You don't have any active offers, go ahead and check the jobs, professionals and services section.",
  review: 'Review',
  leave_review_to: 'Leave a review to {{username}} for "{{job}}"',
  profile_updated: 'Profile updated successfully.',
  main_job: 'Main job',
  email_verified: 'Email verified successfully.',
  verify_account: 'Verify account',
};

export default en;
