import React from 'react';
import IconProps from './IconProps';

export const CheckCircleIcon: React.FC<IconProps> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5605 22.8145L25.6855 9.68945L23.6348 7.57031L12.5605 18.6445L7.36523 13.4492L5.31445 15.5L12.5605 22.8145ZM5.17773 5.24609C8.04883 2.375 11.4896 0.939453 15.5 0.939453C19.5104 0.939453 22.9284 2.375 25.7539 5.24609C28.625 8.07161 30.0605 11.4896 30.0605 15.5C30.0605 19.5104 28.625 22.9512 25.7539 25.8223C22.9284 28.6478 19.5104 30.0605 15.5 30.0605C11.4896 30.0605 8.04883 28.6478 5.17773 25.8223C2.35221 22.9512 0.939453 19.5104 0.939453 15.5C0.939453 11.4896 2.35221 8.07161 5.17773 5.24609Z"
        fill={color}
      />
    </svg>
  );
};
