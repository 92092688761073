import { useContext, useEffect, useState } from 'react';
import { Profile } from '../models/Profile';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { UserAddress } from '../models/UserAddress';
import { t } from 'i18next';

type UserAddressReturn = {
  userAddress: UserAddress;
  setUserAddress: (ua: UserAddress) => void;
};

export const useUserAddress = (profile?: Profile): UserAddressReturn => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [userAddress, setUserAddress] = useState<UserAddress>({
    address: '',
    city: '',
    country: '',
    province_or_state: '',
  });

  const fetchWorkerAddress = () => {
    if (profile && profile.role === 'user') {
      showLoader();
      API.user
        .getUserAddress()
        .then((response) => {
          setUserAddress(response);
          logger(
            'useUserAddress.ts - fetchWorkerAddress()',
            response,
            'success',
          );
        })
        .catch((error) => {
          showBanner(t('error'), error.message);
          logger(
            'useUserAddress.ts - fetchWorkerAddress()',
            error.message,
            'error',
          );
        })
        .finally(() => closeLoader());
    }
  };

  useEffect(() => fetchWorkerAddress(), [profile]);

  return { userAddress, setUserAddress };
};
