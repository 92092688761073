import React from 'react';
import IconProps from './IconProps';

export const MessagesIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27 10.3594V7H7V10.3594H27ZM27 15.3594V12H7V15.3594H27ZM27 20.3594V17H7V20.3594H27ZM30.3594 0.359375C31.2448 0.359375 32 0.697917 32.625 1.375C33.3021 2 33.6406 2.75521 33.6406 3.64062V23.6406C33.6406 24.526 33.3021 25.3073 32.625 25.9844C32 26.6615 31.2448 27 30.3594 27H7L0.359375 33.6406V3.64062C0.359375 2.75521 0.671875 2 1.29688 1.375C1.97396 0.697917 2.75521 0.359375 3.64062 0.359375H30.3594Z" fill={color} />
    </svg>
  );
};
