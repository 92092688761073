export const TIMEOUT = 3000;

export const startBGAnimation = () => {
  const imgHTMLElement = document.getElementById('register-bg-img');
  /* istanbul ignore next */
  if (imgHTMLElement) {
    imgHTMLElement.style.animation = 'fade-in 2s linear';
    setTimeout(() => {
      /* istanbul ignore next */
      imgHTMLElement.style.animation = '';
    }, 2000);
  }
};

export const changeBGImg = (
  images: string[],
  imgIndex: number,
  setImgIndex: (value: number) => void,
) => {
  /* istanbul ignore next */
  setTimeout(() => {
    if (imgIndex === images.length - 1) {
      setImgIndex(0);
      startBGAnimation();
      return;
    }
    setImgIndex(imgIndex + 1);
    startBGAnimation();
  }, TIMEOUT);
};
