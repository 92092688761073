import React from 'react';
import { COLORS } from '../../utils/colors';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';

interface BannerProps {
  title: string;
  message: string;
  onClose?: () => void;
}

export const Banner: React.FC<BannerProps> = ({ title, message, onClose }) => {
  return (
    <div
      className="full-screen"
      style={{
        background: COLORS.black_70,
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className="white-card">
        <Separator size={10} />
        <h2 id="banner-title" style={{ color: COLORS.blue, textAlign: 'center' }}>
          {title}
        </h2>
        <span style={{ color: COLORS.gray, textAlign: 'center' }}>{message}</span>
        <Separator size={20} />
        {onClose && (
          <div style={{ width: 150 }}>
            <button type="button" className="orange-button" onClick={onClose}>
              {t('close')}
            </button>
          </div>
        )}
        <Separator size={30} />
      </div>
    </div>
  );
};
