import React, { useEffect } from 'react';
import { checkLoggedIn } from '../../utils/user';
import { LoaderProvider } from '../../context/LoaderContext';
import { BannerProvider } from '../../context/BannerContext';

interface LoggedInWrapperProps {
  children: React.ReactNode;
}

export const LoggedInWrapper: React.FC<LoggedInWrapperProps> = ({
  children,
}) => {
  useEffect(() => {
    checkLoggedIn();
  });
  return (
    <LoaderProvider>
      <BannerProvider>{children}</BannerProvider>
    </LoaderProvider>
  );
};
