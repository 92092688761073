import { FC } from 'react';
import styled from 'styled-components';
import { Profile } from '../../models/Profile';
import { WorkerCard } from '../Cards/WorkerCard';

const ServicesRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ServicesListProps {
  profiles: Profile[];
}

export const ProfessionalsList: FC<ServicesListProps> = ({ profiles }) => {
  return (
    <ServicesRoot>
      {profiles.map((profile) => {
        /* istanbul ignore next */
        return <WorkerCard key={profile.id} profile={profile} />;
      })}
    </ServicesRoot>
  );
};
