import React, { CSSProperties } from 'react';
import { t } from 'i18next';
import { COLORS } from '../utils/colors';
import { UserOnlyNavbar } from '../components/Navbar/UserOnlyNavbar';
import { DropdownPersonalData } from '../components/Dropdown/DropdownPersonalData';
import { Separator } from '../components/Separators/Separator';
import { useProfile } from '../hooks/useProfile';
import { useWorkerAddress } from '../hooks/useWorkerAddress';
import { useFinancialData } from '../hooks/useFinancialData';
import { DropdownFinancialData } from '../components/Dropdown/DropdownFinancialData';
import { DropdownServicesData } from '../components/Dropdown/DropdownServicesData';
import { useWorkerServices } from '../hooks/useWorkerServices';
import { Router } from '../navigation/Router';
import { Helmet } from 'react-helmet-async';

const CompleteWorkerProfile: React.FC = () => {
  const { profile } = useProfile();
  const { workerAddress, setWorkerAddress } = useWorkerAddress(profile);
  const { financialData, setFinancialData } = useFinancialData(profile);
  const { workerServices, createService } = useWorkerServices(profile);
  const styles = {
    root: {
      background: COLORS.white,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    } as CSSProperties,
    button: {
      width: 'unset',
      paddingInline: 40,
      alignSelf: 'flex-end',
    } as CSSProperties,
    finish: {
      width: 'unset',
      paddingInline: 40,
    } as CSSProperties,
  };

  const handleFinish = () => Router.goToHome();

  const isFinishDisabled =
    workerAddress.user === null ||
    financialData.user === null ||
    workerServices.length === 0;

  return (
    <>
      <Helmet>
        <title>{t('complete_worker_profile')} - Hephaestus</title>
      </Helmet>
      <h1>Complete profile</h1>
      <div style={styles.root}>
        <UserOnlyNavbar profile={profile} />
        <div className="full-screen-white-card" style={{ flex: 'unset' }}>
          <h2 className="orange text-center">
            {t('welcome')} {profile?.username}
          </h2>
          <span className="text-center">{t('finish_form_worker_request')}</span>
          <Separator size={10} />
          <DropdownPersonalData
            profile={profile}
            workerAddress={workerAddress}
            setWorkerAddress={setWorkerAddress}
          />
          <Separator size={10} />
          <DropdownFinancialData
            financialData={financialData}
            setFinancialData={setFinancialData}
          />
          <Separator size={10} />
          <DropdownServicesData
            workerServices={workerServices}
            createService={createService}
          />
          <Separator size={20} />
          <button
            type="button"
            id="complete-worker-profile-finish-button"
            className="orange-button"
            style={styles.finish}
            onClick={handleFinish}
            disabled={isFinishDisabled}>
            {t('finish')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CompleteWorkerProfile;
