import React, { FC, useContext, useState } from 'react';
import { TitleUnderline } from '../Title/TitleUnderline';
import { t } from 'i18next';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { TopLabelTextArea } from '../Inputs/TopLabelTextArea';
import { Separator } from '../Separators/Separator';
import { API } from '../../network/API';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { ProfileContext } from '../../context/ProfileContext';

export const ProfileSettings: FC = () => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const { profile, reloadProfile } = useContext(ProfileContext);
  const [birthdate, setBirthdate] = useState<string | undefined>(
    profile?.birthdate,
  );
  const [description, setDescription] = useState<string | undefined>(
    profile?.profile_description,
  );
  const [mainJob, setMainJob] = useState<string | undefined>(profile?.main_job);
  const [pricePerHour, setPricePerHour] = useState<string | undefined>(
    `${profile?.main_price_per_hour}`,
  );

  const handleChangeBirtdate = (event: React.ChangeEvent<HTMLInputElement>) =>
    setBirthdate(event.target.value);
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setDescription(event.target.value);
  const handleChangeMainJob = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMainJob(event.target.value);
  const handleChangePricePerHour = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setPricePerHour(event.target.value);

  const onSave = () => {
    showLoader();
    API.user
      .editProfile(
        birthdate,
        description,
        mainJob,
        pricePerHour ? Number.parseFloat(pricePerHour) : undefined,
      )
      .then(() => {
        reloadProfile();
        showBanner(t('success'), t('profile_updated'));
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };
  return (
    <div>
      <TitleUnderline title={t('profile')} />
      <div className="grid-2-columns">
        <TopLabelInput
          label={t('full_name')}
          id="full-name-input"
          value={profile?.username}
          disabled
        />
        <TopLabelInput
          label={t('birthdate')}
          id="birthdate-input"
          type="date"
          value={birthdate}
          onChange={handleChangeBirtdate}
        />
      </div>
      <TopLabelTextArea
        label={t('description')}
        id="description-input"
        value={description}
        onChange={handleChangeDescription}
      />
      {profile?.role === 'worker' && (
        <div className="grid-2-columns">
          <TopLabelInput
            label={t('main_job')}
            id="job-input"
            value={mainJob}
            onChange={handleChangeMainJob}
          />
          <TopLabelInput
            label={t('price_per_hour')}
            id="price_per_hour-input"
            type="number"
            value={pricePerHour}
            onChange={handleChangePricePerHour}
          />
        </div>
      )}
      <Separator size={20} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          className="orange-button"
          style={{ width: 'unset', paddingInline: 40 }}
          onClick={onSave}>
          {t('save')}
        </button>
      </div>
    </div>
  );
};
