import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FullScreenBackgroundImgSlider } from '../components/Slider/FullScreenBackgroundImgSlider';
import { t } from 'i18next';
import { isPC } from '../utils/screenWidth';
import { useSearchParams } from 'react-router-dom';
import { decodeURLData, parseDecodedData } from '../utils/miscUtils';
import { saveClientAPIToken } from '../network/ClientAPIKey';
import { API } from '../network/API';
import { COLORS } from '../utils/colors';
import { Router } from '../navigation/Router';

const VerifyAccount: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>();

  const handleLogin = () => Router.goToLogin();

  const verifyAccount = () => {
    const urlData = searchParams.get('data');
    if (!urlData) {
      setMessage(t('general_error'));
      return;
    }
    const strData = decodeURLData(urlData);
    const parsedData = parseDecodedData(strData);
    const expires = parsedData.expires;
    const token = parsedData.token;
    const timestamp = Date.now() / 1000;

    if (!token) {
      setMessage(t('general_error'));
      return;
    }

    if (expires <= timestamp) {
      setMessage(t('url_expired'));
      return;
    }

    saveClientAPIToken(token);
    API.user
      .verifyAccount()
      .then(() => setMessage(t('email_verified')))
      .catch((error) => setMessage(error.message));
  };

  useEffect(() => verifyAccount(), []);
  return (
    <>
      <Helmet>
        <title>{t('verify_account')} - Hephaestus</title>
      </Helmet>
      <div>
        <FullScreenBackgroundImgSlider />
        <div className="flex-row--reverse-800 flex-1">
          <div className="double-border-component">
            <div style={{ height: isPC() ? undefined : '100vh' }}>
              <div style={{ paddingInline: 20 }}>
                <h2 style={{ color: COLORS.blue }}>{message}</h2>
                <button type="button" className="orange-button" onClick={handleLogin}>
                  {t('login')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
