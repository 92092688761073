import React, { ChangeEvent, CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';
import { EyeSlashIcon } from '../../assets/icons/EyeSlashIcon';
import { EyeIcon } from '../../assets/icons/EyeIcon';

interface PasswordInputProps {
  label: string;
  id: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ id, label, value, onChange }) => {
  const rootStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBlock: 8,
  };
  const innerInputStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    border: `2px solid ${COLORS.orange}`,
    borderRadius: 8,
    boxShadow: `1px 2px 5px ${COLORS.gray}`,
    padding: 5,
    gap: 10,
    alignItems: 'center',
  };
  const inputStyle: CSSProperties = {
    border: 0,
    flex: 1,
    color: COLORS.blue,
    fontSize: 16,
    outline: 'none',
  };
  const labelStyle: CSSProperties = {
    color: COLORS.blue,
    marginBottom: 5,
  };
  const buttonstyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const handlePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const inputType = passwordVisible ? 'text' : 'password';
  return (
    <div style={rootStyle}>
      <label style={labelStyle} htmlFor={id}>
        {label}:
      </label>
      <div style={innerInputStyle}>
        <input style={inputStyle} id={id} value={value} onChange={onChange} type={inputType} />
        <button type="button" onClick={handlePasswordVisibility} style={buttonstyle}>
          {passwordVisible ? (
            <EyeSlashIcon width={20} height={20} color={COLORS.blue} />
          ) : (
            <EyeIcon width={20} height={20} color={COLORS.blue} />
          )}
        </button>
      </div>
    </div>
  );
};
