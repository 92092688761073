import { TFunction } from 'i18next';
import { TextSlugModel } from '../models/TextSlugModel';

export type UserType = 'user' | 'worker';

const UserTypesConst = (t: TFunction<['translation', ...string[]], undefined>): TextSlugModel[] => {
  return [
    {
      text: t('user').toUpperCase(),
      slug: 'user',
    },
    {
      text: t('worker').toUpperCase(),
      slug: 'worker',
    },
  ];
};

export default UserTypesConst;
