const ita = {
  create_an_account: 'Creare un account',
  already_have_account_question: 'Hai già un account?',
  login: 'Login',
  full_name: 'Nome e cognome',
  email: 'Email',
  password: 'Password',
  user_type: 'Tipo di utente',
  user: 'Utente',
  worker: 'Lavoratore',
  i_accept_privacy_and_security:
    'Accetto la politica sulla privacy e sulla sicurezza.',
  register: 'Registrati',
  or: 'O',
  error: 'Errore',
  write_a_valid_name: 'Per favore scrivi un nome valido.',
  write_a_valid_email: 'Per favore scrivi un email valido.',
  write_a_valid_password:
    'La password deve contenere almeno una lettera MAIUSCOLA, una lettera minuscola, un numero e deve essere lunga almeno 8 caratteri.',
  choose_a_valid_user_type: 'Scegli un tipo di utente valido.',
  must_accept_privacy:
    'Devi accettare la nostra politica sulla privacy e sulla sicurezza.',
  register_success: 'Registrato con successo!',
  register_success_message:
    "Ti sei registrato con successo. Tra poco riceverai un'email per attivare il tuo account, assicurati di controllare la casella di spam se non vedi la mail nella tua casella di posta.",
  close: 'Chiudi',
  not_have_account_question: 'Non hai ancora un account?',
  password_forget_question: 'Hai dimenticato la tua password?',
  welcome_exclamation: 'Benvenut@!',
  home: 'Home',
  choose_role: 'Scegli il tuo ruolo',
  save: 'Salva',
  welcome: 'Benvenut@',
  we_need_role_type:
    'Dobbiamo sapere qual è il tuo ruolo nella nostra applicazione.',
  complete_worker_profile: 'Completa Profilo Worker',
  sign_out: 'Chiudi sessione',
  birthdate: 'Data di nascita',
  address: 'Indirizzo',
  city: 'Città',
  province_or_state: 'Provincia o stato',
  country: 'Paese',
  general_error: 'Qualcosa è andato storto, riprova più tardi.',
  finish_form_worker_request:
    'Per finalizzare il tuo profilo, ti chiediamo di completare il seguente modulo.',
  personal_data: 'Dati personali',
  financial_data: 'Dati finanziari',
  vat_number: 'Partita IVA',
  services_data: 'Dati di servizi',
  service_name: 'Nome del servizio',
  action_radio: 'Radio di azzione (Km)',
  price_per_hour: 'Prezzo per ora (€/h)',
  service_description: 'Descrizione del servizio',
  finish: 'Finaliza',
  password_recovery: 'Recupero della password',
  password_recovery_steps:
    "Scrivi l'e-mail del tuo account qui sotto. Ti invieremo un messaggio con i passi necessari per reimpostare la tua password. Non dimenticare di controllare la casella spam.",
  send: 'Invia',
  password_reset: 'Reimposta password',
  new_password: 'Nuova password',
  repeat_password: 'Ripeti password',
  success: 'Successo',
  email_sent: 'Email inviato!',
  missing_data: 'Mancano alcuni dati necessari.',
  url_expired: 'Questo URL è scaduto, richiedine uno nuovo.',
  view_profile: 'Vedi profilo',
  messages: 'Messagi',
  view_all: 'Vedi tutto',
  notifications: 'Notificazioni',
  dashboard: 'Dashboard',
  jobs: 'Lavori',
  create_service: 'Crea servizio',
  create_job: 'Crea lavoro',
  settings: 'Aggiusti',
  services_and_professionals: 'Servizi e professionisti',
  services: 'Servizi',
  professionals: 'Professionisti',
  search: 'Cercare',
  filters: 'Filtri',
  filter: 'Filtrare',
  reset: 'Reset',
  job_name: 'Nome del lavoro',
  job_description: 'Descrizione del lavoro',
  job_created_success: 'Lavoro creato con successo.',
  hire: 'Assumere',
  created: 'Creato',
  profile: 'Profilo',
  service_deleted: 'Servizio cancellato con successo.',
  edit_profile: 'Editare profilo',
  contact: 'Contattare',
  description: 'Descrizione',
  delete_user: 'Cancellare utente',
  job_proposal: 'Proposta di lavoro',
  job_proposal_message: 'Mi piacerebbe fare una proposta.',
  new_offer: 'Nuova offerta',
  offer_title: "Titolo dell'offerta",
  offer_description: "Descrizione dell'offerta",
  send_offer: 'Invia offerta',
  refuse: 'Rifiutare',
  accept: 'Accettare',
  delete: 'Cancellare',
  active_offers: 'Offerte attive',
  no_active_offers:
    'No tienes ninguna oferta activa, sigue adelante y consulta la sección de empleos, profesionales y servicios.',
  review: 'Recensione',
  leave_review_to: 'Lascia una recensione a {{username}} per "{{job}}"',
  profile_updated: 'Profilo aggiornato con successo.',
  main_job: 'Lavoro principale',
  email_verified: 'Email verificata con successo.',
  verify_account: 'Verifica account',
};

export default ita;
