import React from 'react';
import IconProps from './IconProps';

export const ChevronUpIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0.689453L17.75 9.43945L15.6992 11.4902L9 4.79102L2.30078 11.4902L0.25 9.43945L9 0.689453Z" fill={color} />
    </svg>
  );
};
