import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FullScreenBackgroundImgSlider } from '../components/Slider/FullScreenBackgroundImgSlider';
import { t } from 'i18next';
import { isPC } from '../utils/screenWidth';
import { PasswordResetForm } from '../components/Forms/PasswordResetForm';

const PasswordReset: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{t('password_reset')} - Hephaestus</title>
      </Helmet>
      <div>
        <FullScreenBackgroundImgSlider />
        <div className="flex-row--reverse-800 flex-1">
          <div className="double-border-component">
            <div style={{ height: isPC() ? undefined : '100vh' }}>
              <PasswordResetForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
