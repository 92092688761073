import React, { ChangeEvent, CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';

type InpuType = 'text' | 'email' | 'number' | 'date';

interface TopLabelInputProps {
  label: string;
  id: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: InpuType;
  disabled?: boolean;
}

export const TopLabelInput: React.FC<TopLabelInputProps> = ({ id, label, value, onChange, type = 'text', disabled = false }) => {
  const rootStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBlock: 8,
  };
  const inputStyle: CSSProperties = {
    border: `2px solid ${COLORS.orange}`,
    borderRadius: 8,
    padding: 6,
    boxShadow: `1px 2px 5px ${COLORS.gray}`,
    color: COLORS.blue,
    fontSize: 16,
    outline: 'none',
    cursor: disabled ? 'default' : 'text',
  };
  const labelStyle: CSSProperties = {
    color: COLORS.blue,
    marginBottom: 5,
  };
  return (
    <div style={rootStyle}>
      <label style={labelStyle} htmlFor={id}>
        {label}:
      </label>
      <input style={inputStyle} id={id} value={value} onChange={onChange} type={type} disabled={disabled} />
    </div>
  );
};
