import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/colors';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { TopLabelInput } from '../components/Inputs/TopLabelInput';
import { TopLabelTextArea } from '../components/Inputs/TopLabelTextArea';
import { Separator } from '../components/Separators/Separator';
import loaderControls from '../components/Loader/loaderControls';
import bannerControls from '../components/Banner/bannerControls';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { Loader } from '../components/Loader/Loader';
import { Banner } from '../components/Banner/Banner';

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Form = styled.div`
  background: white;
  padding-inline: 20px;
  width: 80%;
  border: 1px solid ${COLORS.gray_50};
  border-radius: 10px;
  box-shadow: 3px 3px 7px ${COLORS.gray_50};
  padding: 20px;
`;

const NewJob: FC = () => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    bannerMessage,
    bannerTitle,
    closeBanner,
  } = bannerControls();
  const [jobName, setJobName] = useState<string>('');
  const [jobCity, setJobCity] = useState<string>('');
  const [jobCountry, setJobCountry] = useState<string>('');
  const [jobPrice, setJobPrice] = useState<string>('');
  const [jobDescription, setJobDescription] = useState<string>('');

  const handleJobNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setJobName(event.target.value);
  const handleJobCityChange = (event: ChangeEvent<HTMLInputElement>) =>
    setJobCity(event.target.value);
  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) =>
    setJobCountry(event.target.value);
  const handleJobPriceChange = (event: ChangeEvent<HTMLInputElement>) =>
    setJobPrice(event.target.value);
  const handleJobDescriptionChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => setJobDescription(event.target.value);

  const resetValues = () => {
    setJobName('');
    setJobCity('');
    setJobPrice('');
    setJobDescription('');
  };

  const onSave = () => {
    openLoader();
    API.user
      .createJob(
        jobName,
        jobDescription,
        jobCity,
        jobCountry,
        Number.parseFloat(jobPrice),
      )
      .then((response) => {
        logger('NewJob.tsx - onSave()', response, 'success');
        showBanner(t('success'), t('job_created_success'));
        resetValues();
      })
      .catch((error) => {
        logger('NewJob.tsx - onSave()', error.message, 'error');
        showBanner(t('success'), error.message);
      })
      .finally(() => closeLoader());
  };

  /* istanbul ignore next */
  const buttonDisabled =
    !Boolean(jobName.length) ||
    !Boolean(jobDescription.length) ||
    !Boolean(jobCity.length) ||
    !Boolean(jobCountry.length) ||
    !Boolean(jobPrice.length) ||
    Number.parseFloat(jobPrice) <= 0;

  return (
    <>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <Helmet>
        <title>{t('create_job')} - Hephaestus</title>
      </Helmet>
      <Body>
        <Form>
          <h2 className="text-center orange">{t('create_job')}</h2>
          <div className="grid-4-columns">
            <TopLabelInput
              label={t('job_name')}
              id="job_name-input"
              value={jobName}
              onChange={handleJobNameChange}
            />
            <TopLabelInput
              label={t('city')}
              id="city-input"
              value={String(jobCity)}
              onChange={handleJobCityChange}
            />
            <TopLabelInput
              label={t('country')}
              id="country-input"
              value={jobCountry}
              type="text"
              onChange={handleCountryChange}
            />
            <TopLabelInput
              label={t('price_per_hour')}
              id="price_per_hour-input"
              value={jobPrice}
              type="number"
              onChange={handleJobPriceChange}
            />
          </div>
          <TopLabelTextArea
            label={t('job_description')}
            id="job_description-textarea"
            value={jobDescription}
            onChange={handleJobDescriptionChange}
          />
          <Separator size={20} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              type="button"
              className="orange-button"
              id="new-service-save-button"
              style={{ width: 'unset', paddingInline: 40 }}
              onClick={onSave}
              disabled={buttonDisabled}>
              {t('save')}
            </button>
          </div>
        </Form>
      </Body>
    </>
  );
};

export default NewJob;
