import React from 'react';
import IconProps from './IconProps';

export const HamburguerMenuIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H30V3.35938H0V0ZM0 11.6406V8.35938H30V11.6406H0ZM0 20V16.6406H30V20H0Z" fill={color} />
    </svg>
  );
};
