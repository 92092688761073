import React, { ChangeEvent, FC, useContext } from 'react';
import { t } from 'i18next';
import { TitleUnderline } from '../Title/TitleUnderline';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { Separator } from '../Separators/Separator';
import { useFinancialData } from '../../hooks/useFinancialData';
import { Profile } from '../../models/Profile';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { API } from '../../network/API';

interface FinancialDataSettingsProps {
  profile?: Profile;
}

export const FinancialDataSettings: FC<FinancialDataSettingsProps> = ({
  profile,
}) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const { financialData, setFinancialData } = useFinancialData(profile);
  const handleChangeVatNumber = (event: ChangeEvent<HTMLInputElement>) =>
    setFinancialData({
      ...financialData,
      vat_number: event.target.value,
    });

  const onSave = () => {
    showLoader();
    API.user
      .createFinancialData(financialData)
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };
  return (
    <div>
      <TitleUnderline title={t('financial_data')} />
      <div className="grid-3-columns">
        <TopLabelInput
          label={t('vat_number')}
          id="vat-number-input"
          value={financialData?.vat_number}
          onChange={handleChangeVatNumber}
        />
      </div>
      <Separator size={20} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          id="dropdown-financial-data-save-button"
          className="orange-button"
          style={{ width: 'unset', paddingInline: 40 }}
          onClick={onSave}>
          {t('save')}
        </button>
      </div>
    </div>
  );
};
