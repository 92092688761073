const es = {
  create_an_account: 'Crear una cuenta',
  already_have_account_question: '¿Ya tienes una cuenta?',
  login: 'Iniciar sesión',
  full_name: 'Nombre completo',
  email: 'Correo electrónico',
  password: 'Contraseña',
  user_type: 'Tipo de usuario',
  user: 'Usuario',
  worker: 'Trabajador',
  i_accept_privacy_and_security:
    'Acepto la política de privacidad y seguridad.',
  register: 'Registrarse',
  or: 'O',
  error: 'Error',
  write_a_valid_name: 'Escribe un nombre válido.',
  write_a_valid_email: 'Escribe un correo electrónico válido.',
  write_a_valid_password:
    'La contraseña debe tener al menos una letra MAYÚSCULA, una letra minúscula, un número y debe tener al menos 8 caracteres de longitud.',
  choose_a_valid_user_type: 'Escoge un tipo de usuario válido.',
  must_accept_privacy:
    'Debes aceptar nuestra política de privacidad y seguridad.',
  register_success: '¡Registrado con éxito!',
  register_success_message:
    'Te has registrado con éxito. En un momento recibirás un correo electrónico para activar tu cuenta. Asegúrate de revisar la bandeja de spam si no ves el email en tu bandeja de entrada.',
  close: 'Cerrar',
  not_have_account_question: '¿Todavía no tienes una cuenta?',
  password_forget_question: '¿Has olvidado tu contraseña?',
  welcome_exclamation: '¡Bienvenido!',
  home: 'Página de inicio',
  choose_role: 'Elige tu rol',
  save: 'Guardar',
  welcome: 'Bienvenido',
  we_need_role_type:
    'Necesitamos saber cuál es su papel en nuestra aplicación.',
  complete_worker_profile: 'Completar Perfil Worker',
  sign_out: 'Cerrar sesión',
  birthdate: 'Fecha de nacimiento',
  address: 'Dirección',
  city: 'Ciudad',
  province_or_state: 'Provincia o estado',
  country: 'País',
  general_error: 'Algo ha salido mal, inténtalo más tarde.',
  finish_form_worker_request:
    'Para finalizar tu perfil, requerimos que completes el siguiente formulario.',
  personal_data: 'Datos personales',
  financial_data: 'Datos financieros',
  vat_number: 'Número de IVA',
  services_data: 'Datos de servicios',
  service_name: 'Nombre del servicio',
  action_radio: 'Radio de acción (Km)',
  price_per_hour: 'Precio por hora (€/h)',
  service_description: 'Descripción del servicio',
  finish: 'Finalizar',
  password_recovery: 'Recuperación de contraseña',
  password_recovery_steps:
    'Escribe el correo electrónico de tu cuenta a continuación. Te enviaremos un mensaje con los pasos necesarios para restablecer tu contraseña. No olvides mirar la carpeta de spam.',
  send: 'Enviar',
  password_reset: 'Restablecer contraseña',
  new_password: 'Nueva contraseña',
  repeat_password: 'Repetir contraseña',
  success: 'Éxito',
  email_sent: '¡Email enviado!',
  missing_data: 'Faltan algunos datos necesarios.',
  url_expired: 'Esta URL ha caducado, solicite una nueva.',
  view_profile: 'Ver perfil',
  messages: 'Mensajes',
  view_all: 'Ver todo',
  notifications: 'Notificaciones',
  dashboard: 'Inicio',
  jobs: 'Trabajos',
  create_service: 'Crear servicio',
  create_job: 'Crear trabajo',
  settings: 'Ajustes',
  services_and_professionals: 'Servicios y profesionales',
  services: 'Servicios',
  professionals: 'Profesionales',
  search: 'Buscar',
  filters: 'Filtros',
  filter: 'Filtrar',
  reset: 'Resetear',
  job_name: 'Nombre del trabajo',
  job_description: 'Descripción del trabajo',
  job_created_success: 'Trabajo creado con éxito.',
  hire: 'Contratar',
  created: 'Creado',
  profile: 'Perfil',
  service_deleted: 'Servicio borrado con éxito.',
  edit_profile: 'Editar perfil',
  contact: 'Contactar',
  description: 'Descripción',
  delete_user: 'Borrar usuario',
  job_proposal: 'Propuesta de trabajo',
  job_proposal_message: 'Me gustaría hacer una propuesta.',
  new_offer: 'Nueva oferta',
  offer_title: 'Título de la oferta',
  offer_description: 'Descripción de la oferta',
  send_offer: 'Enviar oferta',
  refuse: 'Rechazar',
  accept: 'Aceptar',
  delete: 'Borrar',
  active_offers: 'Ofertas activas',
  no_active_offers:
    'No tienes ninguna oferta activa, sigue adelante y consulta la sección de empleos, profesionales y servicios.',
  review: 'Reseña',
  leave_review_to: 'Deja una reseña a {{username}} por "{{job}}"',
  profile_updated: 'Perfil actualizado con éxito.',
  main_job: 'Trabajo principal',
  email_verified: 'Email verificado con éxito.',
  verify_account: 'Verificar cuenta',
};

export default es;
