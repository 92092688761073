import React, { useState } from 'react';
import { COLORS } from '../../utils/colors';
import { AccountCirlce } from '../../assets/icons/AccountCirlce';
import { Profile } from '../../models/Profile';
import { t } from 'i18next';
import { signOut } from '../../utils/user';
import loaderControls from '../Loader/loaderControls';
import { Loader } from '../Loader/Loader';
import styled from 'styled-components';
import { PopUpMenu } from './PopUpMenu';

const RootDiv = styled.div`
  background: ${COLORS.blue};
  display: flex;
  padding-inline: 20px;
  padding-block: 10px;
  position: sticky;
  top: 0;
`;
const ProfileButton = styled.button`
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  gap: 10px;
`;

interface UserOnlyNavbarProps {
  profile?: Profile;
}

export const UserOnlyNavbar: React.FC<UserOnlyNavbarProps> = ({ profile }) => {
  const { isLoaderVisible, openLoader } = loaderControls();

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const handleMenuVisibility = () => setMenuVisible(!menuVisible);

  const handleSignOut = () => {
    openLoader();
    signOut();
  };
  return (
    <RootDiv>
      {isLoaderVisible && <Loader />}
      <div style={{ flex: 1 }} />
      <ProfileButton type="button" id="user-only-navbar-menu-handler-button" onClick={handleMenuVisibility}>
        {profile?.username}
        <AccountCirlce width={24} height={24} color={COLORS.white} />
      </ProfileButton>
      {menuVisible && (
        <PopUpMenu>
          <button type="button" className="orange" id="user-only-navbar-sign-out-button" onClick={handleSignOut}>
            {t('sign_out')}
          </button>
        </PopUpMenu>
      )}
    </RootDiv>
  );
};
