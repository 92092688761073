import React from 'react';
import { COLORS } from '../../utils/colors';
import { IMAGES } from '../../utils/images';

export const Loader: React.FC = () => {
  return (
    <div
      id="loader"
      className="full-screen"
      style={{
        background: COLORS.black_70,
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img src={IMAGES.logo_full_trans_white} width={250} style={{ animation: 'pulse 1.5s linear infinite' }} />
    </div>
  );
};
