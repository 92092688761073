import { API } from '../network/API';
import { logger } from '../utils/logger';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { ExtendedOffer } from '../models/OfferModel';
import { Profile } from '../models/Profile';

export const useOffers = (profile?: Profile) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const [offers, setOffers] = useState<ExtendedOffer[]>([]);

  const getOffers = (loaderShow: boolean) => {
    if (!profile) return;
    if (loaderShow) showLoader();
    API.user
      .getUserOffers()
      .then((response) => setOffers(response))
      .catch((error) => {
        logger('useOffers.tsx - getOffers', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  useEffect(() => {
    getOffers(true);
    const interval = setInterval(() => getOffers(false), 5000);
    return () => clearInterval(interval);
  }, [profile]);
  return {
    offers,
    getOffers,
  };
};
