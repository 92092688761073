import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { MoneyInput } from '../Inputs/MoneyInput';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';
import { ChoicesInput } from '../Inputs/ChoicesInput';
import { SearchInput } from '../Inputs/SearchInput';
import { Job } from '../../models/JobModel';

const FilterDiv = styled.div`
  width: 270px;
  background: white;
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border: 0;
  border-radius: 10px;
  padding-block: 10px;
  padding-inline: 15px;
  display: flex;
  flex-direction: column;

  & > span {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 700px) {
    width: 90%;
    box-shadow: 0 0 0 ${COLORS.gray_50};
    margin-top: 5px;
    margin-left: 0;
    border: 1px solid ${COLORS.gray_50};
  }
`;

const FilterButton = styled.button`
  background: ${COLORS.blue};
  color: ${COLORS.white};
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 7px ${COLORS.gray};

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray};
  }
`;

interface JobsFilterProps {
  jobs: Job[];
  handleFilter: (
    minPrice?: number,
    city?: string,
    searchString?: string,
  ) => void;
  handleResetFilters: () => void;
}

export const JobsFilter: React.FC<JobsFilterProps> = ({
  jobs,
  handleFilter,
  handleResetFilters,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [jobsCities, setJobsCities] = useState<string[]>([]);
  const [minMoney, setMinMoney] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [selectedCity, setSelecetdCity] = useState<string>('');

  /* istanbul ignore next */
  const handleChangeMinMoney = (event: ChangeEvent<HTMLInputElement>) =>
    setMinMoney(event.target.value);

  /* istanbul ignore next */
  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const resetFilters = () => {
    setMinMoney('');
    setCity('');
    setSelecetdCity('');
    setSearchValue('');
    handleResetFilters();
  };

  useEffect(() => {
    const mappedAddresses = jobs.map(
      (job) => `${job.city}, ${job.country}`,
    );
    const filteredAddresses = mappedAddresses.filter(
      (value, index) => mappedAddresses.indexOf(value) === index,
    );
    setJobsCities(filteredAddresses);
  }, [jobs]);
  return (
    <FilterDiv>
      <span style={{ fontWeight: 500, fontSize: 16 }}>{t('filters')}</span>
      <Separator size={20} />
      <span>{t('jobs')}:</span>
      <Separator size={10} />
      <SearchInput
        id="jobs-filter-search-input"
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      <Separator size={20} />
      <span>{t('price_per_hour')} Min:</span>
      <Separator size={10} />
      <MoneyInput
        id="jobs-filter-max-money-input"
        value={minMoney}
        onChange={handleChangeMinMoney}
        placeholder="Min."
      />
      <Separator size={20} />
      <span>{t('city')}:</span>
      <Separator size={10} />
      <ChoicesInput
        id="jobs-filter-city-input"
        placeholder="Valencia, Spain"
        value={city}
        setValue={setCity}
        choices={jobsCities}
        handleSelectedChoice={setSelecetdCity}
        selectedValue={selectedCity}
      />
      <Separator size={30} />
      <FilterButton
        id="jobs-filter-filter-button"
        type="button"
        onClick={() =>
          handleFilter(
            Number.parseFloat(minMoney),
            selectedCity || city,
            searchValue,
          )
        }>
        {t('filter')}
      </FilterButton>
      <Separator size={20} />
      <FilterButton
        id="jobs-filter-reset-button"
        type="button"
        onClick={resetFilters}
        style={{ background: COLORS.orange }}>
        {t('reset')}
      </FilterButton>
    </FilterDiv>
  );
};
