import React, { ChangeEvent } from 'react';
import { IMAGES } from '../../utils/images';
import { PasswordInput } from '../Inputs/PasswordInput';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { Separator } from '../Separators/Separator';
import { LineSeparator } from '../Separators/LineSeparator';
import { SocialButtons } from '../Buttons/SocialButtons';
import { isPC } from '../../utils/screenWidth';
import { t } from 'i18next';
import { Router } from '../../navigation/Router';
import { Loader } from '../Loader/Loader';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Banner } from '../Banner/Banner';
import { handleLogin } from '../../utils/Login';
import { LoginRegisterStyles } from '../../assets/css/LoginRegisterStyles';

export const LoginForm: React.FC = () => {
  const styles = LoginRegisterStyles;

  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();

  /* istanbul ignore next */
  const handleGoRegister = () => Router.goToRegister();

  /* istanbul ignore next */
  const handleGoPasswordRecovery = () => Router.goToPasswordRecovery();

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  /* istanbul ignore next */
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  /* istanbul ignore next */
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  /* istanbul ignore next */
  const loginButtonDisabled =
    !Boolean(email.length) || !Boolean(password.length);

  /* istanbul ignore next */
  const onSuccess = () => Router.goToHome();

  /* istanbul ignore next */
  const handleLoginPress = () =>
    handleLogin({
      t,
      showBanner,
      openLoader,
      closeLoader,
      onSuccess,
      email,
      password,
    });

  return (
    <>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <div
        className={`flex-column flex-1 width-80 ${isPC() ? 'justify-center' : ''}`}>
        <Separator size={30} />
        {isPC() && <img src={IMAGES.logo_full_trans} style={styles.img} />}
        <h1 style={styles.h1}>{t('welcome_exclamation')}</h1>
        <span style={styles.span}>
          {t('not_have_account_question')}{' '}
          <button
            type="button"
            className="button-link-orange"
            onClick={handleGoRegister}>
            {t('register')}
          </button>
        </span>
        <Separator size={20} />
        <div style={styles.div}>
          <TopLabelInput
            label={t('email')}
            id="email-input"
            value={email}
            onChange={handleEmailChange}
            type="email"
          />
          <PasswordInput
            label={t('password')}
            id="password-input"
            value={password}
            onChange={handlePasswordChange}
          />
          <Separator size={10} />
          <button
            type="button"
            className="button-link-gray"
            onClick={handleGoPasswordRecovery}
            style={styles.forgetPassword}>
            {t('password_forget_question')}
          </button>
          <Separator size={30} />
          <button
            type="button"
            className="orange-button"
            onClick={handleLoginPress}
            disabled={loginButtonDisabled}>
            {t('login')}
          </button>
          <Separator size={20} />
          <Separator size={20} />
          {isPC() && (
            <>
              <div style={styles.textSeparator}>
                <LineSeparator size={1} />
                <span>{t('or')}</span>
                <LineSeparator size={1} />
              </div>
              <SocialButtons />
            </>
          )}
        </div>
        <Separator size={30} />
      </div>
    </>
  );
};
