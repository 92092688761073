import { FinancialData } from '../models/FinancialData';
import { DefaultControls } from '../models/FunctionInterface';
import { WorkerAddress } from '../models/WorkerAddress';
import { Router } from '../navigation/Router';
import { API } from '../network/API';
import { clearClientAPIToken, getClientAPIToken } from '../network/ClientAPIKey';
import { checkEmailValid } from './email';
import { logger } from './logger';

export const isUserTokenSaved = () => {
  const token = getClientAPIToken();
  return token !== null && token !== '';
};

export const checkLoggedIn = () => {
  const loggedIn = isUserTokenSaved();
  if (loggedIn) {
    Router.goToHome();
  }
};

export const checkLoggedOut = () => {
  const loggedOut = isUserTokenSaved();
  if (!loggedOut) {
    Router.goToLogin();
  }
};

export const signOut = () => {
  clearClientAPIToken();
  Router.goToLogin();
};

export const changeRole = async ({ t, openLoader, closeLoader, showBanner }: DefaultControls, role?: string) => {
  openLoader();
  try {
    await API.user.editRole(role);
    logger('user.ts - changeRole()', '', 'success');
    closeLoader();
    Router.goToHome();
  } catch (error: any) {
    closeLoader();
    showBanner(t('error'), error.message);
    logger('user.ts - changeRole()', error.message, 'error');
  }
};

export const completePersonalData = async ({ t, openLoader, closeLoader, showBanner }: DefaultControls, workerAddress: WorkerAddress, birthdate: string, setWorkerAddress: (wa: WorkerAddress) => void) => {
  openLoader();
  try {
    await API.user.editBirthdate(birthdate);
    const newWorkerAddress = await API.user.createWorkerAddress(workerAddress);
    setWorkerAddress(newWorkerAddress);
    logger('user.ts - completePersonalData()', '', 'success');
    closeLoader();
    return true;
  } catch (error: any) {
    closeLoader();
    showBanner(t('error'), error.message);
    logger('user.ts - completePersonalData()', error.message, 'error');
    return false;
  }
};

export const completeFinancialData = async ({ t, openLoader, closeLoader, showBanner }: DefaultControls, financialData: FinancialData, setFinancialData: (fd: FinancialData) => void) => {
  openLoader();
  try {
    const newFinancialData = await API.user.createFinancialData(financialData);
    setFinancialData(newFinancialData);
    logger('user.ts - completeFinancialData()', '', 'success');
    closeLoader();
    return true;
  } catch (error: any) {
    closeLoader();
    showBanner(t('error'), error.message);
    logger('user.ts - completeFinancialData()', error.message, 'error');
    return false;
  }
};

export const sendPasswordRecoveryEmail = ({ t, openLoader, closeLoader, showBanner }: DefaultControls, email: string) => {
  if (!checkEmailValid(email)) {
    showBanner(t('error'), t('write_a_valid_email'));
    return;
  }
  openLoader();
  API.general
    .passwordRecovery(email)
    .then(() => {
      closeLoader();
      showBanner(t('success'), t('email_sent'));
    })
    .catch((error) => {
      closeLoader();
      showBanner(t('error'), error.message);
      logger('user.ts - sendPasswordRecoveryEmail()', error.message, 'error');
    });
};
