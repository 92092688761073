import { FC, useContext } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { t } from 'i18next';
import { Service } from '../../models/Services';
import { API } from '../../network/API';
import { Router } from '../../navigation/Router';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { ReviewComponent } from '../ReviewComponent';
import { isPhone } from '../../utils/miscUtils';

const ServiceRoot = styled.div`
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-block: 15px;

  & > span {
    font-size: 0.9rem;
    color: ${COLORS.blue};
  }
`;

const ServiceHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ServiceTitle = styled.button`
  color: ${COLORS.blue};
  font-size: 1.2rem;
  font-weight: 600;
  text-align: start;
`;

const ServiceUser = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;

    & > span {
      font-size: 1.1rem;
      font-weight: 600;
      text-align: end;
    }

    & > img {
      width: 50px;
      height: 50px;
      border: 0;
      border-radius: 8px;
      box-shadow: 0 0 4px ${COLORS.gray};
    }
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;

  & > div > span {
    color: ${COLORS.blue};
  }
`;

interface ServiceCardProps {
  service: Service;
}

export const ServiceCard: FC<ServiceCardProps> = ({ service }) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const navigateToUser = () => {
    if (isPhone()) {
      Router.goToProfileId(service.user?.id);
      return;
    }
    window.open(`/profile/${service.user?.id}`, '_blank', 'rel=noreferrer');
  };

  const handleNewChat = () => {
    if (!service.user) return;
    showLoader();
    API.user
      .sendMessage(service.user?.id, service.service, t('job_proposal_message'))
      .then((response) => {
        Router.goToChats(response.chat_id);
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };
  return (
    <ServiceRoot id="service-card-root">
      <ServiceHeader>
        <ServiceTitle type="button">{service.service}</ServiceTitle>
        <div style={{ flex: 1 }} />
        <ServiceUser type="button" onClick={navigateToUser}>
          <div>
            <span>{service.user?.username}</span>
            <img
              src={service.user?.avatar}
              alt="Profile image"
              loading="lazy"
              width={50}
              height={50}
            />
          </div>
          {service.user && (
            <ReviewComponent
              rating={service.user?.rating.rating}
              reviews={service.user?.rating.reviews}
              openModal={false}
            />
          )}
        </ServiceUser>
      </ServiceHeader>
      <p>{service.description}</p>
      <Footer>
        <div>
          <span>
            {t('price_per_hour')}: <b>{service.price_per_hour}€</b>
          </span>
          <br />
          <span>
            {service.city}, {service.country}
          </span>
        </div>
        <div style={{ flex: 1 }} />
        <div style={{ paddingInline: 10 }}>
          <button
            type="button"
            className="orange-button"
            onClick={handleNewChat}>
            {t('contact')}
          </button>
        </div>
      </Footer>
    </ServiceRoot>
  );
};
