import { CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';
import { isPC } from '../../utils/screenWidth';

export const LoginRegisterStyles = {
  img: {
    width: '50%',
    alignSelf: 'center',
  } as CSSProperties,
  h1: {
    textAlign: 'center',
    color: COLORS.blue,
  } as CSSProperties,
  span: {
    width: isPC() ? '80%' : '90%',
    alignSelf: 'center',
    textAlign: 'center',
  } as CSSProperties,
  div: {
    alignSelf: 'center',
    width: isPC() ? '80%' : '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as CSSProperties,
  textSeparator: {
    display: 'flex',
    width: '100%',
    gap: 10,
    alignItems: 'center',
    color: COLORS.blue,
  } as CSSProperties,
  socialRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
  } as CSSProperties,
  socialIconContainer: {
    padding: 8,
    display: 'flex',
    background: COLORS.white,
    boxShadow: `1px 2px 5px ${COLORS.gray}`,
    border: 0,
    borderRadius: 8,
  } as CSSProperties,
  forgetPassword: {
    alignSelf: 'flex-start',
    fontSize: 14,
  } as CSSProperties,
};
