import React, { FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Message } from '../../models/ChatModels';
import { OfferMesssage } from './OfferMesssage';

const ReceiverMessageDiv = styled.div`
  background: ${COLORS.blue_50};
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 20px 0;
  padding-inline: 20px;
  padding-block: 5px;
  align-self: flex-start;

  & > p {
    font-size: 1rem;
    margin: 0;
    white-space: pre-wrap;
  }

  & > span {
    font-size: 0.7rem;
  }
`;

interface ReceiverMessageProps {
  item: Message;
}

export const ReceiverMessage: FC<ReceiverMessageProps> = ({ item }) => {
  return (
    <ReceiverMessageDiv>
      {item.message_type === 'message' ? (
        <>
          <p>{item.message}</p>
          <span>{new Date(item.get_date).toLocaleString()}</span>
        </>
      ) : (
        <OfferMesssage
          item={item}
          showReceiverControls={item.offer_data?.state === 'pending'}
        />
      )}
    </ReceiverMessageDiv>
  );
};
