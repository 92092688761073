import React, { ChangeEvent, CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';
import { Separator } from '../Separators/Separator';

interface CheckboxProps {
  label: string;
  id: string;
  isChecked: boolean;
  onChangeCheck: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressUrl?: () => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  id,
  isChecked,
  onChangeCheck,
  onPressUrl,
}) => {
  const rootStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    marginBlock: 8,
    alignItems: 'center',
  };
  const inputStyle: CSSProperties = {
    color: COLORS.blue,
    fontSize: 16,
    outline: 'none',
  };
  const labelStyle: CSSProperties = {
    color: COLORS.blue,
  };
  return (
    <div style={rootStyle}>
      <input
        style={inputStyle}
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={onChangeCheck}
      />
      <Separator size={10} orientation="horizontal" />
      {onPressUrl ? (
        <label style={labelStyle} htmlFor={id}>
          <button type="button" className="button-link" onClick={onPressUrl}>
            {label}
          </button>
        </label>
      ) : (
        <label style={labelStyle} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
