import React, { ChangeEvent } from 'react';
import { IMAGES } from '../../utils/images';
import { PasswordInput } from '../Inputs/PasswordInput';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { TopLabelSelect } from '../Inputs/TopLabelSelect';
import { Separator } from '../Separators/Separator';
import { TextSlugModel } from '../../models/TextSlugModel';
import { LineSeparator } from '../Separators/LineSeparator';
import { SocialButtons } from '../Buttons/SocialButtons';
import { Checkbox } from '../Inputs/Checkbox';
import { isPC } from '../../utils/screenWidth';
import { t } from 'i18next';
import UserTypesConst from '../../constants/UserTypesConst';
import { Router } from '../../navigation/Router';
import { handleRegister } from '../../utils/Register';
import { Loader } from '../Loader/Loader';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Banner } from '../Banner/Banner';
import { LoginRegisterStyles } from '../../assets/css/LoginRegisterStyles';

export const RegisterForm: React.FC = () => {
  const styles = LoginRegisterStyles;

  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();

  const handleLogin = () => Router.goToLogin();

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [userType, setUserType] = React.useState<TextSlugModel | undefined>(
    UserTypesConst(t)[0],
  );
  const [privacyChecked, setPrivacyChecked] = React.useState<boolean>(false);

  /* istanbul ignore next */
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);
  /* istanbul ignore next */
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  /* istanbul ignore next */
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  /* istanbul ignore next */
  const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setUserType(
      UserTypesConst(t).find((item) => item.slug === event.target.value),
    );
  /* istanbul ignore next */
  const handlePrivacyChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPrivacyChecked(event.target.checked);

  /* istanbul ignore next */
  const registerButtonDisabled =
    !Boolean(name.length) ||
    !Boolean(email.length) ||
    !Boolean(password.length) ||
    !privacyChecked ||
    userType === undefined;

  /* istanbul ignore next */
  const handleRegisterPress = async () =>
    handleRegister({
      t,
      showBanner,
      openLoader,
      closeLoader,
      name,
      email,
      password,
      privacyChecked,
      userType,
      setName,
      setEmail,
      setPassword,
      setUserType,
      setPrivacyChecked,
    });

  return (
    <>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <div
        className={`flex-column flex-1 width-80 ${isPC() ? 'justify-center' : ''}`}>
        <Separator size={30} />
        {isPC() && <img src={IMAGES.logo_full_trans} style={styles.img} />}
        <h1 style={styles.h1}>{t('create_an_account')}</h1>
        <span style={styles.span}>
          {t('already_have_account_question')}{' '}
          <button
            type="button"
            className="button-link-orange"
            onClick={handleLogin}>
            {t('login')}
          </button>
        </span>
        <Separator size={20} />
        <div style={styles.div}>
          <TopLabelInput
            label={t('full_name')}
            id="name-input"
            value={name}
            onChange={handleNameChange}
          />
          <TopLabelInput
            label={t('email')}
            id="email-input"
            value={email}
            onChange={handleEmailChange}
            type="email"
          />
          <PasswordInput
            label={t('password')}
            id="password-input"
            value={password}
            onChange={handlePasswordChange}
          />
          <TopLabelSelect
            list={UserTypesConst(t)}
            label={t('user_type')}
            id="user-type-select"
            selected={userType}
            onChangeSelecton={handleUserTypeChange}
          />
          <Separator size={20} />
          <Checkbox
            label={t('i_accept_privacy_and_security')}
            id="privacy-checkbox"
            isChecked={privacyChecked}
            onChangeCheck={handlePrivacyChange}
            onPressUrl={Router.goToPrivacy}
          />
          <Separator size={30} />
          <button
            type="button"
            className="orange-button"
            onClick={handleRegisterPress}
            disabled={registerButtonDisabled}>
            {t('register')}
          </button>
          <Separator size={20} />
          {isPC() && (
            <>
              <div style={styles.textSeparator}>
                <LineSeparator size={1} />
                <span>{t('or')}</span>
                <LineSeparator size={1} />
              </div>
              <Separator size={20} />
              <SocialButtons />
            </>
          )}
        </div>
        <Separator size={30} />
      </div>
    </>
  );
};
