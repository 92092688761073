import React, { FC, useContext } from 'react';
import { TitleUnderline } from '../Title/TitleUnderline';
import { t } from 'i18next';
import { Separator } from '../Separators/Separator';
import { API } from '../../network/API';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { clearClientAPIToken } from '../../network/ClientAPIKey';
import { Router } from '../../navigation/Router';

export const DeleteUserSettings: FC = () => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const onDelete = () => {
    showLoader();
    API.user
      .deleteUser()
      .then(() => {
        clearClientAPIToken();
        Router.goToLanding();
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };
  return (
    <div>
      <TitleUnderline title={t('delete_user')} />
      <Separator size={20} />
      <button
        type="button"
        id="dropdown-financial-data-save-button"
        className="red-button"
        style={{ width: 'unset', paddingInline: 40 }}
        onClick={onDelete}>
        {t('delete_user')}
      </button>
    </div>
  );
};
