import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { ChatsContext } from '../../context/ChatsContext';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { ProfileContext } from '../../context/ProfileContext';
import { SearchInput } from '../Inputs/SearchInput';
import { Separator } from '../Separators/Separator';
import { Router } from '../../navigation/Router';
import { checkUnseenMessages } from '../../utils/messages';

const Root = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    padding: 10px;
    flex: 1;
    max-height: 200px;
  }
`;

const ChatButton = styled.button`
  display: flex;
  gap: 10px;
  padding-inline: 0;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
`;

const ChatUserDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const ChatButtonUsername = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${COLORS.blue};
`;

const ChatButtonTopic = styled.span`
  font-size: 0.8rem;
  color: ${COLORS.gray_50};
`;

const UnseenMessagesDiv = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${COLORS.orange};
`;

interface ChatsSidebarProps {
  selectedChat?: string;
  setSelectedChat: (chatId: string) => void;
}

export const ChatsSidebar: FC<ChatsSidebarProps> = ({
  selectedChat,
  setSelectedChat,
}) => {
  const { chats } = useContext(ChatsContext);
  const { profile } = useContext(ProfileContext);

  const [searchValue, setSearchValue] = useState<string>('');

  const handleOpenChat = (chatId: number) => {
    setSelectedChat(`${chatId}`);
    Router.goToChats(chatId);
  };

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  return (
    <Root>
      <SearchInput
        id=""
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      <Separator size={20} />
      {chats
        .filter((chat) => chat.topic.includes(searchValue))
        .map((chat) => {
          const secondUser = chat.users_list.find(
            (user) => user.id !== profile?.id,
          );
          return (
            <ChatButton
              key={chat.id}
              className={
                Number(selectedChat) === chat.id ? 'selected-gray' : ''
              }
              type="button"
              onClick={() => handleOpenChat(chat.id)}>
              <ChatUserDiv>
                <ChatButtonUsername>{secondUser?.username}</ChatButtonUsername>
                <ChatButtonTopic>{chat.topic}</ChatButtonTopic>
              </ChatUserDiv>
              {checkUnseenMessages([chat], profile) && <UnseenMessagesDiv />}
            </ChatButton>
          );
        })}
    </Root>
  );
};
