import React from 'react';
import { Profile } from '../models/Profile';

interface ProfileContextProps {
  profile?: Profile;
  reloadProfile: () => void;
}

export const ProfileContext = React.createContext<ProfileContextProps>({
  profile: undefined,
  reloadProfile: () => undefined,
});
