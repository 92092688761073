import { FC, useContext } from 'react';
import { Job } from '../../models/JobModel';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { t } from 'i18next';
import { ReviewComponent } from '../ReviewComponent';
import { API } from '../../network/API';
import { Router } from '../../navigation/Router';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { isPhone } from '../../utils/miscUtils';

const CardRoot = styled.div`
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-block: 15px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

const UserDiv = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const UsernameDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  & > span {
    font-weight: 500;
    color: ${COLORS.gray};
  }

  & > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 0;
    border-radius: 8px;
  }
`;

const Title = styled.span`
  color: ${COLORS.blue};
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;

  & > span {
    font-size: 0.9rem;
  }
`;

interface JobsCardProps {
  job: Job;
}

export const JobsCard: FC<JobsCardProps> = ({ job }) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const navigateToUser = () => {
    if (isPhone()) {
      Router.goToProfileId(job.user.id);
      return;
    }
    window.open(`/profile/${job.user.id}`, '_blank', 'rel=noreferrer');
  };

  const handleNewChat = () => {
    showLoader();
    API.user
      .sendMessage(job.user.id, job.title, t('job_proposal_message'))
      .then((response) => {
        Router.goToChats(response.chat_id);
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };
  return (
    <CardRoot>
      <Header>
        <Title>{job.title}</Title>
        <div style={{ flex: 1 }} />
        <UserDiv onClick={navigateToUser}>
          <UsernameDiv>
            <span>{job.user.username}</span>
            <img src={job.user.avatar} alt="profile" loading="lazy" />
          </UsernameDiv>
          <ReviewComponent
            rating={job.user.rating.rating}
            reviews={job.user.rating.reviews}
            openModal={false}
          />
        </UserDiv>
      </Header>

      <span>{job.description}</span>
      <Footer>
        <span style={{ color: COLORS.blue }}>
          {t('price_per_hour')}: {job.price_per_hour} €
        </span>
        <div style={{ flex: 1 }} />
        <span>
          {t('created')}: {job.creation.split('-').reverse().join('-')}
        </span>
      </Footer>
      <Footer>
        <span
          style={{
            color: COLORS.gray,
            fontSize: '0.8rem',
          }}>
          {job.city}, {job.country}
        </span>
        <div style={{ flex: 1 }} />
        <div style={{ paddingInline: 10 }}>
          <button
            type="button"
            className="orange-button"
            onClick={handleNewChat}>
            {t('contact')}
          </button>
        </div>
      </Footer>
    </CardRoot>
  );
};
