import React, { ChangeEvent, FC, useContext } from 'react';
import { TitleUnderline } from '../Title/TitleUnderline';
import { t } from 'i18next';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { Separator } from '../Separators/Separator';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { Profile } from '../../models/Profile';
import { API } from '../../network/API';
import { useWorkerAddress } from '../../hooks/useWorkerAddress';
import { useUserAddress } from '../../hooks/useUserAddress';

interface AddessSettingsProps {
  profile?: Profile;
}

export const AddessSettings: FC<AddessSettingsProps> = ({ profile }) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const { workerAddress, setWorkerAddress } = useWorkerAddress(profile);
  const { userAddress, setUserAddress } = useUserAddress(profile);

  const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    if (profile?.role === 'worker') {
      setWorkerAddress({ ...workerAddress, address: event.target.value });
      return;
    }
    setUserAddress({ ...userAddress, address: event.target.value });
  };
  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) => {
    if (profile?.role === 'worker') {
      setWorkerAddress({ ...workerAddress, city: event.target.value });
      return;
    }
    setUserAddress({ ...userAddress, city: event.target.value });
  };
  const handleChangeProvinceOrState = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (profile?.role === 'worker') {
      setWorkerAddress({
        ...workerAddress,
        province_or_state: event.target.value,
      });
      return;
    }
    setUserAddress({ ...userAddress, province_or_state: event.target.value });
  };
  const handleChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
    if (profile?.role === 'worker') {
      setWorkerAddress({ ...workerAddress, country: event.target.value });
      return;
    }
    setUserAddress({ ...userAddress, country: event.target.value });
  };

  const saveWorkerAddress = () => {
    showLoader();
    API.user
      .createWorkerAddress(workerAddress)
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  const saveUserAddress = () => {
    showLoader();
    API.user
      .createUserAddress(userAddress)
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  const onSave = () => {
    if (profile?.role === 'worker') {
      saveWorkerAddress();
      return;
    }
    saveUserAddress();
  };

  return (
    <div>
      <TitleUnderline title={t('address')} />
      <div className="grid-2-columns">
        <TopLabelInput
          label={t('address')}
          id="address-input"
          value={
            profile?.role === 'user'
              ? userAddress?.address
              : workerAddress?.address
          }
          onChange={handleChangeAddress}
        />
        <TopLabelInput
          label={t('city')}
          id="city-input"
          value={
            profile?.role === 'user' ? userAddress?.city : workerAddress?.city
          }
          onChange={handleChangeCity}
        />
        <TopLabelInput
          label={t('province_or_state')}
          id="province-or-state-input"
          value={
            profile?.role === 'user'
              ? userAddress?.province_or_state
              : workerAddress?.province_or_state
          }
          onChange={handleChangeProvinceOrState}
        />
        <TopLabelInput
          label={t('country')}
          id="country-input"
          value={
            profile?.role === 'user'
              ? userAddress?.country
              : workerAddress?.country
          }
          onChange={handleChangeCountry}
        />
      </div>
      <Separator size={20} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          className="orange-button"
          style={{ width: 'unset', paddingInline: 40 }}
          onClick={onSave}>
          {t('save')}
        </button>
      </div>
    </div>
  );
};
