import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import ita from './ita';
import es from './es';

export const getLanguage = () => {
  const lang = navigator.language;
  if (lang) {
    if (lang.includes('-')) {
      return lang.split('-')[0].toLowerCase();
    }
    return lang.split('_')[0].toLowerCase();
  }
  return 'en';
};

export const setUpTexts = () => {
  const resources = {
    en: {
      translation: en,
    },
    it: {
      translation: ita,
    },
    es: {
      translation: es,
    },
  };

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: getLanguage(),
      fallbackLng: 'it',
      interpolation: {
        escapeValue: false,
      },
    })
    .catch((error) => console.error('I18_ERROR:', error));
};
