import { createContext, FC, ReactNode } from 'react';
import { Chat } from '../models/ChatModels';
import { useChats } from '../hooks/useChats';

interface ChatsContextType {
  chats: Chat[];
}

export const ChatsContext = createContext<ChatsContextType>({
  chats: [],
});

interface ChatsProviderProps {
  children: ReactNode;
}

export const ChatsProvider: FC<ChatsProviderProps> = ({ children }) => {
  const { chats } = useChats();
  return (
    <ChatsContext.Provider value={{ chats: chats }}>
      {children}
    </ChatsContext.Provider>
  );
};
