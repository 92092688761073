import React, { ChangeEvent, CSSProperties, useContext, useState } from 'react';
import { t } from 'i18next';
import { UserOnlyNavbar } from '../components/Navbar/UserOnlyNavbar';
import { Banner } from '../components/Banner/Banner';
import { COLORS } from '../utils/colors';
import { TopLabelSelect } from '../components/Inputs/TopLabelSelect';
import { TextSlugModel } from '../models/TextSlugModel';
import UserTypesConst from '../constants/UserTypesConst';
import { Separator } from '../components/Separators/Separator';
import { changeRole } from '../utils/user';
import loaderControls from '../components/Loader/loaderControls';
import bannerControls from '../components/Banner/bannerControls';
import { Loader } from '../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import { ProfileContext } from '../context/ProfileContext';

const RoleChooser: React.FC = () => {
  const { profile } = useContext(ProfileContext);
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerMessage,
    bannerTitle,
  } = bannerControls();
  const styles = {
    root: {
      background: COLORS.white,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    } as CSSProperties,
    button: {
      width: 'unset',
      paddingInline: 40,
      alignSelf: 'flex-end',
    } as CSSProperties,
  };

  const [selectedUserType, setSelectedUserType] = useState<
    TextSlugModel | undefined
  >(UserTypesConst(t)[0]);
  /* istanbul ignore next */
  const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedUserType(
      UserTypesConst(t).find((item) => item.slug === event.target.value),
    );
  /* istanbul ignore next */
  const handleSave = () =>
    changeRole(
      { t, openLoader, closeLoader, showBanner },
      selectedUserType?.slug,
    );

  /* istanbul ignore next */
  if (!profile) {
    return <Banner title={t('error')} message={t('general_error')} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('choose_role')} - Hephaestus</title>
      </Helmet>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <div style={styles.root}>
        <UserOnlyNavbar profile={profile} />
        <div className="full-screen-white-card" style={{ flex: 'unset' }}>
          <h2 className="orange text-center">
            {t('welcome')} {profile.username}
          </h2>
          <span className="text-center">{t('we_need_role_type')}</span>
          <Separator size={20} />
          <TopLabelSelect
            list={UserTypesConst(t)}
            label={t('user_type')}
            id="user-type-select"
            selected={selectedUserType}
            onChangeSelecton={handleUserTypeChange}
          />
          <Separator size={30} />
          <button
            type="button"
            className="orange-button"
            style={styles.button}
            onClick={handleSave}>
            {t('save')}
          </button>
        </div>
      </div>
    </>
  );
};

export default RoleChooser;
