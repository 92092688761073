import React, { FC, useState } from 'react';
import { FullStarIcon } from '../assets/icons/FullStarIcon';
import { COLORS } from '../utils/colors';
import { EmptyStartIcon } from '../assets/icons/EmptyStartIcon';
import { ReviewIcon } from '../assets/icons/ReviewIcon';
import styled from 'styled-components';
import { XMarkIcon } from '../assets/icons/XMarkIcon';
import { API } from '../network/API';
import { ReviewModel } from '../models/Review';
import { Separator } from './Separators/Separator';

const ReviewDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 5px;

  & > div {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  & > button {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
const ModalReviewRoot = styled.div`
  background: ${COLORS.gray_50};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background: ${COLORS.white};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 ${COLORS.gray};
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;

    @media screen and (max-width: 800px) {
      width: 60%;
    }

    @media screen and (max-width: 600px) {
      width: 80%;
    }
  }
`;

const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-block: 5px;
`;

const renderStars = (rating: number) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <FullStarIcon key={i} width={20} height={20} color={COLORS.orange} />,
      );
    } else {
      stars.push(
        <EmptyStartIcon key={i} width={17} height={17} color={COLORS.orange} />,
      );
    }
  }
  return stars;
};

interface ReviewComponentProps {
  rating: number;
  reviews: number;
  openModal?: boolean;
}

export const ReviewComponent: FC<ReviewComponentProps> = ({
  rating,
  reviews,
  openModal = true,
}) => {
  const [reviewsStr, setReviewsStr] = useState<ReviewModel[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModal = () => {
    if (openModal) {
      if (showModal) {
        setShowModal(false);
      } else {
        fetchReviews();
        setShowModal(true);
      }
    }
  };

  const fetchReviews = () => {
    API.user.getReviews().then((response) => setReviewsStr(response));
  };

  return (
    <ReviewDiv>
      <div>
        {renderStars(rating).map((star) => star)}
        <span>{Math.round(rating)}</span>
      </div>
      <button type="button" onClick={handleModal}>
        <ReviewIcon width={20} height={20} color={COLORS.orange} />
        <span>{reviews}</span>
      </button>

      {showModal && (
        <ModalReviewRoot>
          <div>
            <button
              type="button"
              style={{ alignSelf: 'flex-end' }}
              onClick={handleModal}>
              <XMarkIcon width={24} height={24} color={COLORS.blue} />
            </button>
            {reviewsStr.map((review) => {
              return (
                <ReviewItem key={review.id}>
                  <span>
                    {review.reviewer_data.username} -{' '}
                    <strong>{review.related_offer_data.title}</strong>
                  </span>
                  <div>{renderStars(review.stars).map((star) => star)}</div>
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {review.review}
                  </span>
                  <Separator size={5} />
                  <span style={{ fontSize: '0.7rem' }}>
                    {new Date(review.created_at).toLocaleString()}
                  </span>
                </ReviewItem>
              );
            })}
          </div>
        </ModalReviewRoot>
      )}
    </ReviewDiv>
  );
};
