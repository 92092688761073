import React from 'react';
import IconProps from './IconProps';

export const ChevronDownIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6992 0.509766L17.75 2.56055L9 11.3105L0.25 2.56055L2.30078 0.509766L9 7.20898L15.6992 0.509766Z" fill={color} />
    </svg>
  );
};
