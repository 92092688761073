import { FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > span {
    font-size: 1.2rem;
  }

  & > #divider {
    height: 1px;
    width: 100%;
  }
`;

interface TitleUnderlineProps {
  color?: string;
  title?: string;
}

export const TitleUnderline: FC<TitleUnderlineProps> = ({
  color = COLORS.blue,
  title,
}) => {
  return (
    <Root>
      <span style={{ color: color }}>{title}</span>
      <div id="divider" style={{ background: color }}></div>
    </Root>
  );
};
