import React from 'react';
import { PopUpMenu } from './PopUpMenu';
import { signOut } from '../../utils/user';
import { t } from 'i18next';
import { Router } from '../../navigation/Router';

interface ProfileNavbarMenuProps {
  onClose: () => void;
}

export const ProfileNavbarMenu: React.FC<ProfileNavbarMenuProps> = ({
  onClose,
}) => {
  const handleViewProfile = () => {
    Router.goToProfile();
    onClose();
  };
  const handleSignOut = () => signOut();
  return (
    <PopUpMenu>
      <button
        type="button"
        className="text-start"
        id="navbar-view-profile-button"
        onClick={handleViewProfile}>
        {t('view_profile')}
      </button>
      <button
        type="button"
        className="orange text-start"
        id="navbar-sign-out-button"
        onClick={handleSignOut}>
        {t('sign_out')}
      </button>
    </PopUpMenu>
  );
};
