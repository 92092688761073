import { NavigateFunction } from 'react-router-dom';
import { Profile } from '../models/Profile';

let navigator: NavigateFunction;
export const setUpNavigator = (navFunction: NavigateFunction) => {
  navigator = navFunction;
};

export const Router = {
  goToLanding: () => navigator('/', { replace: true }),
  goToLogin: () => navigator('/login', { replace: true }),
  goToPasswordRecovery: () =>
    navigator('/password-recovery', { replace: true }),
  goToRegister: () => navigator('/register', { replace: true }),
  goToHome: () => navigator('/home', { replace: true }),
  goToRoleChooser: (profile: Profile) =>
    navigator('/role-chooser', { replace: true, state: { profile } }),
  goToCompleteWorkerProfile: () =>
    navigator('/complete-worker-profile', { replace: true }),
  goToNewService: () => navigator('/new-service', { replace: true }),
  goToServicesAndProfessionals: () =>
    navigator('/services-and-professionals', { replace: true }),
  goToNewJob: () => navigator('/new-job', { replace: true }),
  goToJobs: () => navigator('/jobs', { replace: true }),
  goToProfile: () => navigator('/profile', { replace: true }),
  goToChats: (chatId?: number) =>
    navigator(`/chats/${chatId ?? 0}`, { replace: true }),
  goToSettings: () => navigator('/settings/', { replace: true }),
  goToProfileId: (id?: number) =>
    navigator(`/profile/${id}`, { replace: true }),
  goToPrivacy: () => navigator('/privacy'),
};
