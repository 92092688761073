import React from 'react';
import IconProps from './IconProps';

export const ReviewIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.1895 0.939453C27.9642 0.939453 28.625 1.23568 29.1719 1.82812C29.7643 2.375 30.0605 3.03581 30.0605 3.81055V21.3105C30.0605 22.1309 29.7643 22.8372 29.1719 23.4297C28.625 23.9766 27.9642 24.25 27.1895 24.25H6.75L0.939453 30.0605V3.81055C0.939453 3.03581 1.21289 2.375 1.75977 1.82812C2.35221 1.23568 3.03581 0.939453 3.81055 0.939453H27.1895ZM17.7559 14.8848L22.8145 12.5605L17.7559 10.3047L15.5 5.31445L13.2441 10.3047L8.18555 12.5605L13.2441 14.8848L15.5 19.875L17.7559 14.8848Z"
        fill={color}
      />
    </svg>
  );
};
