import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { ProfileContext } from '../context/ProfileContext';
import { ProfileSettings } from '../components/Settings/ProfileSettings';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { FinancialDataSettings } from '../components/Settings/FinancialDataSettings';
import { Separator } from '../components/Separators/Separator';
import { AddessSettings } from '../components/Settings/AddessSettings';
import { PasswordResetSettings } from '../components/Settings/PasswordResetSettings';
import { DeleteUserSettings } from '../components/Settings/DeleteUserSettings';

const Root = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding-inline: 20px;
  padding-block: 10px;
`;

export const Settings: FC = () => {
  const { profile } = useContext(ProfileContext);
  return (
    <Root>
      <Helmet>
        <title>{t('settings')} - Hephaestus</title>
      </Helmet>
      <ProfileSettings />
      <Separator size={10} />
      <AddessSettings profile={profile} />
      <Separator size={10} />
      <FinancialDataSettings profile={profile} />
      <Separator size={10} />
      <PasswordResetSettings />
      <Separator size={10} />
      <DeleteUserSettings />
    </Root>
  );
};
