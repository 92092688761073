import { API } from '../network/API';
import { useEffect, useState } from 'react';
import { Chat } from '../models/ChatModels';
import { logger } from '../utils/logger';

export const useChats = () => {
  const [chats, setChats] = useState<Chat[]>([]);

  const fetchChats = () => {
    API.user
      .getUserChats()
      .then((response) => setChats(response))
      .catch((error) =>
        logger('useChat.ts - fetchChats()', error.message, 'error'),
      );
  };

  useEffect(() => {
    fetchChats();
    const interval = setInterval(() => fetchChats(), 10000);

    return () => clearInterval(interval);
  }, []);
  return { chats };
};
