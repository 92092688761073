import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import styled from 'styled-components';
import { Profile as ProfileModel } from '../models/Profile';
import { Helmet } from 'react-helmet-async';
import { COLORS } from '../utils/colors';
import { CheckCircleIcon } from '../assets/icons/CheckCircleIcon';
import { ReviewComponent } from '../components/ReviewComponent';
import { useWorkerServices } from '../hooks/useWorkerServices';
import useJobs from '../hooks/useJobs';
import { ProfileServiceCard } from '../components/Cards/ProfileServiceCard';
import { ProfileJobsCard } from '../components/Cards/ProfileJobsCard';
import { API } from '../network/API';
import { Router } from '../navigation/Router';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 40px;
  overflow-y: hidden;

  @media screen and (max-width: 600px) {
    padding-inline: 10px;
  }

  @media screen and (max-width: 400px) {
    padding-inline: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-block: 20px;

  & > img {
    width: 150px;
    height: 150px;
    border: 0;
    border-radius: 10px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

const Button = styled.button`
  background: ${COLORS.blue};
  color: ${COLORS.white};
  border: 0;
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 5px;
  font-weight: 500;
  box-shadow: 1px 1px 5px ${COLORS.gray};

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray};
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Username = styled.span`
  font-weight: 500;
  font-size: 1.1rem;
`;

const Price = styled.span`
  font-weight: 500;
  color: ${COLORS.blue};
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 1.2rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 20px;
  gap: 10px;
  border-top: 1px solid ${COLORS.orange};
  padding-top: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const UserProfileView: FC = () => {
  const { id } = useParams();
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const [userProfile, setUserProfile] = useState<ProfileModel>();

  const { workerServices } = useWorkerServices(userProfile, 'user_id');
  const { jobs } = useJobs('user_id', userProfile);

  const fetchProfile = () => {
    if (!id) return;
    showLoader();
    API.user
      .getProfileById(id)
      .then((response) => {
        setUserProfile(response);
        console.log(response);
      })
      .catch((error) => {
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleNewChat = () => {
    if (!id) return;
    showLoader();
    API.user
      .sendMessage(
        Number.parseInt(id),
        t('job_proposal'),
        t('job_proposal_message'),
      )
      .then((response) => {
        Router.goToChats(response.chat_id);
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  useEffect(() => fetchProfile(), [id]);
  return (
    <>
      <Helmet>
        <title>{`${userProfile?.username}`} - Hephaestus</title>
      </Helmet>
      <Root>
        <Header>
          <img src={userProfile?.avatar} alt="profile" loading="lazy" />
          <div style={{ flex: 1 }} />
          {userProfile?.role === 'worker' && (
            <Button type="button" onClick={handleNewChat}>
              {t('contact')}
            </Button>
          )}
        </Header>
        <Profile>
          <div>
            <Username>
              {userProfile?.username}{' '}
              {userProfile?.id_verified && (
                <CheckCircleIcon width={16} height={16} color={COLORS.blue} />
              )}
            </Username>
            {userProfile && (
              <ReviewComponent
                rating={userProfile?.rating.rating}
                reviews={userProfile?.rating.reviews}
              />
            )}
            <span>{userProfile?.profile_description}</span>
          </div>
          <div style={{ flex: 1 }} />
          {userProfile?.role === 'worker' && (
            <Price>
              {userProfile?.main_price_per_hour}€ {t('price_per_hour')}
            </Price>
          )}
        </Profile>
        {userProfile?.role === 'worker' && (
          <Body>
            <Title>{t('services')}</Title>
            {workerServices?.map((service) => {
              return (
                <ProfileServiceCard
                  key={service.id}
                  service={service}
                  profile={userProfile}
                />
              );
            })}
          </Body>
        )}
        {userProfile?.role === 'user' && (
          <Body>
            <Title>{t('jobs')}</Title>
            {jobs?.map((job) => {
              return (
                <ProfileJobsCard key={job.id} job={job} profile={userProfile} />
              );
            })}
          </Body>
        )}
      </Root>
    </>
  );
};
export default UserProfileView;
