import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useProfile } from '../hooks/useProfile';
import { useWorkerAddress } from '../hooks/useWorkerAddress';
import { useFinancialData } from '../hooks/useFinancialData';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { OfferCard } from '../components/Cards/OfferCard';
import { COLORS } from '../utils/colors';
import { useOffers } from '../hooks/useOffers';
import { TopLabelTextArea } from '../components/Inputs/TopLabelTextArea';
import ReactStars from 'react-rating-stars-component';
import { ExtendedOffer } from '../models/OfferModel';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';

const Root = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;
  flex-direction: column;
`;

const ModalReviewRoot = styled.div`
    background: ${COLORS.gray_50};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        background: ${COLORS.white};
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 ${COLORS.gray};
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        @media screen and (max-width: 800px) {
            width: 60%;
        }

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        & > span {
            font-weight: bold;
            color: ${COLORS.orange};
            align-self: flex-start;
        }

        & > button {
            background: ${COLORS.blue};
            color: ${COLORS.white};
            border: none;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            width: 100%;
        }
    }
`;

const Home: React.FC = () => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const { profile, checkUserTypeExist } = useProfile();
  const { workerAddress, checkWorkerAddressComplete } =
    useWorkerAddress(profile);
  const { financialData, checkFinancialDataComplete } =
    useFinancialData(profile);
  const { offers, getOffers } = useOffers(profile);

  const [selectedOffer, setSelectedOffer] = useState<ExtendedOffer>();
  const [review, setReview] = useState<string>('');
  const [stars, setStars] = useState<number>(0);

  const handleChangeReview = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setReview(e.target.value);
  const handleChangeStars = (newRating: number) => setStars(newRating);

  const checkPendingReviews = () => {
    API.user
      .pendingReviews()
      .then((response) => {
        if (response.length > 0) setSelectedOffer(response[0]);
      })
      .catch((error) =>
        logger('Home.tsx - checkPendingReviews()', error.message, 'error'),
      );
  };

  const saveReview = () => {
    if (review.length === 0) return;
    showLoader();
    API.user
      .saveReview(
        review,
        stars,
        selectedOffer?.from_offer_data.id !== profile?.id
          ? selectedOffer?.from_offer_data.id
          : selectedOffer?.to_offer_data.id,
        selectedOffer?.id,
      )
      .then(() => {
        setReview('');
        setStars(0);
        setSelectedOffer(undefined);
        checkPendingReviews();
      })
      .catch((error) => {
        logger('Home.tsx - saveReview()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  useEffect(() => {
    if (profile) checkUserTypeExist();
    if (workerAddress) checkWorkerAddressComplete();
    if (financialData) checkFinancialDataComplete();
    checkPendingReviews();
  }, [profile, workerAddress, financialData]);

  return (
    <Root>
      <Helmet>
        <title>{t('home')} - Hephaestus</title>
      </Helmet>
      <h2
        style={{
          textAlign: 'center',
          fontWeight: 400,
          color: COLORS.orange,
        }}>
        {t('welcome')} {profile?.username}
      </h2>
      <h3
        style={{
          textAlign: 'center',
          fontWeight: 400,
          color: COLORS.blue,
        }}>
        {t('active_offers')}
      </h3>
      <div className="grid-4-columns">
        {offers.length > 0 &&
          offers.map((offer) => {
            return (
              <OfferCard
                offer={offer}
                profile={profile}
                key={offer.id}
                refreshOffers={getOffers}
                setSelectedOffer={setSelectedOffer}
              />
            );
          })}
      </div>
      {offers.length === 0 && (
        <h2
          style={{
            textAlign: 'center',
            fontWeight: 500,
            color: COLORS.orange,
            paddingInline: 40,
          }}>
          {t('no_active_offers')}
          <br />
          💼
        </h2>
      )}
      {selectedOffer && (
        <ModalReviewRoot>
          <div>
            <span>
              {t('leave_review_to', {
                username:
                  selectedOffer.from_offer_data.id !== profile?.id
                    ? selectedOffer.from_offer_data.username
                    : selectedOffer.to_offer_data.username,
                job: selectedOffer.title,
              })}
            </span>
            <TopLabelTextArea
              label={t('review')}
              id="review-textarea"
              onChange={handleChangeReview}
              value={review}
            />
            <ReactStars
              count={5}
              onChange={handleChangeStars}
              size={24}
              activeColor={COLORS.orange}
            />
            <button type="button" onClick={saveReview}>
              {t('send')}
            </button>
          </div>
        </ModalReviewRoot>
      )}
    </Root>
  );
};

export default Home;
