import React, { FC, useContext } from 'react';
import { TitleUnderline } from '../Title/TitleUnderline';
import { t } from 'i18next';
import { PasswordInput } from '../Inputs/PasswordInput';
import { Separator } from '../Separators/Separator';
import { API } from '../../network/API';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { checkPasswordValid } from '../../utils/password';

export const PasswordResetSettings: FC = () => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [password, setPassword] = React.useState<string>('');
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const onSave = () => {
    showLoader();
    API.user
      .resetPassword(password)
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  const saveButtonDisabled =
    !Boolean(password.length) || !Boolean(checkPasswordValid(password));
  return (
    <div>
      <TitleUnderline title={t('password_reset')} />
      <PasswordInput
        label={t('new_password')}
        id="password-input"
        value={password}
        onChange={handlePasswordChange}
      />
      <Separator size={20} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          id="dropdown-financial-data-save-button"
          className="orange-button"
          style={{ width: 'unset', paddingInline: 40 }}
          onClick={onSave}
          disabled={saveButtonDisabled}>
          {t('save')}
        </button>
      </div>
    </div>
  );
};
