import React, { ChangeEvent, CSSProperties } from 'react';
import { TextSlugModel } from '../../models/TextSlugModel';
import { COLORS } from '../../utils/colors';
import { isPC } from '../../utils/screenWidth';

interface TopLabelSelectProps {
  list: TextSlugModel[];
  label: string;
  id: string;
  onChangeSelecton: (event: ChangeEvent<HTMLSelectElement>) => void;
  selected?: TextSlugModel;
}

export const TopLabelSelect: React.FC<TopLabelSelectProps> = ({ list, label, id, onChangeSelecton, selected }) => {
  const divStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBlock: 8,
  };
  const labelStyle: CSSProperties = {
    color: COLORS.blue,
    marginBottom: 5,
  };
  const selectStyle: CSSProperties = {
    border: 0,
    background: COLORS.white,
    outline: 'none',
    cursor: 'pointer',
    color: COLORS.blue,
    width: '100%',
  };
  const selectContainerStyle: CSSProperties = {
    width: '100%',
    border: isPC() ? 0 : `1.7px solid ${COLORS.orange}`,
    boxShadow: `1px 2px 5px ${COLORS.gray}`,
    borderRadius: 10,
    paddingBlock: 7,
  };
  return (
    <div style={divStyle}>
      <label htmlFor={id} style={labelStyle}>
        {label}:
      </label>
      <div style={selectContainerStyle}>
        <select id={id} style={selectStyle} onChange={onChangeSelecton} value={selected?.slug}>
          {selected &&
            list.map((item) => {
              return (
                <option key={item.slug} value={item.slug}>
                  {item.text}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};
