import { FC } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import { COLORS } from '../../utils/colors';
import { TrashIcon } from '../../assets/icons/TrashIcon';
import { API } from '../../network/API';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';
import { logger } from '../../utils/logger';
import { Job } from '../../models/JobModel';
import { Router } from '../../navigation/Router';
import { Profile } from '../../models/Profile';

const Root = styled.div`
  flex: 1;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-bottom: 5px;
`;

const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-block: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DeleteButton = styled.button`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ProfileServiceCardProps {
  job: Job;
  onReload?: () => void;
  profile?: Profile;
}

export const ProfileJobsCard: FC<ProfileServiceCardProps> = ({
  job,
  onReload,
  profile,
}) => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerMessage,
    bannerTitle,
  } = bannerControls();

  const onDelete = () => {
    if (job.id) {
      openLoader();
      API.user
        .deleteJob(job.id)
        .then(() => {
          showBanner(t('success'), t('service_deleted')); // TODO
          if (onReload) onReload();
        })
        .catch((error) => {
          logger('ProfileJobsCard - onDelete()', error, 'error');
          showBanner(t('error'), error.message);
        })
        .finally(() => closeLoader());
    }
  };

  const handleNewChat = () => {
    if (!profile) return;
    openLoader();
    API.user
      .sendMessage(profile?.id, job.title, t('job_proposal_message'))
      .then((response) => {
        Router.goToChats(response.chat_id);
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  return (
    <Root>
      {isLoaderVisible && <Loader />}
      {isBannerVisible && (
        <Banner
          title={bannerTitle}
          message={bannerMessage}
          onClose={closeBanner}
        />
      )}
      <Header>
        <span style={{ fontWeight: 500 }}>{job.title}</span>
        <span style={{ fontWeight: 600 }}>
          {job.price_per_hour}€ {t('price_per_hour')}
        </span>
        <div style={{ flex: 1 }} />
        {onReload && (
          <DeleteButton type="button" onClick={onDelete}>
            <TrashIcon width={20} height={20} color={COLORS.blue} />
          </DeleteButton>
        )}
      </Header>
      <span>{job.description}</span>
      <div style={{ padding: 10 }}>
        {!onReload && (
          <button
            type="button"
            className="orange-button"
            onClick={handleNewChat}>
            {t('contact')}
          </button>
        )}
      </div>
    </Root>
  );
};
