import React from 'react';
import { PopUpMenu } from './PopUpMenu';
import { COLORS } from '../../utils/colors';
import styled from 'styled-components';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';

const NotificationsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 280px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NotificationButton = styled.button`
  border-bottom: 1px solid ${COLORS.gray_50};
  font-size: 12px;
  margin-inline: -5px;
`;

interface NotificationsNavbarMenuProps {
  notifications: string[];
}

export const NotificationsNavbarMenu: React.FC<
  NotificationsNavbarMenuProps
> = ({ notifications }) => {
  return (
    <PopUpMenu innerBoxPosition="45%" menuPosition={92} menuHeight={50}>
      <span
        style={{ color: COLORS.blue, fontSize: 14 }}
        id="notifications-navbar-menu-header">
        {t('notifications')}
      </span>
      <Separator size={10} />
      <NotificationsDiv>
        {notifications.map((item, index) => {
          return <NotificationButton key={index}>{item}</NotificationButton>;
        })}
      </NotificationsDiv>
    </PopUpMenu>
  );
};
