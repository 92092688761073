import React, { CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';
import { ChevronDownIcon } from '../../assets/icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../assets/icons/ChevronUpIcon';

interface DropdownHeaderNumberProps {
  number: number;
  title: string;
  onClick: () => void;
  isOpen: boolean;
  isComplete: boolean;
}

export const DropdownHeaderNumber: React.FC<DropdownHeaderNumberProps> = ({ number, title, onClick, isOpen, isComplete }) => {
  const styles = {
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      borderBottom: `1px solid ${COLORS.gray}`,
    } as CSSProperties,
    title: {
      fontWeight: 'normal',
      color: isOpen || isComplete ? COLORS.blue : COLORS.gray,
    } as CSSProperties,
    number: {
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: isComplete ? COLORS.blue : COLORS.white,
      color: isComplete ? COLORS.white : isOpen ? COLORS.blue : COLORS.gray,
      border: `2px solid ${isComplete ? COLORS.orange : isOpen ? COLORS.blue : COLORS.gray}`,
      borderRadius: 100,
    } as CSSProperties,
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    } as CSSProperties,
  };
  return (
    <div style={styles.root}>
      <span style={styles.number}>{number}</span>
      <h3 style={styles.title}>{title}</h3>
      <div style={{ flex: 1 }} />
      <button type="button" onClick={onClick} style={styles.button} id={`dropdown-header-button-${number}`}>
        {isOpen ? <ChevronUpIcon width={20} height={20} color={COLORS.blue} /> : <ChevronDownIcon width={20} height={20} color={COLORS.blue} />}
      </button>
    </div>
  );
};
