import React, { CSSProperties, useContext } from 'react';
import { GoogleButton } from './GoogleButton';
import { useGoogleLogin } from '@react-oauth/google';
import { logger } from '../../utils/logger';
import { getGoogleUser } from '../../utils/google';
import { API } from '../../network/API';
import { t } from 'i18next';
import { saveClientAPIToken } from '../../network/ClientAPIKey';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { Router } from '../../navigation/Router';

export const SocialButtons: React.FC = () => {
  const styles = {
    socialRoot: {
      display: 'flex',
      flexDirection: 'row',
      gap: 50,
      justifyContent: 'center',
      width: '100%',
    } as CSSProperties,
  };
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  /* istanbul ignore next */
  const handleGoogle = useGoogleLogin({
    async onSuccess(credentialResponse) {
      showLoader();
      logger(
        'handleGoogle() - credentialResponse',
        credentialResponse.access_token,
        'success',
      );

      getGoogleUser(credentialResponse.access_token)
        .then((googleProfile) => {
          API.general
            .thirdPartyAuth(
              googleProfile.name,
              googleProfile.email,
              googleProfile.id,
            )
            .then((authResponse) => {
              if (authResponse.token) {
                saveClientAPIToken(authResponse.token);
              }
              closeLoader();
              Router.goToHome();
            })
            .catch((error) => {
              closeLoader();
              logger('handleGoogle() - getGoogleUser', error, 'error');
              showBanner(t('error'), error.message);
            });
        })
        .catch((error) => {
          logger('handleGoogle()', 'Google Login Error', 'error');
          closeLoader();
          showBanner(t('error'), error);
        });
    },
    onError() {
      logger('handleGoogle()', 'Google Login Error', 'error');
    },
  });

  // const handleFacebook = () => undefined;
  return (
    <div style={styles.socialRoot}>
      <GoogleButton onClick={() => handleGoogle()} />
      {/*<FacebookButton onClick={handleFacebook} />*/}
    </div>
  );
};
