import React from 'react';
import { useProfile } from '../../hooks/useProfile';
import styled from 'styled-components';
import { Navbar } from '../Navbar/Navbar';
import { ProfileContext } from '../../context/ProfileContext';
import { NavbarTypes } from '../Navbar/NavbarTypes';
import { UserOnlyNavbar } from '../Navbar/UserOnlyNavbar';

const Root = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

interface ProfileWrapperProps {
  children: React.ReactNode;
  navbarType?: NavbarTypes;
}

export const ProfileWrapper: React.FC<ProfileWrapperProps> = ({
  children,
  navbarType = 'full',
}) => {
  const { profile, reloadProfile } = useProfile();

  return (
    <ProfileContext.Provider value={{ profile: profile, reloadProfile: reloadProfile }}>
      <Root>
        {navbarType === 'full' ? (
          <Navbar profile={profile} />
        ) : (
          <UserOnlyNavbar profile={profile} />
        )}
        {children}
      </Root>
    </ProfileContext.Provider>
  );
};
