import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Separator } from '../Separators/Separator';
import loaderControls from '../Loader/loaderControls';
import { Loader } from '../Loader/Loader';
import bannerControls from '../Banner/bannerControls';
import { Banner } from '../Banner/Banner';
import { t } from 'i18next';
import { getWorkers } from '../../utils/APIUtils';
import { COLORS } from '../../utils/colors';
import { FilterIcon } from '../../assets/icons/FilterIcon';
import { Profile } from '../../models/Profile';
import { ProfessionalsList } from '../Lists/ProfessionalsList';
import { WorkersFilter } from '../Forms/WorkersFilter';

const RootDiv = styled.div`
  padding-inline: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: 30px;

  @media screen and (max-width: 800px) {
    padding-inline: 15px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  gap: 40px;
  overflow-y: hidden;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ServicesFilterDiv = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const CheckBtn = styled.label`
  cursor: pointer;
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -35px;
  }
`;

const CheckInp = styled.input`
  display: none;

  @media screen and (max-width: 800px) {
    &:checked ~ ${ServicesFilterDiv} {
      display: flex;
    }
  }
`;

export const ProfessionalsTab: React.FC = () => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [showProfiles, setShownProfiles] = useState<Profile[]>([]);

  /* istanbul ignore next */
  const onProfilesSuccess = (data: { profiles?: Profile[] }) => {
    if (data.profiles) {
      setProfiles(data.profiles);
      setShownProfiles(data.profiles);
    }
    closeLoader();
  };

  /* istanbul ignore next */
  const onProfilesError = (error: string) => {
    closeLoader();
    showBanner(t('error'), error);
  };

  /* istanbul ignore next */
  const handleFilter = (
    maxPrice?: number,
    city?: string,
    searchString?: string,
  ) => {
    if (maxPrice && city) {
      setShownProfiles(
        profiles
          .filter(
            (item) =>
              item.main_price_per_hour <= maxPrice &&
              `${item.address?.city}, ${item.address?.country}`
                .toLowerCase()
                .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.main_job
                ?.toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.profile_description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (maxPrice && !city) {
      setShownProfiles(
        profiles
          .filter((item) => item.main_price_per_hour <= maxPrice)
          .filter(
            (item) =>
              item.main_job
                ?.toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.profile_description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (!maxPrice && city) {
      setShownProfiles(
        profiles
          .filter((item) =>
            `${item.address?.city}, ${item.address?.country}`
              .toLowerCase()
              .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.main_job
                ?.toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.profile_description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else {
      setShownProfiles(
        profiles.filter(
          (item) =>
            item.main_job
              ?.toLowerCase()
              .includes(searchString?.toLowerCase() ?? '') ||
            item.profile_description
              .toLowerCase()
              .includes(searchString?.toLowerCase() ?? ''),
        ),
      );
    }
  };

  /* istanbul ignore next */
  const handleResetFilters = () =>
    getWorkers(onProfilesSuccess, onProfilesError);

  useEffect(() => {
    openLoader();
    getWorkers(onProfilesSuccess, onProfilesError);
  }, []);
  return (
    <RootDiv id="services-tab-root">
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <Separator size={30} />
      <BodyDiv>
        <CheckInp type="checkbox" id="check" />
        <CheckBtn htmlFor="check">
          <FilterIcon width={20} height={20} color={COLORS.blue} />
        </CheckBtn>
        <ServicesFilterDiv>
          <WorkersFilter
            profiles={showProfiles}
            handleFilter={handleFilter}
            handleResetFilters={handleResetFilters}
          />
        </ServicesFilterDiv>
        <ProfessionalsList profiles={showProfiles} />
      </BodyDiv>
    </RootDiv>
  );
};
