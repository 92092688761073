import { useState } from 'react';

const bannerControls = () => {
  const [isBannerVisible, setBannerVisible] = useState<boolean>(false);
  const [bannerTitle, setBannerTitle] = useState<string>('');
  const [bannerMessage, setBannerMessage] = useState<string>('');

  const showBanner = (title: string, message: string) => {
    setBannerTitle(title);
    setBannerMessage(message);
    setBannerVisible(true);
  };

  const closeBanner = () => setBannerVisible(false);
  return { isBannerVisible, bannerTitle, bannerMessage, showBanner, closeBanner };
};

export default bannerControls;
