import React, { FC } from 'react';
import { SenderMessage } from './SenderMessage';
import { ReceiverMessage } from './ReceiverMessage';
import { Message } from '../../models/ChatModels';
import { Profile } from '../../models/Profile';

interface MessageProps {
  message: Message;
  profile?: Profile;
}

export const MessageView: FC<MessageProps> = ({ message, profile }) => {
  if (message.sender_data.id === profile?.id) {
    return <SenderMessage item={message} />;
  }
  return <ReceiverMessage item={message} />;
};
