import { TFunction } from 'i18next';
import { TextSlugModel } from '../models/TextSlugModel';
import { API } from '../network/API';
import { checkEmailValid } from './email';
import { checkPasswordValid } from './password';
import UserTypesConst from '../constants/UserTypesConst';
import { logger } from './logger';

export const checkRegsterInputs = (t: TFunction<['translation', ...string[]], undefined>, name: string, email: string, password: string, privacyChecked: boolean, userType?: TextSlugModel) => {
  if (!Boolean(name.length)) {
    return t('write_a_valid_name');
  }
  if (!checkEmailValid(email)) {
    return t('write_a_valid_email');
  }
  if (!checkPasswordValid(password)) {
    return t('write_a_valid_password');
  }
  if (!privacyChecked) {
    return t('must_accept_privacy');
  }
  if (!userType) {
    return t('choose_a_valid_user_type');
  }
  return;
};

export const registerUser = async (name: string, email: string, password: string, userType?: TextSlugModel) => {
  try {
    return await API.general.registerUser(name, email, password, userType?.slug);
  } catch (error: any) {
    logger('Register.ts - registerUser()', error, 'error');
    throw error.message;
  }
};

interface ResetRegisterInputsProps {
  t: TFunction<['translation', ...string[]], undefined>;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setUserType: (value: TextSlugModel) => void;
  setPrivacyChecked: (value: boolean) => void;
}

export const resetRegisterInputs = ({ t, setName, setEmail, setPassword, setUserType, setPrivacyChecked }: ResetRegisterInputsProps) => {
  setName('');
  setEmail('');
  setPassword('');
  setUserType(UserTypesConst(t)[0]);
  setPrivacyChecked(false);
};

interface HandleRegisterProps {
  t: TFunction<['translation', ...string[]], undefined>;
  showBanner: (title: string, message: string) => void;
  openLoader: () => void;
  closeLoader: () => void;
  name: string;
  email: string;
  password: string;
  privacyChecked: boolean;
  userType?: TextSlugModel;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setUserType: (value: TextSlugModel) => void;
  setPrivacyChecked: (value: boolean) => void;
}

export const handleRegister = async ({ t, showBanner, openLoader, closeLoader, name, email, password, privacyChecked, userType, setName, setEmail, setPassword, setUserType, setPrivacyChecked }: HandleRegisterProps) => {
  const allValid = checkRegsterInputs(t, name, email, password, privacyChecked, userType);
  if (allValid) {
    showBanner(t('error'), allValid);
    return;
  }

  openLoader();
  try {
    await registerUser(name, email, password, userType);
    resetRegisterInputs({ t, setName, setEmail, setPassword, setUserType, setPrivacyChecked });
    logger('Register.ts - handleRegister()', '', 'success');
    showBanner(t('register_success'), t('register_success_message'));
  } catch (error: any) {
    showBanner(t('error'), error);
    logger('Register.ts - handleRegister()', error, 'error');
  }
  closeLoader();
};
