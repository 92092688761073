import React, { ChangeEvent } from 'react';
import { LoginRegisterStyles } from '../../assets/css/LoginRegisterStyles';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';
import { isPC } from '../../utils/screenWidth';
import { Separator } from '../Separators/Separator';
import { IMAGES } from '../../utils/images';
import { t } from 'i18next';
import { PasswordInput } from '../Inputs/PasswordInput';
import { useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../utils/miscUtils';
import { checkPasswordValid } from '../../utils/password';
import { clearClientAPIToken } from '../../network/ClientAPIKey';
import { Router } from '../../navigation/Router';

export const PasswordResetForm: React.FC = () => {
  const styles = LoginRegisterStyles;

  const [searchParams] = useSearchParams();

  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();

  const [password, setPassword] = React.useState<string>('');
  /* istanbul ignore next */
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  /* istanbul ignore next */
  const saveButtonDisabled =
    !Boolean(password.length) || !Boolean(checkPasswordValid(password));

  /* istanbul ignore next */
  const onSuccess = () => {
    clearClientAPIToken();
    Router.goToLogin();
  };
  /* istanbul ignore next */
  const handleSavePress = () =>
    resetPassword(
      { t, openLoader, closeLoader, showBanner },
      password,
      onSuccess,
      searchParams.get('data'),
    );
  return (
    <>
      {/* istanbul ignore next */ isLoaderVisible && <Loader />}
      {
        /* istanbul ignore next */ isBannerVisible && (
          <Banner
            title={bannerTitle}
            message={bannerMessage}
            onClose={closeBanner}
          />
        )
      }
      <div
        className={`flex-column flex-1 width-80 ${isPC() ? 'justify-center' : ''}`}>
        <Separator size={30} />
        {isPC() && <img src={IMAGES.logo_full_trans} style={styles.img} />}
        <h1 style={styles.h1}>{t('password_reset')}</h1>
        <Separator size={20} />
        <div style={styles.div}>
          <PasswordInput
            label={t('new_password')}
            id="new-password-input"
            value={password}
            onChange={handlePasswordChange}
          />
          <Separator size={10} />
          <span>{t('write_a_valid_password')}</span>
          <Separator size={30} />
          <button
            type="button"
            className="orange-button"
            onClick={handleSavePress}
            disabled={saveButtonDisabled}>
            {t('save')}
          </button>
        </div>
        <Separator size={30} />
      </div>
    </>
  );
};
