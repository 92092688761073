type LogTypes = 'debug' | 'warning' | 'error' | 'success';

const LogType = {
  debug: '\x1b[36m',
  warning: '\x1b[33m',
  error: '\x1b[31m',
  success: '\x1b[32m',
};

export const logger = (tag: string, message: any, type: LogTypes = 'debug') => {
  if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
    console.log(`${LogType[type]}${new Date().toISOString()} - ${tag}:`, message);
  }
};
