import { FC } from 'react';
import styled from 'styled-components';
import { Job } from '../../models/JobModel';
import { JobsCard } from '../Cards/JobsCard';

const JobsRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface JobsListProps {
  jobs: Job[];
}

export const JobsList: FC<JobsListProps> = ({ jobs }) => {
  return (
    <JobsRoot>
      {jobs.map((job) => {
        /* istanbul ignore next */
        return <JobsCard key={job.id} job={job} />;
      })}
    </JobsRoot>
  );
};
