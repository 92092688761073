import { Chat } from '../models/ChatModels';
import { Profile } from '../models/Profile';

export const checkUnseenMessages = (chats: Chat[], profile?: Profile) => {
  let unseen = false;
  chats.forEach((chat) => {
    const isUnseen = Boolean(
      chat.unseen_messages.filter(
        (message) => message.user_id === profile?.id && message.unseen,
      ).length,
    );
    if (isUnseen) unseen = true;
  });
  return unseen;
};
