import React, { CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';
import { GoogleIcon } from '../../assets/icons/GoogleIcon';

interface GoogleButtonProps {
  onClick: () => void;
}

export const GoogleButton: React.FC<GoogleButtonProps> = ({ onClick }) => {
  const styles = {
    socialIconContainer: {
      padding: 8,
      display: 'flex',
      background: COLORS.white,
      boxShadow: `1px 2px 5px ${COLORS.gray}`,
      border: 0,
      borderRadius: 8,
    } as CSSProperties,
  };
  return (
    <button type="button" style={styles.socialIconContainer} onClick={onClick} id="google-button">
      <GoogleIcon width={50} height={50} color={COLORS.black} />
    </button>
  );
};
