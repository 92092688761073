import React, { FC, useContext } from 'react';
import { t } from 'i18next';
import { Message } from '../../models/ChatModels';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { LoaderContext } from '../../context/LoaderContext';
import { BannerContext } from '../../context/BannerContext';
import { API } from '../../network/API';
import { logger } from '../../utils/logger';

const OfferMesageDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Description = styled.p`
  font-size: 0.9rem;
  margin: 0;
  white-space: pre-wrap;
`;

const RefuseButton = styled.button`
  background: ${COLORS.red};
  border-radius: 10px;
  border: none;
  flex: 1;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-block: 5px;
`;

const AcceptButton = styled.button`
  background: ${COLORS.green};
  border-radius: 10px;
  border: none;
  flex: 1;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-block: 5px;
`;

const DateSpan = styled.span`
  font-size: 0.7rem;
`;

const PriceSpan = styled.span`
  background: ${COLORS.orange};
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  align-self: flex-end;
  font-weight: bold;
  color: ${COLORS.white};
`;

const TitleSpan = styled.span`
  font-size: 1rem;
  font-weight: 600;
  flex: 1;
`;

const OfferState = styled.span`
  font-size: 1rem;
  font-weight: 600;
  flex: 1;
  text-transform: uppercase;
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  color: ${COLORS.white};
`;

interface OfferMesssageProps {
  item: Message;
  showSenderControls?: boolean;
  showReceiverControls?: boolean;
}

export const OfferMesssage: FC<OfferMesssageProps> = ({
  item,
  showSenderControls = false,
  showReceiverControls = false,
}) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const handleDelete = () => {
    showLoader();
    API.user
      .deleteOffer(item.offer_data?.id)
      .catch((error) => {
        logger('OfferMesssage.tsx - handleDelete()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleAccept = () => {
    showLoader();
    API.user
      .changeOfferState('inProgress', item.offer_data?.id)
      .catch((error) => {
        logger('OfferMesssage.tsx - handleAccept()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  const handleRefuse = () => {
    showLoader();
    API.user
      .changeOfferState('refused', item.offer_data?.id)
      .catch((error) => {
        logger('OfferMesssage.tsx - handleAccept()', error.message, 'error');
        showBanner(t('error'), error.message);
      })
      .finally(() => closeLoader());
  };

  return (
    <OfferMesageDiv>
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <TitleSpan>{item.offer_data?.title}</TitleSpan>
        <PriceSpan>{item.offer_data?.price} €/h</PriceSpan>
      </div>
      <Description>{item.offer_data?.description}</Description>
      {showReceiverControls && (
        <div style={{ display: 'flex', gap: 10 }}>
          <RefuseButton type="button" onClick={handleRefuse}>
            {t('refuse')}
          </RefuseButton>
          <AcceptButton type="button" onClick={handleAccept}>
            {t('accept')}
          </AcceptButton>
        </div>
      )}
      {showSenderControls && (
        <div style={{ display: 'flex', gap: 10 }}>
          <RefuseButton type="button" onClick={handleDelete}>
            {t('delete')}
          </RefuseButton>
        </div>
      )}
      {!showSenderControls && !showReceiverControls && (
        <OfferState
          style={{
            background:
              item.offer_data?.state === 'inProgress'
                ? COLORS.orange
                : COLORS.red,
          }}>
          {item.offer_data?.state}
        </OfferState>
      )}
      <DateSpan>{new Date(item.get_date).toLocaleString()}</DateSpan>
    </OfferMesageDiv>
  );
};
