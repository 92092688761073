import React from 'react';
import IconProps from './IconProps';

export const FullStarIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 23.1562L6.47656 28.625L8.86914 18.3711L0.939453 11.4668L11.3984 10.5781L15.5 0.939453L19.6016 10.5781L30.0605 11.4668L22.1309 18.3711L24.5234 28.625L15.5 23.1562Z"
        fill={color}
      />
    </svg>
  );
};
