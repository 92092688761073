import React, { CSSProperties } from 'react';
import { IMAGES } from '../../utils/images';
import { isPC } from '../../utils/screenWidth';
import { changeBGImg } from '../../utils/fullScreenBackgroundImgSlider';

export const FullScreenBackgroundImgSlider: React.FC = () => {
  const logoStyle: CSSProperties = {
    position: 'fixed',
    top: 20,
    width: '50%',
    alignSelf: 'center',
  };
  const [imgIndex, setImgIndex] = React.useState<number>(0);
  const images = [IMAGES.grdner, IMAGES.pastry, IMAGES.plumber, IMAGES.cleaner];
  React.useEffect(() => changeBGImg(images, imgIndex, setImgIndex), [imgIndex]);
  return (
    <div className="full-screen">
      <img data-testid="register-bg-img" id="register-bg-img" style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={images[imgIndex]} loading="lazy" alt={IMAGES.grdner} title={IMAGES.grdner} />
      {!isPC() && <img data-testid="register-bg-logo-img" id="register-bg-logo-img" src={IMAGES.logo_full_trans_white} style={logoStyle} />}
    </div>
  );
};
