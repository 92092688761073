import React from 'react';
import IconProps from './IconProps';

export const AccountCirlce: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 27.4688C13.3542 28.5104 15.2292 29.0312 17 29.0312C18.7708 29.0312 20.6198 28.5104 22.5469 27.4688C24.526 26.4271 26.0104 25.151 27 23.6406C26.9479 22.1823 25.776 20.9583 23.4844 19.9688C21.1927 18.9792 19.0312 18.4844 17 18.4844C14.9688 18.4844 12.8073 18.9792 10.5156 19.9688C8.22396 20.9062 7.05208 22.1302 7 23.6406C7.98958 25.151 9.44792 26.4271 11.375 27.4688ZM20.5156 6.84375C19.526 5.85417 18.3542 5.35938 17 5.35938C15.6458 5.35938 14.474 5.85417 13.4844 6.84375C12.4948 7.83333 12 9.00521 12 10.3594C12 11.7135 12.4948 12.8854 13.4844 13.875C14.474 14.8646 15.6458 15.3594 17 15.3594C18.3542 15.3594 19.526 14.8646 20.5156 13.875C21.5052 12.8854 22 11.7135 22 10.3594C22 9.00521 21.5052 7.83333 20.5156 6.84375ZM5.20312 5.28125C8.48438 2 12.4167 0.359375 17 0.359375C21.5833 0.359375 25.4896 2 28.7188 5.28125C32 8.51042 33.6406 12.4167 33.6406 17C33.6406 21.5833 32 25.5156 28.7188 28.7969C25.4896 32.026 21.5833 33.6406 17 33.6406C12.4167 33.6406 8.48438 32.026 5.20312 28.7969C1.97396 25.5156 0.359375 21.5833 0.359375 17C0.359375 12.4167 1.97396 8.51042 5.20312 5.28125Z" fill={color} />
    </svg>
  );
};
