import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { ChatsSidebar } from '../components/Chat/ChatsSidebar';
import { Separator } from '../components/Separators/Separator';
import { COLORS } from '../utils/colors';
import { useParams } from 'react-router-dom';
import { ChatView } from '../components/Chat/ChatView';
import { HamburguerMenuIcon } from '../assets/icons/HamburguerMenuIcon';
import { ProfileContext } from '../context/ProfileContext';

const Root = styled.div`
  padding-inline: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: 30px;

  @media screen and (max-width: 700px) {
    padding-inline: 15px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  overflow-y: hidden;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 ${COLORS.gray_50};

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const CheckBtn = styled.label`
  cursor: pointer;
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: -20px;
  }
`;

const ChatsDiv = styled.div`
  flex: 0.2;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const CheckInp = styled.input`
  display: none;

  @media screen and (max-width: 800px) {
    &:checked ~ ${ChatsDiv} {
      display: flex;
    }
  }
`;

const Chats: FC = () => {
  const { id } = useParams();
  const { profile } = useContext(ProfileContext);
  const [selectedChat, setSelectedChat] = useState<string | undefined>(id);

  return (
    <>
      <Helmet>
        <title>{t('messages')} - Hephaestus</title>
      </Helmet>
      <Root>
        <Separator size={30} />
        <BodyDiv>
          <CheckInp type="checkbox" id="check" />
          <CheckBtn htmlFor="check">
            <HamburguerMenuIcon width={20} height={20} color={COLORS.blue} />
          </CheckBtn>
          <ChatsDiv>
            <ChatsSidebar selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
          </ChatsDiv>
          {Number(selectedChat) !== 0 && (
            <ChatView selectedChat={Number(selectedChat)} profile={profile} />
          )}
        </BodyDiv>
      </Root>
    </>
  );
};

export default Chats;
