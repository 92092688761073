import { FinancialData } from '../models/FinancialData';
import { Job } from '../models/JobModel';
import { LoginResponse } from '../models/LoginResponse';
import { Profile } from '../models/Profile';
import { Service } from '../models/Services';
import { WorkerAddress } from '../models/WorkerAddress';
import { del, get, post, put } from './Network';
import { Chat, Message, NewMessageRepsonse } from '../models/ChatModels';
import { UserAddress } from '../models/UserAddress';
import { ExtendedOffer } from '../models/OfferModel';
import { ReviewModel } from '../models/Review';

export const API = {
  general: {
    registerUser: (
      name: string,
      email: string,
      password: string,
      role?: string,
    ) =>
      post<any>('/profile/', false, {
        username: name,
        email: email,
        password: password,
        role: role,
      }),
    loginUser: (email: string, password: string) =>
      put<LoginResponse>('/login/', false, {
        email: email,
        password: password,
      }),
    thirdPartyAuth: (name: string, email: string, password: string) =>
      post<LoginResponse>('/third-party-auth/', false, {
        username: name,
        email: email,
        password: password,
      }),
    passwordRecovery: (email: string) =>
      put<any>('/password-recovery/', false, { email: email }),
  },
  user: {
    verifyAccount: () =>
      put<any>('/profile/', true, { email_verified: true }),
    getProfile: () => get<Profile>('/profile/', true),
    editRole: (role?: string) => put<any>('/profile/', true, { role: role }),
    getWorkerAddress: () => get<WorkerAddress>('/worker-address/', true),
    getUserAddress: () => get<UserAddress>('/user-address/', true),
    createWorkerAddress: (workerAddress: WorkerAddress) =>
      post<WorkerAddress>('/worker-address/', true, {
        address: workerAddress.address,
        city: workerAddress.city,
        country: workerAddress.country,
        province_or_state: workerAddress.province_or_state,
      }),
    createUserAddress: (userAddress: UserAddress) =>
      post<UserAddress>('/user-address/', true, {
        address: userAddress.address,
        city: userAddress.city,
        country: userAddress.country,
        province_or_state: userAddress.province_or_state,
      }),
    editBirthdate: (birthdate: string) =>
      put<any>('/profile/', true, { birthdate: birthdate }),
    getFinancialData: () => get<FinancialData>('/financial-data/', true),
    createFinancialData: (financialData: FinancialData) =>
      post<FinancialData>('/financial-data/', true, {
        vat_number: financialData.vat_number,
      }),
    getWorkerServices: () => get<Service[]>('/service/', true),
    createNewService: (workerService: Service) =>
      post<Service[]>('/service/', true, {
        service: workerService.service,
        city: workerService.city,
        country: workerService.country,
        price_per_hour: workerService.price_per_hour,
        description: workerService.description,
      }),
    resetPassword: (password: string) =>
      put<any>('/password-reset/', true, { password: password }),
    getServices: () => get<Service[]>('/services/', true),
    createJob: (
      title: string,
      description: string,
      city: string,
      country: string,
      price_per_hour: number,
    ) =>
      post<Job>('/job/', true, {
        title,
        description,
        city,
        country,
        price_per_hour,
      }),
    getWorkers: () => get<Profile[]>('/profiles/', true),
    getAllJobs: () => get<Job[]>('/jobs/', true),
    deleteService: (id: number) => del<any>(`/service/${id}`, true),
    getUserJobs: () => get<Job[]>('/jobs-user/', true),
    deleteJob: (id: number) => del<any>(`/job/${id}`, true),
    getProfileById: (id?: string) => get<Profile>(`/profile/${id}`, true),
    getUserJobsByUserId: (id: number) => get<Job[]>(`/jobs/${id}`, true),
    getUserServicesByUserId: (id: number) =>
      get<Service[]>(`/services/${id}`, true),
    getUserChats: () => get<Chat[]>('/chats/', true),
    getChatMessages: (chatId: number) =>
      get<Message[]>(`/message/${chatId}`, true),
    sendMessage: (receiver: number, topic: string, message: string) =>
      post<NewMessageRepsonse>('/message/', true, { receiver, topic, message }),
    editProfile: (
      birthdate?: string,
      description?: string,
      mainJob?: string,
      pricePerHour?: number,
    ) =>
      put<any>('/profile/', true, {
        birthdate: birthdate,
        profile_description: description,
        main_job: mainJob,
        main_price_per_hour: pricePerHour,
      }),
    deleteUser: () => del<any>('/profile/', true),
    sendNewOffer: (
      title: string,
      description: string,
      price: number,
      toOffer: number,
    ) =>
      post<any>('/offer/', true, {
        title: title,
        description: description,
        price: price,
        to_offer: toOffer,
      }),
    deleteOffer: (id?: number) => del<any>(`/offer/${id}`, true),
    changeOfferState: (state: string, id?: number) =>
      put<any>(`/offer/${id}`, true, { state: state }),
    getUserOffers: () => get<ExtendedOffer[]>('/offers/', true),
    saveReview: (
      review: string,
      stars: number,
      reviewed?: number,
      relatedOffer?: number,
    ) =>
      post<any>('/review/', true, {
        reviewed: reviewed,
        review: review,
        stars: stars,
        related_offer: relatedOffer,
      }),
    pendingReviews: () => get<ExtendedOffer[]>('/pending-reviews/', true),
    getReviews: () => get<ReviewModel[]>('/review/', true),
  },
};
