import React, { ChangeEvent, CSSProperties } from 'react';
import { COLORS } from '../../utils/colors';

interface TopLabelTextAreaProps {
  label: string;
  id: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
}

export const TopLabelTextArea: React.FC<TopLabelTextAreaProps> = ({ id, label, value, onChange, disabled = false }) => {
  const rootStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBlock: 8,
  };
  const inputStyle: CSSProperties = {
    border: `2px solid ${COLORS.orange}`,
    borderRadius: 8,
    padding: 6,
    boxShadow: `1px 2px 5px ${COLORS.gray}`,
    color: COLORS.blue,
    fontSize: 16,
    outline: 'none',
    opacity: disabled ? 0.3 : 1,
    cursor: disabled ? 'default' : 'text',
    maxHeight: 300,
    fontFamily: '"Poppins", sans-serif',
  };
  const labelStyle: CSSProperties = {
    color: COLORS.blue,
    marginBottom: 5,
  };
  return (
    <div style={rootStyle}>
      <label style={labelStyle} htmlFor={id}>
        {label}:
      </label>
      <textarea value={value} style={inputStyle} id={id} onChange={onChange}></textarea>
    </div>
  );
};
