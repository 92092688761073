import React, { useContext, useState } from 'react';
import { COLORS } from '../../utils/colors';
import { AccountCirlce } from '../../assets/icons/AccountCirlce';
import { Profile } from '../../models/Profile';
import styled from 'styled-components';
import { ProfileNavbarMenu } from './ProfileNavbarMenu';
import { MessagesIcon } from '../../assets/icons/MessagesIcon';
import { MessagesNavbarMenu } from './MessagesNavbarMenu';
import { NotificationsNavbarMenu } from './NotificationsNavbarMenu';
import { HamburguerMenuIcon } from '../../assets/icons/HamburguerMenuIcon';
import { MainNavbarMenu } from './MainNavbarMenu';
import { t } from 'i18next';
import { Router } from '../../navigation/Router';
import { ChatsContext } from '../../context/ChatsContext';
import { checkUnseenMessages } from '../../utils/messages';

type MenuTypes = 'profile' | 'messages' | 'notifications' | 'main';

const RootDiv = styled.div`
  background: ${COLORS.blue};
  display: flex;
  padding-inline: 20px;
  padding-block: 10px;
  position: sticky;
  top: 0;
  gap: 20px;
`;

const NavbarButton = styled.button`
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CreateNavbarButton = styled.button`
  border: 1px solid ${COLORS.orange};
  background: white;
  color: ${COLORS.orange};
  border-radius: 8px;
  box-shadow: 3px 3px 7px ${COLORS.gray_50};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray_50};
  }
`;

const MobileItemsDiv = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

interface NavbarProps {
  profile?: Profile;
}

export const Navbar: React.FC<NavbarProps> = ({ profile }) => {
  const { chats } = useContext(ChatsContext);

  const [mainMenuVisible, setMainMenuVisible] = useState<boolean>(false);
  const [profileMenuVisible, setProfileMenuVisible] = useState<boolean>(false);
  const [messagesMenuVisible, setMessagesMenuVisible] =
    useState<boolean>(false);
  const [notificationsMenuVisible, setNotificationsMenuVisible] =
    useState<boolean>(false);

  /* istanbul ignore next */
  const handleCreateAction = () => {
    if (profile && profile.role === 'worker') Router.goToNewService();
    if (profile && profile.role === 'user') Router.goToNewJob();
  };

  const closeAllMenus = () => {
    setProfileMenuVisible(false);
    setMessagesMenuVisible(false);
    setNotificationsMenuVisible(false);
    setMainMenuVisible(false);
  };

  const menusHandler = (menu: MenuTypes) => {
    switch (menu) {
      case 'main':
        setProfileMenuVisible(false);
        setMessagesMenuVisible(false);
        setNotificationsMenuVisible(false);
        setMainMenuVisible(!mainMenuVisible);
        break;
      case 'profile':
        setMainMenuVisible(false);
        setMessagesMenuVisible(false);
        setNotificationsMenuVisible(false);
        setProfileMenuVisible(!profileMenuVisible);
        break;
      case 'messages':
        setMainMenuVisible(false);
        setProfileMenuVisible(false);
        setNotificationsMenuVisible(false);
        setMessagesMenuVisible(!messagesMenuVisible);
        break;
      /* istanbul ignore next */
      case 'notifications':
        setMainMenuVisible(false);
        setProfileMenuVisible(false);
        setMessagesMenuVisible(false);
        setNotificationsMenuVisible(!notificationsMenuVisible);
        break;
    }
  };

  return (
    <RootDiv>
      <NavbarButton
        type="button"
        id="navbar-notifications-menu-handler-button"
        onClick={() => menusHandler('main')}>
        <HamburguerMenuIcon width={24} height={24} color={COLORS.white} />
      </NavbarButton>
      <div style={{ flex: 1 }} />
      <MobileItemsDiv>
        {
          /* istanbul ignore next */ profile && profile.role === 'worker' ? (
            <CreateNavbarButton
              type="button"
              id="navbar-create-button"
              onClick={handleCreateAction}>
              {t('create_service')}
            </CreateNavbarButton>
          ) : (
            <CreateNavbarButton
              type="button"
              id="navbar-create-button"
              onClick={handleCreateAction}>
              {t('create_job')}
            </CreateNavbarButton>
          )
        }
        {/*<NavbarButton
                      type="button"
                      id="navbar-notifications-menu-handler-button"
                      onClick={
                         () => menusHandler('notifications')
                      }>
                      <BellIcon width={24} height={24} color={COLORS.white} />
                    </NavbarButton>*/}
      </MobileItemsDiv>
      <NavbarButton
        type="button"
        id="navbar-messages-menu-handler-button"
        onClick={() => menusHandler('messages')}>
        <MessagesIcon
          width={24}
          height={24}
          color={
            checkUnseenMessages(chats, profile) ? COLORS.orange : COLORS.white
          }
        />
      </NavbarButton>
      <NavbarButton
        type="button"
        id="navbar-profile-menu-handler-button"
        onClick={() => menusHandler('profile')}>
        {profile?.username}
        <AccountCirlce width={24} height={24} color={COLORS.white} />
      </NavbarButton>

      {
        /* istanbul ignore next */ profileMenuVisible && (
          <ProfileNavbarMenu onClose={closeAllMenus} />
        )
      }
      {
        /* istanbul ignore next */ messagesMenuVisible && (
          <MessagesNavbarMenu
            profile={profile}
            chats={chats}
            handleClose={closeAllMenus}
          />
        )
      }
      {
        /* istanbul ignore next */ notificationsMenuVisible && (
          <NotificationsNavbarMenu notifications={[]} />
        )
      }
      {
        /* istanbul ignore next */ mainMenuVisible && (
          <MainNavbarMenu profile={profile} onClose={closeAllMenus} />
        )
      }
    </RootDiv>
  );
};
