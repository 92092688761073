import { createContext, FC, ReactNode } from 'react';
import bannerControls from '../components/Banner/bannerControls';
import { Banner } from '../components/Banner/Banner';

interface BannerContextProps {
  showBanner: (title: string, message: string) => void;
}

export const BannerContext = createContext<BannerContextProps>({
  showBanner: () => undefined,
});

interface BannerProviderProps {
  children: ReactNode;
}

export const BannerProvider: FC<BannerProviderProps> = ({ children }) => {
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerTitle,
    bannerMessage,
  } = bannerControls();
  return (
    <BannerContext.Provider value={{ showBanner: showBanner }}>
      {children}
      {isBannerVisible && (
        <Banner
          title={bannerTitle}
          message={bannerMessage}
          onClose={closeBanner}
        />
      )}
    </BannerContext.Provider>
  );
};
