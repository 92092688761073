import { t } from 'i18next';
import React, { ChangeEvent, CSSProperties, useState } from 'react';
import { PlusCirlceIcon } from '../../assets/icons/PlusCirlceIcon';
import { Service } from '../../models/Services';
import { COLORS } from '../../utils/colors';
import { TopLabelInput } from '../Inputs/TopLabelInput';
import { TopLabelTextArea } from '../Inputs/TopLabelTextArea';
import { Separator } from '../Separators/Separator';
import { DropdownHeaderNumber } from './DropdownHeaderNumber';

interface DropdownServicesDataProps {
  workerServices: Service[];
  createService: (workerService: Service) => void;
}

export const DropdownServicesData: React.FC<DropdownServicesDataProps> = ({
  workerServices,
  createService,
}) => {
  const styles = {
    addButton: {
      display: 'flex',
      gap: 10,
      justifyContent: 'center',
    } as CSSProperties,
  };

  const [isNewEntryOpen, setNewEntryOpen] = useState<boolean>(false);
  /* istanbul ignore next */
  const handleNewEntryOpen = () => setNewEntryOpen(!isNewEntryOpen);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  /* istanbul ignore next */
  const handleOpen = () => setIsOpen(!isOpen);

  const [serviceName, setServiceName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [pricePerHour, setPricePerHour] = useState<number>(0);
  const [serviceDescription, setServiceDescription] = useState<string>('');

  /* istanbul ignore next */
  const handleServiceNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setServiceName(event.target.value);

  /* istanbul ignore next */
  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCity(event.target.value);

  /* istanbul ignore next */
  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCountry(event.target.value);

  /* istanbul ignore next */
  const handlePricePerHourChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPricePerHour(Number.parseInt(event.target.value));

  /* istanbul ignore next */
  const handleServiceDescriptionChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => setServiceDescription(event.target.value);

  /* istanbul ignore next */
  const save = () => {
    const service: Service = {
      service: serviceName,
      city: city,
      country: country,
      price_per_hour: pricePerHour,
      description: serviceDescription,
    };
    createService(service);
    setServiceName('');
    setCity('');
    setCountry('');
    setPricePerHour(0);
    setServiceDescription('');
    setNewEntryOpen(false);
  };

  /* istanbul ignore next */
  const buttonDisabled =
    !Boolean(serviceName.length) ||
    !Boolean(serviceDescription.length) ||
    !Boolean(city.length) ||
    !Boolean(country.length) ||
    pricePerHour <= 0;

  return (
    <div style={{ width: '100%' }}>
      <DropdownHeaderNumber
        number={3}
        title={t('services_data')}
        isOpen={isOpen}
        onClick={handleOpen}
        isComplete={workerServices.length > 0}
      />
      {isOpen && (
        <div>
          <Separator size={20} />
          <button
            type="button"
            className="orange bold underline"
            id="dropdown-service-data-new-entry-open-button"
            style={styles.addButton}
            onClick={handleNewEntryOpen}>
            Añadir servicio
            <PlusCirlceIcon width={24} height={24} color={COLORS.orange} />
          </button>

          {isNewEntryOpen && (
            <div>
              <Separator size={10} />
              <div className="grid-4-columns">
                <TopLabelInput
                  label={t('service_name')}
                  id="service_name-input"
                  value={serviceName}
                  onChange={handleServiceNameChange}
                />
                <TopLabelInput
                  label={t('city')}
                  id="city-input"
                  value={city}
                  type="text"
                  onChange={handleCityChange}
                />
                <TopLabelInput
                  label={t('country')}
                  id="country-input"
                  value={country}
                  type="text"
                  onChange={handleCountryChange}
                />
                <TopLabelInput
                  label={t('price_per_hour')}
                  id="price_per_hour-input"
                  value={String(pricePerHour)}
                  type="number"
                  onChange={handlePricePerHourChange}
                />
              </div>
              <TopLabelTextArea
                label={t('service_description')}
                id="service_description-textarea"
                value={serviceDescription}
                onChange={handleServiceDescriptionChange}
              />
              <Separator size={20} />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  type="button"
                  className="orange-button"
                  style={{ width: 'unset', paddingInline: 40 }}
                  onClick={save}
                  disabled={buttonDisabled}>
                  {t('save')}
                </button>
              </div>
            </div>
          )}

          <ul>
            {workerServices.map((item, index) => {
              return (
                <li key={index} id={`worker-service-item-${index}`}>
                  {item.service}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
