import { Message } from '../models/ChatModels';
import { API } from '../network/API';
import { useContext, useEffect, useState } from 'react';
import { logger } from '../utils/logger';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { t } from 'i18next';

export const useChat = (chatId: number) => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [messages, setMessages] = useState<Message[]>([]);

  const getMessages = (loading: boolean) => {
    if (loading) showLoader();
    API.user
      .getChatMessages(chatId)
      .then((response) => {
        setMessages(response);
      })
      .catch((error) => {
        logger('useChat.ts - getMessages()', error.message, 'error');
      })
      .finally(() => closeLoader());
  };

  const sendMessage = (
    message: string,
    receiver: number,
    topic: string,
    onSuccess: () => void,
  ) => {
    if (message.length > 0) {
      API.user
        .sendMessage(receiver, topic, message)
        .then(() => onSuccess())
        .catch((error) => {
          logger('useChat.ts - sendMessage()', error.message, 'error');
          showBanner(t('error'), error.message);
        });
    }
  };

  useEffect(() => {
    getMessages(true);
    const interval = setInterval(() => getMessages(false), 2000);

    return () => clearInterval(interval);
  }, [chatId]);
  return {
    messages,
    sendMessage,
  };
};
