export interface GoogleProfile {
  id: string;
  name: string;
  email: string;
}

export const getGoogleUser = async (accessToken: string) => {
  try {
    const respone = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
    });
    return (await respone.json()) as GoogleProfile;
  } catch (error) {
    throw error;
  }
};
