import React from 'react';
import { FullScreenBackgroundImgSlider } from '../components/Slider/FullScreenBackgroundImgSlider';
import { isPC } from '../utils/screenWidth';
import { PasswordRecoveryForm } from '../components/Forms/PasswordRecoveryForm';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';

const ForgotPassword: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{t('password_recovery')} - Hephaestus</title>
      </Helmet>
      <FullScreenBackgroundImgSlider />
      <div className="flex-row--reverse-800 flex-1">
        <div className="double-border-component">
          <div style={{ height: isPC() ? undefined : '100vh' }}>
            <PasswordRecoveryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
