import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/colors';
import { Separator } from '../components/Separators/Separator';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { Loader } from '../components/Loader/Loader';
import { Banner } from '../components/Banner/Banner';
import loaderControls from '../components/Loader/loaderControls';
import bannerControls from '../components/Banner/bannerControls';
import { FilterIcon } from '../assets/icons/FilterIcon';
import { JobsFilter } from '../components/Forms/JobsFilter';
import { Job } from '../models/JobModel';
import { getJobs } from '../utils/APIUtils';
import { JobsList } from '../components/Lists/JobsList';

const RootDiv = styled.div`
  padding-inline: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: 30px;

  @media screen and (max-width: 700px) {
    padding-inline: 15px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  gap: 40px;
  overflow-y: hidden;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ServicesFilterDiv = styled.div`
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const CheckBtn = styled.label`
  cursor: pointer;
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -35px;
  }
`;

const CheckInp = styled.input`
  display: none;

  @media screen and (max-width: 700px) {
    &:checked ~ ${ServicesFilterDiv} {
      display: flex;
    }
  }
`;

const Jobs: React.FC = () => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    bannerMessage,
    bannerTitle,
    showBanner,
    closeBanner,
  } = bannerControls();

  const [jobs, setJobs] = useState<Job[]>([]);
  const [showJobs, setShownJobs] = useState<Job[]>([]);

  /* istanbul ignore next */
  const onServiceSuccess = (data: { jobs?: Job[] }) => {
    if (data.jobs) {
      setJobs(data.jobs);
      setShownJobs(data.jobs);
    }
    closeLoader();
  };

  /* istanbul ignore next */
  const onServiceError = (error: string) => {
    closeLoader();
    showBanner(t('error'), error);
  };

  /* istanbul ignore next */
  const handleFilter = (
    minPrice?: number,
    city?: string,
    searchString?: string,
  ) => {
    if (minPrice && city) {
      setShownJobs(
        jobs
          .filter(
            (item) =>
              item.price_per_hour >= minPrice &&
              `${item.city}, ${item.country}`
                .toLowerCase()
                .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.title
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (minPrice && !city) {
      setShownJobs(
        jobs
          .filter((item) => item.price_per_hour >= minPrice)
          .filter(
            (item) =>
              item.title
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else if (!minPrice && city) {
      setShownJobs(
        jobs
          .filter((item) =>
            `${item.city}, ${item.country}`
              .toLowerCase()
              .includes(city.toLowerCase()),
          )
          .filter(
            (item) =>
              item.title
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? '') ||
              item.description
                .toLowerCase()
                .includes(searchString?.toLowerCase() ?? ''),
          ),
      );
    } else {
      setShownJobs(
        jobs.filter(
          (item) =>
            item.title
              .toLowerCase()
              .includes(searchString?.toLowerCase() ?? '') ||
            item.description
              .toLowerCase()
              .includes(searchString?.toLowerCase() ?? ''),
        ),
      );
    }
  };

  /* istanbul ignore next */
  const handleResetFilters = () => getJobs(onServiceSuccess, onServiceError);

  useEffect(() => {
    openLoader();
    getJobs(onServiceSuccess, onServiceError);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('jobs')} - Hephaestus</title>
      </Helmet>
      <Separator size={20} />
      <RootDiv id="services-tab-root">
        {/* istanbul ignore next */ isLoaderVisible && <Loader />}
        {
          /* istanbul ignore next */ isBannerVisible && (
            <Banner
              title={bannerTitle}
              message={bannerMessage}
              onClose={closeBanner}
            />
          )
        }
        <Separator size={30} />
        <BodyDiv>
          <CheckInp type="checkbox" id="check" />
          <CheckBtn htmlFor="check">
            <FilterIcon width={20} height={20} color={COLORS.blue} />
          </CheckBtn>
          <ServicesFilterDiv>
            <JobsFilter
              jobs={showJobs}
              handleFilter={handleFilter}
              handleResetFilters={handleResetFilters}
            />
          </ServicesFilterDiv>
          <JobsList jobs={showJobs} />
        </BodyDiv>
      </RootDiv>
    </>
  );
};

export default Jobs;
