import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { COLORS } from '../../utils/colors';

const Menu = styled.div`
  background: ${COLORS.white};
  position: fixed;
  padding-inline: 20px;
  box-shadow: 0 0 10px ${COLORS.gray_50};
  border: 0;
  border-radius: 8px;
  padding-block: 5px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  max-height: 400px;
`;

interface PopUpMenuProps {
  children?: React.ReactNode;
  innerBoxPosition?: string | number;
  menuPosition?: string | number;
  menuHeight?: string | number;
}

export const PopUpMenu: React.FC<PopUpMenuProps> = ({ children, innerBoxPosition = 20, menuPosition = 50, menuHeight = 43 }) => {
  const mainDiv = {
    right: menuPosition,
    top: menuHeight,
  } as CSSProperties;
  const rotatedDivStyle = {
    width: 15,
    height: 15,
    background: 'white',
    position: 'absolute',
    transform: 'rotate(45deg)',
    top: -5,
    right: innerBoxPosition,
  } as CSSProperties;
  return (
    <Menu style={mainDiv}>
      <div style={rotatedDivStyle} />
      {children}
    </Menu>
  );
};
