import { createContext, FC, ReactNode } from 'react';
import loaderControls from '../components/Loader/loaderControls';
import { Loader } from '../components/Loader/Loader';

interface LoaderContextProps {
  showLoader: () => void;
  closeLoader: () => void;
}

export const LoaderContext = createContext<LoaderContextProps>({
  showLoader: () => undefined,
  closeLoader: () => undefined,
});

interface LoaderProviderProps {
  children: ReactNode;
}

export const LoaderProvider: FC<LoaderProviderProps> = ({ children }) => {
  const { openLoader, closeLoader, isLoaderVisible } = loaderControls();
  return (
    <LoaderContext.Provider
      value={{
        showLoader: openLoader,
        closeLoader: closeLoader,
      }}>
      {children}
      {isLoaderVisible && <Loader />}
    </LoaderContext.Provider>
  );
};
