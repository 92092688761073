import React from 'react';
import IconProps from './IconProps';

export const SendIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0996094 18.875V11.5996L15.7246 9.5L0.0996094 7.40039V0.125L21.9746 9.5L0.0996094 18.875Z"
        fill={color}
      />
    </svg>
  );
};
