import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';
import { Profile } from '../../models/Profile';
import { Router } from '../../navigation/Router';

const RootDiv = styled.div`
    position: fixed;
    top: 47px;
    bottom: 0;
    left: 0;
    width: 280px;
    background: white;
    padding-inline: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0 0 5px ${COLORS.gray_50};

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Button = styled.button`
  color: ${COLORS.blue};
  width: 100%;
  border-bottom: 1px solid ${COLORS.orange};
  text-align: start;
`;

interface MainNavbarMenuProps {
  profile?: Profile;
  onClose: () => void;
}

export const MainNavbarMenu: React.FC<MainNavbarMenuProps> = ({
  profile,
  onClose,
}) => {
  const handleDashboard = () => {
    Router.goToHome();
    onClose();
  };

  /* istanbul ignore next */
  const handleProjectOrServices = () => {
    onClose();
    if (profile && profile.role === 'user')
      Router.goToServicesAndProfessionals();
    if (profile && profile.role === 'worker') Router.goToJobs();
  };

  /* istanbul ignore next */
  const handleCreateAction = () => {
    onClose();
    if (profile && profile.role === 'worker') Router.goToNewService();
    if (profile && profile.role === 'user') Router.goToNewJob();
  };

  const handleGoToChats = () => {
    Router.goToChats();
    onClose();
  };

  const handleSettings = () => {
    Router.goToSettings();
    onClose();
  };

  return (
    <RootDiv>
      <Separator size={20} />
      <Button
        type="button"
        id="main-navbar-menu-dashboard-button"
        onClick={handleDashboard}>
        {t('dashboard')}
      </Button>
      {profile && profile.role === 'worker' ? (
        <Button type="button" onClick={handleProjectOrServices}>
          {t('jobs')}
        </Button>
      ) : (
        <Button type="button" onClick={handleProjectOrServices}>
          {t('services_and_professionals')}
        </Button>
      )}
      <Button type="button" onClick={handleGoToChats}>
        {t('messages')}
      </Button>
      {profile && profile.role === 'worker' ? (
        <Button
          type="button"
          id="main-navbar-menu-create-button"
          onClick={handleCreateAction}>
          {t('create_service')}
        </Button>
      ) : (
        <Button
          type="button"
          id="main-navbar-menu-create-button"
          onClick={handleCreateAction}>
          {t('create_job')}
        </Button>
      )}
      <Button type="button" onClick={handleSettings}>
        {t('settings')}
      </Button>
    </RootDiv>
  );
};
