export const COLORS = {
  black: '#1E1E1E',
  black_70: '#1e1e1ecc',
  white: '#FFFFFF',
  gray: '#5B5B5B',
  gray_50: '#5b5b5b79',
  gray_10: 'rgba(91,91,91,0.1)',
  orange: '#FFAD42',
  blue: '#4588F5',
  blue_50: 'rgba(69,136,245,0.5)',
  red: '#FF4D4D',
    green: '#4CAF50',
};
