import { useState } from 'react';

const loaderControls = () => {
  const [isLoaderVisible, setLoaderVisible] = useState<boolean>(false);

  const openLoader = () => setLoaderVisible(true);
  const closeLoader = () => setLoaderVisible(false);

  return {
    isLoaderVisible,
    openLoader,
    closeLoader,
  };
};

export default loaderControls;
