import React from 'react';
import IconProps from './IconProps';

export const FilterIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.625 7.07324V0.823242H14.7246V2.92285H18.875V4.97363H14.7246V7.07324H12.625ZM18.875 11.2236H8.47461V9.17285H18.875V11.2236ZM4.27539 7.07324H6.375V13.3232H4.27539V11.2236H0.125V9.17285H4.27539V7.07324ZM10.5254 19.5732H8.47461V13.3232H10.5254V15.4229H18.875V17.4736H10.5254V19.5732ZM0.125 2.92285H10.5254V4.97363H0.125V2.92285ZM0.125 15.4229H6.375V17.4736H0.125V15.4229Z"
        fill={color}
      />
    </svg>
  );
};
