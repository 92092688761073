import { useContext, useEffect, useState } from 'react';
import { Service } from '../models/Services';
import { Profile } from '../models/Profile';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { Router } from '../navigation/Router';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { t } from 'i18next';

type WorkerServicesType = 'user_id' | 'user';
type UseWorkerServicesReturn = {
  workerServices: Service[];
  checkWorkerServiceExist: () => void;
  createService: (workerService: Service, onSucces?: () => void) => void;
  fetchWorkerServices: () => void;
};

export const useWorkerServices = (
  profile?: Profile,
  type: WorkerServicesType = 'user',
): UseWorkerServicesReturn => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);

  const [workerServices, setWorkerServices] = useState<Service[]>([]);

  const createService = (workerService: Service, onSucces?: () => void) => {
    showLoader();
    API.user
      .createNewService(workerService)
      .then((response) => {
        logger(
          'useWorkerServices - useWorkerServices() - createService()',
          '',
          'success',
        );
        setWorkerServices(response);
        /* istanbul ignore next */
        if (onSucces) onSucces();
      })
      .catch((error) => {
        showBanner(t('error'), error.message);
        logger(
          'useWorkerServices - useWorkerServices() - createService()',
          error.message,
          'error',
        );
      })
      .finally(() => closeLoader());
  };

  const checkWorkerServiceExist = () => {
    if (profile && profile.role === 'worker' && workerServices) {
      if (workerServices.length === 0) {
        Router.goToCompleteWorkerProfile();
      }
    }
  };

  const fetchWorkerServices = () => {
    if (profile && profile.role === 'worker') {
      showLoader();
      API.user
        .getWorkerServices()
        .then((response) => {
          setWorkerServices(response);
          logger(
            'useWorkerServices - useWorkerServices() - fetchWorkerServices()',
            response,
            'success',
          );
        })
        .catch((error) => {
          showBanner(t('error'), error.message);
          logger(
            'useWorkerServices - useWorkerServices() - fetchWorkerServices()',
            error.message,
            'error',
          );
        })
        .finally(() => closeLoader());
    }
  };

  const fetchWorkerServicesByUserId = () => {
    if (profile && profile.role === 'worker') {
      showLoader();
      API.user
        .getUserServicesByUserId(profile.id)
        .then((response) => {
          setWorkerServices(response);
          logger(
            'useWorkerServices - useWorkerServices() - fetchWorkerServicesByUserId()',
            response,
            'success',
          );
        })
        .catch((error) => {
          showBanner(t('error'), error.message);
          logger(
            'useWorkerServices - useWorkerServices() - fetchWorkerServicesByUserId()',
            error.message,
            'error',
          );
        })
        .finally(() => closeLoader());
    }
  };

  useEffect(() => {
    if (type === 'user') fetchWorkerServices();
    if (type === 'user_id') fetchWorkerServicesByUserId();
  }, [profile]);
  return {
    workerServices,
    checkWorkerServiceExist,
    createService,
    fetchWorkerServices,
  };
};
