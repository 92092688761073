import { FC } from 'react';
import { Service } from '../../models/Services';
import styled from 'styled-components';
import { t } from 'i18next';
import { COLORS } from '../../utils/colors';
import { TrashIcon } from '../../assets/icons/TrashIcon';
import { API } from '../../network/API';
import loaderControls from '../Loader/loaderControls';
import bannerControls from '../Banner/bannerControls';
import { Loader } from '../Loader/Loader';
import { Banner } from '../Banner/Banner';
import { logger } from '../../utils/logger';
import { Router } from '../../navigation/Router';
import { Profile } from '../../models/Profile';

const Root = styled.div`
  flex: 1;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.gray_50};
  padding-bottom: 5px;
`;

const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-block: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DeleteButton = styled.button`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background: ${COLORS.blue};
  color: ${COLORS.white};
  border: 0;
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 5px;
  font-weight: 500;
  box-shadow: 1px 1px 5px ${COLORS.gray};

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray};
  }
`;

interface ProfileServiceCardProps {
  service: Service;
  onReload?: () => void;
  profile?: Profile;
}

export const ProfileServiceCard: FC<ProfileServiceCardProps> = ({
  service,
  onReload,
  profile,
}) => {
  const { isLoaderVisible, openLoader, closeLoader } = loaderControls();
  const {
    isBannerVisible,
    showBanner,
    closeBanner,
    bannerMessage,
    bannerTitle,
  } = bannerControls();

  const onDelete = () => {
    if (service.id) {
      openLoader();
      API.user
        .deleteService(service.id)
        .then(() => {
          showBanner(t('success'), t('service_deleted'));
          if (onReload) onReload();
        })
        .catch((error) => {
          logger('ProfileServiceCard - onDelete()', error, 'error');
          showBanner(t('error'), error.message);
        })
        .finally(() => closeLoader());
    }
  };

  const handleNewChat = () => {
    if (!profile) return;
    openLoader();
    API.user
      .sendMessage(profile.id, service.service, t('job_proposal_message'))
      .then((response) => {
        Router.goToChats(response.chat_id);
      })
      .catch((error) => showBanner(t('error'), error.message))
      .finally(() => closeLoader());
  };

  return (
    <Root>
      {isLoaderVisible && <Loader />}
      {isBannerVisible && (
        <Banner
          title={bannerTitle}
          message={bannerMessage}
          onClose={closeBanner}
        />
      )}
      <Header>
        <span style={{ fontWeight: 500 }}>{service.service}</span>
        <span style={{ fontWeight: 600 }}>
          {service.price_per_hour}€ {t('price_per_hour')}
        </span>
        <div style={{ flex: 1 }} />
        {onReload ? (
          <DeleteButton type="button" onClick={onDelete}>
            <TrashIcon width={20} height={20} color={COLORS.blue} />
          </DeleteButton>
        ) : (
          <Button type="button" onClick={handleNewChat}>
            {t('hire')}
          </Button>
        )}
      </Header>
      <span>{service.description}</span>
      <br />
      {!onReload && (
        <span style={{ color: COLORS.blue }}>
          {service.city}, {service.country}
        </span>
      )}
    </Root>
  );
};
