import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

const RootDiv = styled.div`
  background: white;
  border: 0;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border-radius: 5px;
  display: flex;
  padding-block: 5px;
  padding-inline: 10px;
  gap: 10px;
  align-items: center;
  flex: 1;
`;

const Input = styled.textarea`
  flex: 1;
  color: ${COLORS.blue};
  border: 0;
  outline: none;
  max-height: 100px;
  font-family: "Poppins", sans-serif;
`;

interface DefaultTextAreaProps {
  id: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const DefaultTextArea: React.FC<DefaultTextAreaProps> = ({ id, value, onChange }) => {
  return (
    <RootDiv>
      <Input id={id} value={value} onChange={onChange} rows={value.split(/\r\n|\r|\n/).length} />
    </RootDiv>
  );
};
