import { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

const RootDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  color: ${COLORS.blue};
  border: 0;
  outline: none;
  background: white;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border-radius: 5px;
  padding-block: 5px;
  padding-inline: 10px;
`;

const SelectionDiv = styled.div`
  max-height: 200px;
  background: white;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border-radius: 5px;
  border: 0;
  overflow-y: auto;
`;

interface ChoicesInputProps {
  id: string;
  choices: string[];
  value: string;
  selectedValue: any;
  setValue: (value: string) => void;
  handleSelectedChoice: (value: any) => void;
  placeholder?: string;
}

export const ChoicesInput: FC<ChoicesInputProps> = ({
  id,
  choices,
  value,
  setValue,
  handleSelectedChoice,
  selectedValue,
  placeholder,
}) => {
  const onChangeCity = (event: ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);
  /* istanbul ignore next */
  const handleSelected = (sValue: any) => {
    handleSelectedChoice(sValue);
    setValue(sValue);
  };

  return (
    <RootDiv>
      <Input
        type="text"
        id={id}
        value={value}
        onChange={onChangeCity}
        placeholder={placeholder}
      />
      {
        /* istanbul ignore next */ value.length > 0 &&
          choices.length > 0 &&
          selectedValue !== value && (
            <SelectionDiv>
              <ul style={{ listStyleType: 'none' }}>
                {choices
                  .filter((item) =>
                    item.toLowerCase().includes(value.toLowerCase()),
                  )
                  .map((choice, index) => {
                    return (
                      <li key={`${index}-${new Date().getTime()}`}>
                        <button
                          type="button"
                          onClick={() => handleSelected(choice)}>
                          {choice}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </SelectionDiv>
          )
      }
    </RootDiv>
  );
};
