import { useContext, useEffect, useState } from 'react';
import { Profile } from '../models/Profile';
import { FinancialData } from '../models/FinancialData';
import { API } from '../network/API';
import { logger } from '../utils/logger';
import { Router } from '../navigation/Router';
import { LoaderContext } from '../context/LoaderContext';
import { BannerContext } from '../context/BannerContext';
import { t } from 'i18next';

type FinancialDataReturn = {
  financialData: FinancialData;
  setFinancialData: (fd: FinancialData) => void;
  checkFinancialDataComplete: () => void;
};

export const useFinancialData = (profile?: Profile): FinancialDataReturn => {
  const { showLoader, closeLoader } = useContext(LoaderContext);
  const { showBanner } = useContext(BannerContext);
  const [financialData, setFinancialData] = useState<FinancialData>({
    vat_number: '',
  });

  const checkFinancialDataComplete = () => {
    if (
      financialData &&
      financialData.user === null &&
      profile?.role === 'worker'
    ) {
      Router.goToCompleteWorkerProfile();
    }
  };

  const fetchFinancialData = () => {
    showLoader();
    API.user
      .getFinancialData()
      .then((response) => {
        setFinancialData(response);
        logger('useFinancialData.ts - useFinancialData()', response, 'success');
      })
      .catch((error) => {
        showBanner(t('error'), error.message);
        logger(
          'useFinancialData.ts - useFinancialData()',
          error.message,
          'error',
        );
      })
      .finally(() => closeLoader());
  };

  useEffect(() => fetchFinancialData(), [profile]);

  return {
    financialData,
    setFinancialData,
    checkFinancialDataComplete,
  };
};
