import React, { FC, useContext } from 'react';
import { ProfileContext } from '../context/ProfileContext';
import styled from 'styled-components';
import { COLORS } from '../utils/colors';
import { t } from 'i18next';
import { ReviewComponent } from '../components/ReviewComponent';
import { Helmet } from 'react-helmet-async';
import { CheckCircleIcon } from '../assets/icons/CheckCircleIcon';
import { useWorkerServices } from '../hooks/useWorkerServices';
import { ProfileServiceCard } from '../components/Cards/ProfileServiceCard';
import useJobs from '../hooks/useJobs';
import { ProfileJobsCard } from '../components/Cards/ProfileJobsCard';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 40px;
  overflow-y: hidden;

  @media screen and (max-width: 600px) {
    padding-inline: 10px;
  }

  @media screen and (max-width: 400px) {
    padding-inline: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-block: 20px;

  & > img {
    width: 150px;
    height: 150px;
    border: 0;
    border-radius: 10px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Username = styled.span`
  font-weight: 500;
  font-size: 1.1rem;
`;

const Price = styled.span`
  font-weight: 500;
  color: ${COLORS.blue};
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 1.2rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 20px;
  gap: 10px;
  border-top: 1px solid ${COLORS.orange};
  padding-top: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PersonalProfile: FC = () => {
  const { profile } = useContext(ProfileContext);
  const { workerServices, fetchWorkerServices } = useWorkerServices(profile);
  const { jobs, fetchJobsByUser } = useJobs('user', profile);
  return (
    <>
      <Helmet>
        <title>{`${profile?.username}`} - Hephaestus</title>
      </Helmet>
      <Root>
        <Header>
          <img src={profile?.avatar} alt="profile" loading="lazy" />
        </Header>
        <Profile>
          <div>
            <Username>
              {profile?.username}{' '}
              {profile?.id_verified && (
                <CheckCircleIcon width={16} height={16} color={COLORS.blue} />
              )}
            </Username>
            {profile && (
              <ReviewComponent
                rating={profile.rating.rating}
                reviews={profile.rating.reviews}
              />
            )}
            {profile?.role === 'worker' && (
              <>
                <span style={{ fontWeight: 500 }}>{profile?.main_job}</span>
                <br />
              </>
            )}
            <span>{profile?.profile_description}</span>
          </div>
          <div style={{ flex: 1 }} />
          {profile?.role === 'worker' && (
            <Price>
              {profile?.main_price_per_hour}€ {t('price_per_hour')}
            </Price>
          )}
        </Profile>
        {profile?.role === 'worker' && (
          <Body>
            <Title>{t('services')}</Title>
            {workerServices?.map((service) => {
              return (
                <ProfileServiceCard
                  key={service.id}
                  service={service}
                  onReload={fetchWorkerServices}
                />
              );
            })}
          </Body>
        )}
        {profile?.role === 'user' && (
          <Body>
            <Title>{t('jobs')}</Title>
            {jobs?.map((job) => {
              return (
                <ProfileJobsCard
                  key={job.id}
                  job={job}
                  onReload={fetchJobsByUser}
                />
              );
            })}
          </Body>
        )}
      </Root>
    </>
  );
};

export default PersonalProfile;
