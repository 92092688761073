import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { COLORS } from '../utils/colors';
import { Separator } from '../components/Separators/Separator';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { ServicesTab } from '../components/Tabs/ServicesTab';
import { ProfessionalsTab } from '../components/Tabs/ProfessionalsTab';

const Header = styled.div`
  display: flex;
  padding-inline: 40px;

  @media screen and (max-width: 600px) {
    padding-inline: 10px;
  }
`;

const Button = styled.button`
  flex: 1;
  font-size: 1.4rem;
  font-weight: 600;
`;

const ServicesAndProfessionals: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const servicesButtonStyle: CSSProperties = {
    color: selectedTab === 0 ? COLORS.orange : COLORS.gray_50,
    borderBottom:
      selectedTab === 0
        ? `3px solid ${COLORS.orange}`
        : `3px solid ${COLORS.gray_50}`,
  };
  const professionalButtonStyle: CSSProperties = {
    color: selectedTab === 1 ? COLORS.orange : COLORS.gray_50,
    borderBottom:
      selectedTab === 1
        ? `3px solid ${COLORS.orange}`
        : `3px solid ${COLORS.gray_50}`,
  };

  const Tab = () => {
    switch (selectedTab) {
      case 0:
        return <ServicesTab />;
      case 1:
        return <ProfessionalsTab />;
    }
  };
  return (
    <>
      <Helmet>
        <title>{t('services_and_professionals')} - Hephaestus</title>
      </Helmet>
      <Separator size={20} />
      <Header id="services-and-professionals-header">
        <Button
          style={servicesButtonStyle}
          id="services-and-professionals-services-tab-button"
          onClick={() => setSelectedTab(0)}>
          {t('services')}
        </Button>
        <Button
          style={professionalButtonStyle}
          id="services-and-professionals-professionals-tab-button"
          onClick={() => setSelectedTab(1)}>
          {t('professionals')}
        </Button>
      </Header>
      {Tab()}
    </>
  );
};

export default ServicesAndProfessionals;
