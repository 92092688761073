import React from 'react';
import IconProps from './IconProps';

export const MagnifyingGlassIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5918 10.2324C4.50326 11.1439 5.61003 11.5996 6.91211 11.5996C8.21419 11.5996 9.32096 11.1439 10.2324 10.2324C11.1439 9.32096 11.5996 8.21419 11.5996 6.91211C11.5996 5.61003 11.1439 4.50326 10.2324 3.5918C9.32096 2.68034 8.21419 2.22461 6.91211 2.22461C5.61003 2.22461 4.50326 2.68034 3.5918 3.5918C2.68034 4.50326 2.22461 5.61003 2.22461 6.91211C2.22461 8.21419 2.68034 9.32096 3.5918 10.2324ZM13.1621 11.5996L18.3379 16.7754L16.7754 18.3379L11.5996 13.1621V12.332L11.3066 12.0391C10.0697 13.1133 8.60482 13.6504 6.91211 13.6504C5.02409 13.6504 3.41276 12.9993 2.07812 11.6973C0.776042 10.3952 0.125 8.80013 0.125 6.91211C0.125 5.02409 0.776042 3.42904 2.07812 2.12695C3.41276 0.792318 5.02409 0.125 6.91211 0.125C8.80013 0.125 10.3952 0.792318 11.6973 2.12695C12.9993 3.42904 13.6504 5.02409 13.6504 6.91211C13.6504 7.5957 13.4876 8.37695 13.1621 9.25586C12.8366 10.1022 12.4622 10.7858 12.0391 11.3066L12.332 11.5996H13.1621Z" fill={color} />
    </svg>
  );
};
