import { API } from '../network/API';
import { logger } from './logger';

export const getServices = async (
  onSuccess: (data: object) => void,
  onError: (error: string) => void,
) => {
  API.user
    .getServices()
    .then((response) => {
      logger('APIUtils.tsx - getServices', response, 'success');
      onSuccess({ services: response });
    })
    .catch((error) => {
      logger('APIUtils.tsx - getServices', error.message, 'error');
      onError(error.message);
    });
};

export const getWorkers = async (
  onSuccess: (data: object) => void,
  onError: (error: string) => void,
) => {
  API.user
    .getWorkers()
    .then((response) => {
      logger('APIUtils.tsx - getWorkers', response, 'success');
      onSuccess({ profiles: response });
    })
    .catch((error) => {
      logger('ServicesTab.tsx - getWorkers', error.message, 'error');
      onError(error.message);
    });
};

export const getJobs = async (
  onSuccess: (data: object) => void,
  onError: (error: string) => void,
) => {
  API.user
    .getAllJobs()
    .then((response) => {
      logger('APIUtils.tsx - getJobs', response, 'success');
      onSuccess({ jobs: response });
    })
    .catch((error) => {
      logger('APIUtils.tsx - getJobs', error.message, 'error');
      onError(error.message);
    });
};

export const getProfileWithID = (
  onSuccess: (data: object) => void,
  onError: (error: string) => void,
  id?: string,
) => {
  API.user
    .getProfileById(id)
    .then((response) => {
      logger('APIUtils.tsx - getProfileWithID', response, 'success');
      onSuccess({ userProfile: response });
    })
    .catch((error) => {
      logger('APIUtils.tsx - getProfileWithID', error.message, 'error');
      onError(error.message);
    });
};
