import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { MoneyInput } from '../Inputs/MoneyInput';
import { Separator } from '../Separators/Separator';
import { t } from 'i18next';
import { ChoicesInput } from '../Inputs/ChoicesInput';
import { SearchInput } from '../Inputs/SearchInput';
import { Profile } from '../../models/Profile';

const FilterDiv = styled.div`
  width: 270px;
  background: white;
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: 0 0 7px ${COLORS.gray_50};
  border: 0;
  border-radius: 10px;
  padding-block: 10px;
  padding-inline: 15px;
  display: flex;
  flex-direction: column;

  & > span {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    box-shadow: 0 0 0 ${COLORS.gray_50};
    margin-top: 5px;
    margin-left: 0;
    border: 1px solid ${COLORS.gray_50};
  }
`;

const FilterButton = styled.button`
  background: ${COLORS.blue};
  color: ${COLORS.white};
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 7px ${COLORS.gray};

  &:hover {
    box-shadow: 0 0 0 ${COLORS.gray};
  }
`;

interface WorkersFilterProps {
  profiles: Profile[];
  handleFilter: (
    maxPrice?: number,
    city?: string,
    searchString?: string,
  ) => void;
  handleResetFilters: () => void;
}

export const WorkersFilter: React.FC<WorkersFilterProps> = ({
  profiles,
  handleFilter,
  handleResetFilters,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [profilesCities, setProfilesCities] = useState<string[]>([]);
  const [maxMoney, setMaxMoney] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [selectedCity, setSelecetdCity] = useState<string>('');

  /* istanbul ignore next */
  const handleChangeMaxMoney = (event: ChangeEvent<HTMLInputElement>) =>
    setMaxMoney(event.target.value);

  /* istanbul ignore next */
  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const resetFilters = () => {
    setMaxMoney('');
    setCity('');
    setSelecetdCity('');
    setSearchValue('');
    handleResetFilters();
  };

  useEffect(() => {
    const mappedAddresses = profiles.map(
      (profile) => `${profile.address?.city}, ${profile.address?.country}`,
    );
    const filteredAddresses = mappedAddresses.filter(
      (value, index) => mappedAddresses.indexOf(value) === index,
    );
    setProfilesCities(filteredAddresses);
  }, [profiles]);
  return (
    <FilterDiv>
      <span style={{ fontWeight: 500, fontSize: 16 }}>{t('filters')}</span>
      <Separator size={20} />
      <span>{t('services')}:</span>
      <Separator size={10} />
      <SearchInput
        id="profiles-filter-search-input"
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      <Separator size={20} />
      <span>{t('price_per_hour')} Max:</span>
      <Separator size={10} />
      <MoneyInput
        id="profiles-filter-max-money-input"
        value={maxMoney}
        onChange={handleChangeMaxMoney}
        placeholder="Max."
      />
      <Separator size={20} />
      <span>{t('city')}:</span>
      <Separator size={10} />
      <ChoicesInput
        id="profiles-filter-city-input"
        placeholder="Valencia, Spain"
        value={city}
        setValue={setCity}
        choices={profilesCities}
        handleSelectedChoice={setSelecetdCity}
        selectedValue={selectedCity}
      />
      <Separator size={30} />
      <FilterButton
        id="profiles-filter-filter-button"
        type="button"
        onClick={() =>
          handleFilter(
            Number.parseFloat(maxMoney),
            selectedCity || city,
            searchValue,
          )
        }>
        {t('filter')}
      </FilterButton>
      <Separator size={20} />
      <FilterButton
        id="profiles-filter-reset-button"
        type="button"
        onClick={resetFilters}
        style={{ background: COLORS.orange }}>
        {t('reset')}
      </FilterButton>
    </FilterDiv>
  );
};
